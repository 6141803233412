html {
  --antd-wave-shadow-color: transparent;
}

.ant-drawer-body {
  padding: 20px 10px 10px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-left: 10px;
}

.text-align-right {
  text-align: right;
}

.mb-20 {
  margin-bottom: 15px;
}

.count-btn {
  cursor: pointer;
  text-decoration: underline;
  color: #ea5455;
}

.img-scroll {
  max-height: 100px;
  overflow-y: auto;
}

.btn-link {
  padding: 0;
  border: none;
  background-color: white;
  color: #ea5455;
  cursor: pointer;
  font-size: 1rem;
}

.btn-link:hover {
  color: #ea5455 !important;
}

// Meet styles start
.ant-btn {
  margin-bottom: 0;
}

// .ant-form-horizontal .ant-row {margin-left: 0px;margin-right: 0px;}
// .drawer-input {margin-bottom: 10px;width: 100%;}
.ant-drawer.ant-drawer-right .ant-drawer-content-wrapper {
  border-radius: 25px;
}

.ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right
  .ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
}

.ant-pagination {
  padding-top: 10px;
}

// .ant-col.ant-col-16.ant-form-item-control {max-width: 100%;}
// .ant-col.ant-col-xxl-3,.ant-col.ant-col-xxl-4 {flex: 0;margin-bottom: 10px;}
// .ant-col.ant-col-xs-24.ant-col-sm-12.ant-col-md-8.ant-col-lg-8.ant-col-xl-4 {display: flex;}
.half-sc-bt {
  display: none;
}

/*.gx-site-logo {
  width: 80px;
}*/
@media (max-width: 1440px) {
  .full-sc-bt {
    display: none;
  }

  .half-sc-bt {
    display: block;
  }

  .ant-drawer.ant-drawer-left.gx-drawer-sidebar.ant-drawer-open
    .ant-drawer-content-wrapper {
    height: 100vh;
  }
}

.table-bg {
  overflow-x: auto;
}

// Meet styles end

// Hardik style start
.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light
  button {
  box-shadow: none;
  border: none;
}

.custom-label-com span {
  margin: 0;
  font-weight: 600;
}

.ant-divider-inner-text {
  padding: 0;
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0;
}

.ant-divider-horizontal.ant-divider-with-text {
  margin: 10px 0;
}
.ant-btn:not([disabled]):not(.disabled) {
  margin-top: 0px;
}
.ant-btn:not([disabled]):not(.disabled) {
  margin-bottom: 0px;
}

.ant-col.ant-col-2 span.anticon.anticon-minus-circle.dynamic-delete-button {
  margin-bottom: 35px;
}

// .ant-form-item.ant-form-item-with-help.ant-form-item-has-error {
//   margin-bottom: 20px;
// }
.gx-double-right-icon-wrapper {
  color: #ffff !important;
  background: #ea5455;
  cursor: pointer;
  position: unset;
  transform: unset;
  margin: auto;
}

.gx-double-right-icon-wrapper a {
  color: #ffff !important;
  line-height: 1;
  font-size: 20px;
}

.gx-double-right-icon svg {
  width: 20px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #ea5455;
  box-shadow: 0 0 0 2px rgba(234, 84, 84, 0.1);
}

.ant-modal-wrap {
  backdrop-filter: blur(5px);
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #f44336;
  margin-right: 0px;
  border: 1px solid #ff000057;
  /* padding: 5px; */
  border-radius: 50%;
  height: 50px;
  width: 50px;
  line-height: 58px;
  margin-bottom: 10px;
}

.ant-modal-confirm-body .anticon-delete svg {
  width: 18px;
}

.gx-layout-sider-header,
.ant-layout-header {
  box-shadow: 0 0 2.1875rem rgb(90 97 105 / 7%),
    0 0 1.40625rem rgb(90 97 105 / 7%), 0 0 0.53125rem rgb(90 97 105 / 9%),
    0 0 0.1875rem rgb(90 97 105 / 7%);
}

.gx-lt-icon-search-bar-lg {
  width: 100%;
}

.gx-search-bar .gx-form-group {
  width: 83%;
}

// Hardik style end

.ant-modal-wrap {
  backdrop-filter: blur(5px);
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #f44336;
  margin-right: 0px;
  border: 1px solid #ff000057;
  /* padding: 5px; */
  border-radius: 50%;
  height: 50px;
  width: 50px;
  line-height: 58px;
  margin-bottom: 10px;
}

.ant-modal-confirm-body .anticon-delete svg {
  width: 18px;
}

.gx-layout-sider-header,
.ant-layout-header {
  box-shadow: 0 0 2.1875rem rgb(90 97 105 / 7%),
    0 0 1.40625rem rgb(90 97 105 / 7%), 0 0 0.53125rem rgb(90 97 105 / 9%),
    0 0 0.1875rem rgb(90 97 105 / 7%);
}

.gx-lt-icon-search-bar-lg {
  width: 100%;
}

.gx-search-bar .gx-form-group {
  width: 83%;
}

// Hardik style end
//Dixit

/***********GLOBAL-CONTENT-CSS***********/

/*----------- BUTTON-CSS -----------*/
.ant-btn-primary.disabled,
.ant-btn-primary:disabled,
.ant-btn-primary:not([disabled]):not(.disabled):active,
.ant-btn-primary:not([disabled]):not(.disabled).active,
.ant-btn-primary {
  background: #ea5455 !important;
  color: #fff !important;
  background-color: #ea5455 !important;
  border-color: #ea5455 !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: white !important;
  color: #ea5455 !important;
  border-color: #ea5455 !important;
}
/*----------- BUTTON-CSS -----------*/

.gx-main-content-wrapper {
  padding: 15px 30px 0;
}
.rnw-page-title {
  margin-bottom: 0;
}
.ant-form div > .ant-row,
.ant-form > .ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.ant-form .ant-row > .ant-col {
  padding-left: 6px !important;
  padding-right: 6px !important;
}
/*----------*HEADER-CSS*----------*/
.gx-layout-sider-header,
.ant-layout-header {
  height: 60px;
}
.ant-layout-header {
  flex-wrap: nowrap;
  padding-left: 20px;
  z-index: 999;
}

.ant-picker.ant-picker-large,
.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-input {
  height: 43px;
  box-shadow: none !important;
  background-color: #fffbfb99;
  border-color: #ffcdcd !important;
}
.false .ant-select-single.ant-select-lg .ant-select-selector {
  border-color: #ea5455 !important;
}
.ant-drawer-body
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-drawer-body
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-drawer-body .ant-input {
  height: 45px;
}

.gx-search-bar input[type="search"] {
  padding-left: 40px !important;
  height: 60px;
}
.gx-lt-icon-search-bar-lg .gx-search-icon {
  width: 35px;
  height: 60px;
  line-height: 67px;
  font-size: 16px;
  left: 2px;
  right: auto;
}
.gx-search-bar .gx-search-icon {
  color: #ea5455;
}
.gx-app-nav {
  color: #ea5455;
}
.navigation-icons .gx-app-nav {
  flex-wrap: unset;
  flex-direction: row;
}
.branch {
  position: relative;
}
.branch-list {
  list-style: none;
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  padding: 0 10px;
  margin: 0;
  background: #fff;
  right: -10px;
  top: 50px;
  width: 150px;
  box-shadow: 0 0 4px 4px rgb(99 88 237 / 5%);
  border-radius: 5px;
  text-align: center;
}
.branch-list.show {
  visibility: visible;
  opacity: 1;
  height: auto;
}
.branch-list li {
  border-bottom: 1px solid #d7d4ff;
  padding: 10px 0;
}
.branch-list li:last-child {
  border: none;
}
.branch-selected a,
.branch-list li a {
  color: #545454;
}
.branch-selected a:hover,
.branch-list li a:hover {
  color: #ea5455;
}
/*----------*HEADER-CSS*----------*/

/*----------*ANT-CARD---------*/
.ant-card {
  margin-bottom: 30px;
}
.ant-card-bordered {
  border: none;
}

.ant-card-body {
  box-shadow: 0 0 2.1875rem rgb(90 97 105 / 7%),
    0 0 1.40625rem rgb(90 97 105 / 7%), 0 0 0.53125rem rgb(90 97 105 / 9%),
    0 0 0.1875rem rgb(90 97 105 / 7%);
  border-radius: 10px;
}
.ant-card-body {
  padding: 20px 14px;
}
/*-----------ANT-CARD-------*/

/*-----------ANT-FORM-------*/

.float-label {
  padding-bottom: 0;
}
.ant-form-item {
  margin-bottom: 15px;
}
.ant-form .ant-col .ant-col.select-role,
.ant-form .ant-col .ant-form-item .ant-col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.ant-form-item-control-input {
  height: auto;
  min-height: unset;
}
.ant-picker.ant-picker-large,
.ant-input {
  padding-left: 15px !important;
}
.ant-input-lg {
  font-size: 15px;
}
.ant-form label.as-placeholder {
  color: #2a2a2a;
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  transform: rotate(135deg) translateX(2.1px);
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  transform: rotate(-143deg) translateX(2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before {
  transform: rotate(-40deg) translateX(2.5px);
}
/*-----------ANT-FORM-------*/

/***********GLOBAL-CONTENT-CSS***********/

/*********MAIN-CONTENT-CSS***********/

.ant-form-vertical .ant-row {
  flex-direction: row;
}

.dynamic-delete-button {
  position: relative;
  /*top: 7px;*/
  margin: 0 8px;
  color: #999;
  font-size: 24px;
}

:focus-visible {
  outline: none;
}

.dynamic-delete-button:hover {
  color: #777;
}

.gx-sidebar-notifications {
  padding: 20px 10px;
  margin: 0 20px;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  margin-top: 10px;
  height: calc(100vh - 172px);
  overflow: hidden auto;
}

.gx-layout-sider-header .gx-site-logo {
  width: 78px;
}

.mobile-logo {
  width: 40px;
}
.ant-layout-footer {
  padding: 10px 32px;
  color: #bbbbbb;
  font-size: 12px;
  background: #fffffe;
  text-align: center;
  font-weight: 400;
}
.overdue-amount
  .ant-form
  .ant-row
  .ant-col:nth-last-child(-n + 2)
  .ant-form-item,
.zones .ant-form .ant-row .ant-col .ant-form-item,
.user-batch .ant-form .ant-row .ant-col .ant-form-item,
.subTopic .ant-form .ant-row .ant-col:nth-last-child(-n + 2) .ant-form-item,
.package .ant-form .ant-row .ant-col:nth-last-child(-n + 2) .ant-form-item,
.course .ant-form .ant-row .ant-col:nth-last-child(-n + 3) .ant-form-item,
.batch .ant-form .ant-row .ant-col:last-child .ant-form-item,
.sub-course .ant-form .ant-row .ant-col:last-child .ant-form-item,
.department .ant-form .ant-row .ant-col .ant-form-item:last-child,
.categories .ant-form .ant-row .ant-col .ant-form-item,
.sub-categories .ant-form .ant-row .ant-col .ant-form-item,
.sub-department .ant-form .ant-row .ant-col:last-child .ant-form-item,
.branch .ant-form .ant-row .ant-col:nth-last-child(-n + 4) .ant-form-item,
.user .ant-form .ant-row .ant-col:nth-last-child(-n + 2) .ant-form-item {
  margin-bottom: 0px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder,
.ant-select-in-form-item .ant-select-selector {
  padding: 0 !important;
}

.ant-select-in-form-item .ant-select-selector {
  padding-left: 15px !important;
}

.ant-picker-suffix,
.ant-input-suffix .ant-input-password-icon.anticon {
  color: #ea5455;
}

.ant-input-number-input[disabled],
.ant-picker.ant-picker-disabled,
.ant-input[disabled] {
  background-color: #fff5f5;
}

.last-input .ant-form-item {
  margin-bottom: 0 !important;
}

.ant-btn {
  height: 40px;
}

.gx-search-bar .ant-input {
  box-shadow: none;
}

// .ant-select-selection-item,
// .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
//     line-height: 43px !important;
//     font-size: 14px;
//     text-align: left;
//     letter-spacing: .7px;
//     font-weight: 300;
//     color: #2a2a2a;
// }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #545454;
  font-weight: 500;
  background-color: #ffe7e8;
}
.ant-picker-clear,
.ant-select-clear {
  right: 11px;
  color: rgb(234 84 85 / 66%);
}

.ant-picker-clear:hover,
.ant-select-clear:hover {
  color: rgb(234 84 85 / 100%);
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover,
.table-striped tbody tr:nth-of-type(even) {
  background: #fff8f8;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #ea54553d;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  left: 0;
  right: 0;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #ffe5e5;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(
    .ant-select-customize-input
  )
  .ant-select-selection-search-input {
  height: 100%;
  padding-left: 15px !important;
}

.roles-details {
  padding-left: 0;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-width: 150px;
  max-width: 100%;
  position: relative;
}

.roles-details p {
  margin-left: 0px;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.role-modal-details {
  overflow: auto !important;
  max-height: 250px;
}

.roles-details p:last-child {
  margin-bottom: 0 !important;
}
.view-remarks button,
.roles-details button {
  background: transparent;
  border: none;
  padding: 0;
  font-size: 11px;
  height: unset;
  line-height: 1;
  padding: 3px 8px !important;
  cursor: pointer;
  margin: 0 !important;
  border-radius: 5px;
}
.view-remarks .ant-popover-inner {
  width: 375px;
}
.view-remarks .ant-popover-inner .ant-popover-inner-content {
  padding: 10px;
}
.ant-popover-inner-content {
  padding: 0px;
  width: 100%;
}
.view-remarks .ant-popover-placement-bottom {
  left: -73px !important;
}
.ant-popover-title {
  min-height: unset;
  padding: 6px 16px;
}
.ant-popover-inner-content p p {
  font-size: 12px;
  padding: 8px 15px;
  margin-bottom: 0px !important;
}
.ant-popover-inner-content > p,
.ant-popover-inner-content p p:last-child {
  margin-bottom: 0px !important;
}
.ant-input:focus,
.ant-input-focused,
.ant-input:hover {
  border-color: #ea5455;
}

.ant-form-item .ant-form-item {
  margin-bottom: 0px;
}

.role .ant-form-item {
  margin-bottom: 0;
}

.zones .float-label {
  padding-bottom: 0;
}

.ant-table-thead th.ant-table-column-sort,
.ant-table-thead th.ant-table-column-has-sorters:hover,
.ant-table-thead > tr > th {
  background: #ea5455;
  color: #fff;
}

.gx-icon-btn {
  height: auto;
}

.table-card .ant-card-body {
  padding: 10px;
}

.ant-pagination > li {
  margin-bottom: 0;
}

.ant-table-column-sorter {
  color: #fff !important;
}

.ant-btn.ant-btn-default {
  border-color: #ea5455 !important;
  color: #ea5455 !important;
}

.ant-btn.ant-btn-default:hover {
  border-color: #ea5455 !important;
  background-color: #ea5455 !important;
  color: #fff !important;
}

.ant-btn.ant-btn-default:hover .anticon.anticon-upload {
  color: #fff;
}

.gx-sidebar-content .ant-menu-item:hover,
.gx-sidebar-content .ant-menu-submenu-title:hover {
  background: #ffecec !important;
}

.ant-menu-item:focus-visible,
.ant-menu-submenu-title:focus-visible {
  box-shadow: none;
}

.ant-picker-large .ant-picker-input > input {
  font-size: 14px;
  height: 100%;
}

.ant-picker-header-view button:hover {
  color: #ea5455;
}
.ant-picker-large {
  padding-top: 0;
  padding-bottom: 0;
}

.ant-layout.ant-layout-has-sider > .ant-layout {
  background: #fffcfc;
}

.ant-select {
  position: relative;
}

.ant-select-arrow {
  color: #ea5455;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  margin: 0;
}

.gx-sidebar-content {
  height: 100%;
  position: relative;
}

.ant-picker-today-btn:hover,
.ant-pagination-item:hover a,
a:hover,
.gx-link:hover,
a:focus,
.gx-link:focus,
.ant-pagination-item-active a,
.ant-pagination-item-active:hover a {
  color: #ea5455;
}

.ant-pagination-item:hover,
.ant-pagination-item-active:hover,
.ant-pagination-item-active {
  border-color: #ea5455 !important;
}

/*.ant-select:not(.ant-select-disabled):hover .ant-select-selector,*/
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #ea5455 !important;
  color: #ea5455;
}

.gx-layout-sider-header .gx-linebar {
  position: unset;
}

.gx-layout-sider-header {
  padding-left: 20px;
}

.ant-pagination-total-text {
  height: auto;
  line-height: 1;
}

.gx-sidebar-content .ant-menu-item a:hover,
.gx-sidebar-content .ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #ea5455;
}

.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow:before {
  background: #ea5455;
}

.ant-avatar > img {
  border-radius: 50%;
}

.gx-size-40 {
  border: 1.5px solid #ea5455;
  padding: 2px !important;
}

.gx-sidebar-notifications {
  border-bottom: solid 1px #ffd7d8;
  margin: 0;
  padding: 12px 20px;
  width: 100%;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ea5455;
  border-right-width: 1px;
}

/*.gx-sidebar-content {
    border-right: solid 1px #e3e1ff;
}
*/

.gx-icon-btn:focus,
.gx-icon-btn:hover {
  background: transparent;
}

/*.ant-dropdown {
    left: 1762px !important;
}*/

.ant-dropdown-menu-title-content .ant-btn-dangerous,
.ant-dropdown-menu-title-content .btn_view,
.ant-dropdown-menu-title-content .btn_edit {
  height: auto;
  line-height: 1;
}

.ant-dropdown-menu-title-content .ant-btn-dangerous > span,
.btn_edit > span {
  line-height: 1;
  color: #54b3ea;
}

/*.ant-menu-item:active, .ant-menu-submenu-title:active {
    background: #eae8ff;
}*/
.branch .branch-selected a {
  margin-left: 30px;
  padding: 7px 20px;
  border: 1px solid #ea5455;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  text-align: center;
}

.branch .branch-selected a i {
  margin-left: 8px;
  line-height: 0;
}

.user-profile > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gx-lt-icon-search-bar-lg input[type="search"]::placeholder {
  color: #ea5455;
  opacity: 0.5;
}

.ant-dropdown-menu-item:hover .ant-dropdown-menu-title-content .btn_edit {
  color: #54b8ea;
}

.ant-dropdown-menu-title-content .ant-btn-dangerous {
  background: transparent;
  color: #646464 !important;
}

.ant-dropdown-menu-item:hover
  .ant-dropdown-menu-title-content
  .ant-btn-dangerous {
  color: #ea5455 !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: #fff2f3c9;
}

.ant-dropdown-menu-item:hover .ant-menu-light .ant-menu-item {
  color: #ea5455;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #ea5455;
}

.ant-dropdown-menu-title-content .ant-menu-title-content {
  line-height: 22px;
}

.ant-dropdown-menu .ant-menu-vertical,
.ant-dropdown-menu .ant-menu-vertical .ant-menu-item:after {
  border: none;
}

.ant-dropdown-menu .ant-menu-vertical .ant-menu-item {
  padding: 0 !important;
  height: auto;
  line-height: 1;
  text-align: left;
}

.ant-dropdown-menu .ant-menu {
  background: transparent;
}

.ant-table-thead > tr > th::before {
  position: absolute !important;
  top: 50% !important;
  right: 0 !important;
  width: 1px !important;
  height: 1.6em !important;
  background-color: rgb(255 255 255 / 50%) !important;
  transform: translateY(-50%) !important;
  transition: background-color 0.3s !important;
  content: "" !important;
}
.ant-table-thead > tr > th:last-child:before {
  right: unset;
  left: 0;
}
.role .ant-row {
  align-items: center;
}

.ant-dropdown-menu-title-content .ant-btn {
  padding: 0;
}

.branches .float-label,
.zone .float-label,
.role .float-label {
  padding: 0;
}

.table-card .ant-btn-ghost.btn_edit,
.sub-departments .ant-btn-ghost.btn_edit,
.departments .ant-btn-ghost.btn_edit,
.branches .ant-btn-ghost.btn_edit,
.zone .ant-btn-ghost.btn_edit,
.roles .ant-btn-ghost.btn_edit {
  border-color: #54b8ea;
  color: #54b3ea;
  background: #fff;
}
.btn_view {
  background: #fff;
}
.table-card .full-sc-bt .ant-btn,
.sub-departments .full-sc-bt .ant-btn,
.departments .full-sc-bt .ant-btn,
.branches .full-sc-bt .ant-btn,
.zone .full-sc-bt .ant-btn,
.roles .full-sc-bt .ant-btn {
  height: 30px;
  line-height: 31px;
  padding: 0 10px;
}

.table-card .full-sc-bt .ant-btn span:not(.anticon),
.sub-departments .full-sc-bt .ant-btn span:not(.anticon),
.departments .full-sc-bt .ant-btn span:not(.anticon),
.branches .full-sc-bt .ant-btn span:not(.anticon),
.zone .full-sc-bt .ant-btn span:not(.anticon),
.roles .full-sc-bt .ant-btn span:not(.anticon),
.hold-date-model .full-sc-bt .ant-btn span:not(.anticon) {
  display: none;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px 16px;
  text-transform: capitalize;
}

.ant-switch,
.roles .ant-switch {
  min-width: 38px;
  height: 18px;
  line-height: 18px;
  width: 30px;
}

.ant-switch-handle,
.roles .ant-switch-handle {
  width: 14px;
  height: 14px;
}

.ant-switch-checked .ant-switch-handle,
.roles .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 15px - 2px);
}

.branch .ant-col:nth-last-child(4) .float-label {
  padding-bottom: 0;
}

.branch .ant-col:nth-last-child(4) .ant-form-item {
  margin-bottom: 0;
}

.zone .ant-form-item {
  margin-bottom: 0;
}

.departments .ant-card-body,
.sub-departments .ant-card-body,
.zone .ant-card-body {
  padding: 10px;
}

.ant-switch-checked {
  background: #ea5455;
}

.branches .ant-card-body {
  padding: 10px;
}

.ant-menu-submenu .ant-menu-sub,
.ant-menu-item,
.ant-menu-submenu-title {
  transition: all 0.1s !important;
}

.ant-menu-title-content {
  transition: unset;
}

.ant-menu-item:hover a {
  color: #ea5455;
}

.erp-filter-btn {
  padding-right: 15px;
}

.admission-card {
  margin: 18px;
}

.admission-card {
  border-top: 2px solid #ea5455 !important;
  box-shadow: 0 0 2.1875rem rgb(90 97 105 / 7%),
    0 0 1.40625rem rgb(90 97 105 / 7%), 0 0 0.53125rem rgb(90 97 105 / 9%),
    0 0 0.1875rem rgb(90 97 105 / 7%);
  border-radius: 10px;
  position: relative;
}

.erp-button-group {
  padding-left: 15px;
}

.erp-main .ant-btn.ant-btn-default {
  color: #fff !important;
  border-color: initial;
}

.avatar-box {
  display: flex;
  align-items: center;
  background: #fff0f0;
  padding: 27px 20px !important;
  border-radius: 10px;
  margin-right: 0px !important;
}

.erp-details p {
  margin-bottom: 3px;
  color: #545454;
  font-weight: 600 !important;
}

.action-heading h3 {
  color: #ea5455;
}

.admission-card .ant-avatar {
  background: #ea5455;
}
.more-btn {
  background: #ea5455;
  text-align: center;
  border-radius: 5px;
  transform: rotate(0deg);
  width: 25px;
  margin: auto;
  margin: 0 auto 2.5px;
}
.more-btn span {
  transform: rotate(90deg);
}
.action-icon > div {
  padding: 5px 0;
  text-align: center;
  margin: 0;
  line-height: 1;
  width: 25px;
  border-radius: 5px;
  font-size: 14px;
  margin: 5px auto;
  background: #ea5455;
}

.action-icon-disable > div {
  padding: 5px 0;
  text-align: center;
  margin: 0;
  line-height: 1;
  width: 25px;
  border-radius: 5px;
  font-size: 14px;
  margin: 5px auto;
  background: #ee8787;
  cursor: none;
}

.ant-drawer-header {
  padding: 16px 4px;
}

.edit-icon-wrapper {
  top: 13px;
  right: 47px;
  background-color: #ea5455;
}

.ant-input-number {
  height: 43px;
  background: #ffffff;
  border-color: #c0bbff !important;
  box-shadow: none !important;
  width: 100%;
}

::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ea5455;
}
.rc-virtual-list-scrollbar {
  width: 4px !important;
}
.rc-virtual-list-scrollbar-thumb {
  background-color: #ea5455 !important;
}
.ant-drawer-content-wrapper {
  padding: 0 15px !important;
  border-radius: 10px !important;
}

.ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right
  .ant-drawer-content-wrapper {
  overflow: hidden;
  width: 550px !important;
}

.add-addmission .ant-row {
  margin: 0 !important;
  align-items: start;
}

.add-addmission .gx-page-title {
  border: none;
  padding: 0px 0px;
}

.add-addmission .ant-divider-horizontal.ant-divider-with-text {
  margin: 0 !important;
  padding-bottom: 30px !important;
  position: relative;
}

.ant-divider-horizontal {
  position: relative !important;
}

.add-addmission .ant-divider-horizontal.ant-divider-with-text::after {
  display: none;
}

.add-addmission .ant-card-body {
  padding: 15px 14px 5px;
}

.add-addmission .ant-typography {
  margin-bottom: 5px;
}

.gx-field-wrapper {
  margin: 0;
}

.line {
  position: absolute;
  height: 0px;
  width: 100%;
  border-top: 0.5px solid #fdbfb9;
  display: inline-block;
  bottom: 32%;
  left: 0;
  transform: translateY(-50%);
}

.add-addmission .ant-divider-inner-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f44336;
  border-color: #f44336 !important;
}

.ant-checkbox-checked::after,
.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #f44336 !important;
}

.postal-communication .ant-row {
  align-items: center;
}

.postal-communication .gx-field-wrapper {
  margin: 0;
}

.postal-communication .ant-card-body .ant-col {
  padding-right: 0 !important;
}

.ant-form-item.last-input {
  margin-bottom: 0;
}

.ant-divider-horizontal.ant-divider-with-text,
.ant-divider-horizontal.ant-divider-with-text-left,
.ant-divider-horizontal.ant-divider-with-text-right {
  padding-bottom: 0 !important;
}

.ant-message {
  top: 70px;
}

.add-addmission .ant-divider-horizontal.ant-divider-with-text.sub-heading {
  padding-bottom: 10px !important;
}

/*.parent-details-input .ant-col:nth-child(4n+1),
.add-addmission .ant-card-body .ant-col:nth-child(4n+1) {
    padding-right: 0 !important;
}*/
/*.add-addmission .ant-card-body > .ant-col{
    padding-right:0!important;

}*/
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-checked .ant-radio-inner {
  border-color: #ea5455;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgb(99 88 237 / 12%);
}

.ant-radio-inner::after {
  background: #ea5455;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #fff4f4;
}

.ant-drawer-body {
  padding: 15px 0px;
}
.ant-modal .ant-btn.btn-submit,
.ant-modal .ant-btn.btn-cancel,
.ant-modal-body .ant-btn.btn-submit,
.ant-modal-body .ant-btn.btn-cancel,
.ant-drawer .ant-btn.btn-submit,
.ant-drawer .ant-btn.btn-cancel {
  height: 38px;
  padding: 0 35px;
  width: 116px;
}
.ant-drawer .ant-drawer-footer .ant-btn.btn-cancel,
.ant-drawer .ant-drawer-footer .ant-btn.btn-submit {
  height: 45px;
  padding: 0 35px;
  font-size: 16px;
  letter-spacing: 0.5px;
  width: 100%;
}

.modal-btn-grp {
  margin-bottom: 0 !important;
  margin-top: 0px !important;
  padding: 15px 0 !important;
  text-align: right;
  border-top: 1px solid #e8e8e8;
}

.ant-drawer-header {
  position: relative;
}

.ant-drawer-close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  margin: 0;
}

.ant-drawer-title {
  text-align: center;
}

.ant-drawer-open {
  backdrop-filter: blur(3px);
}

.ant-upload.ant-upload-select,
.ant-input-affix-wrapper-lg {
  width: 100% !important;
}

.ant-input-affix-wrapper {
  height: 43px;
  background-color: #fffbfb99;
  border-color: #ffcdcd !important;
  box-shadow: none !important;
  padding: 0;
  overflow: hidden;
}

.anticon.anticon-upload {
  color: #ea5455;
}

.label {
  z-index: 9;
}

.ant-drawer .ant-divider-horizontal.ant-divider-with-text-left::after {
  display: none;
}

.ant-drawer .ant-divider-horizontal.ant-divider-with-text {
  margin: 10px 0 5px;
  padding-left: 5px;
}

.ant-input-affix-wrapper .ant-input {
  padding: 15px;
}

.ant-form
  .custom-label-com:first-child
  .ant-divider-horizontal.ant-divider-with-text {
  margin-top: 0 !important;
}

.ant-upload.ant-upload-select.ant-upload-select-text {
  position: relative;
}

.ant-input-prefix {
  position: absolute;
  z-index: 99;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number:hover,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover {
  background: #fff;
}

.job-label {
  text-align: left;
}

.job-label label.ant-form-item-required,
.shining-label label {
  font-weight: 600;
}

.job-label .ant-col.ant-form-item-label {
  padding: 0;
}

.shining-label .ant-col.ant-form-item-label {
  margin: 10px 0 5px;
  padding: 0;
}

.shining-label button {
  width: 100%;
}

.total-fees {
  margin-bottom: 15px;
}

.ant-form-item-explain-error {
  text-align: left;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.5px;
  margin-top: 3px;
}

.ant-input-number-input {
  padding: 0 15px;
  font-size: 14px;
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number-lg input,
.ant-input-number-input-wrap {
  // height: 100%;
  // line-height: 42px;
  overflow: hidden;
}

.ant-select-multiple .ant-select-selection-item {
  line-height: 20px !important;
}

.ant-select-selection-overflow {
  flex-wrap: nowrap;
  overflow: hidden;
  max-width: 94%;
}

.ant-drawer-body .ant-input-number {
  margin-right: 0px;
}

.ant-pagination-options .ant-select-selector {
  padding-right: 30px !important;
}

.ant-pagination-options .ant-select-arrow {
  right: 13px;
}

.ant-pagination-options .ant-select-selection-item {
  line-height: 35px !important;
}

.course-name {
  margin-top: 10px;
}

.ant-input-number {
  height: 43px;
  background-color: #fffbfb99;
  border-color: #ffcdcd !important;
  box-shadow: none !important;
  width: 100%;
}

.ant-btn:not(:last-child) {
  margin-right: 7px;
}

.ant-btn-icon-only.ant-btn-lg {
  max-width: 36px;
}
.add-remove-user .ant-btn-icon-only.ant-btn-lg {
  max-width: 100%;
  width: 100%;
}

.ant-drawer.ant-drawer-right.ant-drawer-open .ant-input-number {
  width: 100% !important;
}
.ant-drawer.ant-drawer-right.assign-batch.ant-drawer-open.ant-drawer-right
  .ant-drawer-content-wrapper {
  overflow: hidden;
  width: 1000px !important;
}
.ant-modal-content
  .ant-select-multiple.ant-select-lg
  .ant-select-selection-item,
.ant-drawer-right .ant-select-multiple .ant-select-selection-item {
  line-height: 22px !important;
  margin-top: 4px;
  margin-bottom: 2px;
}
.ant-modal-content
  .ant-select-multiple.ant-select-lg
  .ant-select-selection-item,
.ant-drawer-right
  .ant-select-multiple.ant-select-lg
  .ant-select-selection-item {
  height: 22px;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #ea5455;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #ea5455;
}

.ant-picker-today-btn {
  color: #ea5455;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #ea5455;
}

.radio-group {
  padding: 0 !important;
  margin-top: 3px;
}

.ant-modal-confirm-body > .anticon {
  float: unset;
  font-size: 30px;
}

.ant-modal-confirm-body .anticon-delete svg {
  width: 24px;
}

.ant-modal-confirm-body {
  text-align: center;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
  text-align: center;
}

.ant-layout-sider-collapsed .icon-chevron-down.gx-d-xl-inline-block {
  display: none !important;
}

.ant-layout-sider-collapsed .gx-avatar-name {
  display: none !important;
}

.ant-layout-sider {
  background-color: #ffffff;
}

.ant-menu-submenu .ant-menu-sub,
.ant-menu-item,
.ant-menu-submenu-title {
  transition: all 0.1s !important;
}

.ant-menu-title-content {
  transition: unset;
}

// .ant-menu-item:hover a {
//     color: #fff;
// }

.erp-filter-btn {
  padding-right: 15px;
}

.admission-card {
  margin: 10px 14px;
  padding: 10px;
}

.erp-button-group {
  padding-left: 15px;
}

.ant-drawer-content-wrapper {
  padding: 0 15px !important;
  border-radius: 0 !important;
}

.navigation-icons {
  display: flex;
}

.navigation-icons .gx-app-nav {
  flex-wrap: unset;
  flex-direction: row;
}

.add-addmission .ant-row {
  margin: 0 !important;
}
textarea.ant-input {
  min-height: 43px;
}
.add-addmission .gx-page-title {
  border: none;
  padding: 0px 0px;
}

.individual {
  padding: 15px 10px 0 !important;
  background: #f9f9f9;
  border-radius: 10px;
  margin: 0px 0 20px !important;
  margin-bottom: 15px !important;
}

.sub-heading .ant-divider-horizontal.ant-divider-with-text {
  padding-bottom: 0 !important;
  margin-bottom: 10px !important;
}

.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 60px !important;
}

.ant-layout-sider {
  width: 250px !important;
}
.ant-layout-sider-collapsed {
  width: 60px !important;
  overflow: unset;
}
.ant-btn > span + .anticon {
  margin-left: 0px;
}
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title {
  padding: 0 calc((63px - 20px) / 2) !important;
}
.ant-layout-sider-collapsed .gx-layout-sider-header {
  padding-left: 10px;
  padding-right: 10px;
}

// .anticon.anticon-upload {
//   margin-right: 8px;
// }

.individual {
  width: 100%;
}

.make-installment {
  margin-bottom: 8px;
}

.make-installment .ant-btn,
.make-installment .ant-input {
  height: 35px;
}

.make-installment .ant-btn {
  font-size: 12px;
}

.make-installment .label.as-placeholder {
  top: 9px;
}

.view-addmission .ant-table-tbody > tr > td {
  padding: 0;
}

.view-addmission .ant-table-tbody > tr.ant-table-row:hover > td,
.view-addmission .ant-table-tbody > tr > td.ant-table-cell-row-hover,
.view-addmission .table-striped tbody tr:nth-of-type(even) {
  background: transparent;
}

.view-addmission .ant-table-thead > tr > th {
  background: transparent;
  padding: 0;
}

.ant-table-tbody > tr > td {
  border: none;
}

.view-addmission table {
  padding: 0 16px;
}

.view-addmission .card-details.erp-details {
  text-align: left;
}
.sidebar-menu .ant-menu-title-content {
  display: flex !important;
  align-items: center;
}
.ant-layout-sider .ant-menu .ant-menu-submenu-title span {
  display: unset;
}

.sidebar-menu .ant-menu-title-content i {
  margin-right: 10px !important ;
}
.ant-upload-list-item {
  height: 28.2px;
  overflow: hidden;
  border-radius: 5px;
}
.ant-drawer-body .drawer-body-scroll {
  max-height: calc(100vh - 152px);
  min-height: calc(100vh - 152px);
  overflow: auto;
  padding-top: 4px;
}
.crm-history .ant-drawer-body .drawer-body-scroll,
.remark-admission .ant-drawer-body .drawer-body-scroll {
  max-height: calc(100vh - 85px);
  min-height: calc(100vh - 85px);
}
.remark-admission .ant-drawer-body .drawer-body-scroll .ant-table-content {
  max-height: calc(100vh - 450px);
  min-height: 180px;
}

.course-info .ant-drawer-body .drawer-body-scroll {
  max-height: calc(100vh - 75px);
  min-height: calc(100vh - 75px);
}
.course-info .ant-drawer-body .drawer-body-scroll .ant-table-content {
  max-height: calc(100vh - 345px);
  min-height: calc(100vh - 345px);
}

.payment-details .ant-drawer-body .drawer-body-scroll {
  max-height: unset;
}

.payment-details .ant-table-content {
  max-height: calc(100vh - 273px);
}
@media (min-width: 992px) {
  .gx-search-bar {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
  }
}

@media (max-width: 370px) {
  .upload-images {
    height: 157px !important;
    width: 45% !important;
  }
  .view-btn {
    bottom: -46px;
    right: -8px;
  }
  .add-btns .ant-btn {
    font-size: 12px !important;
  }
  .anticon svg {
    width: 12px !important;
  }
  .ant-drawer-body .drawer-body-scroll {
    max-height: calc(100vh - 152px);
    overflow: hidden auto;
  }

  .add-view-upload-images {
    left: 19% !important;
  }
  .ant-drawer-body .drawer-body-scroll {
    overflow: hidden auto;
  }
}

@media (max-width: 575px) {
  .course-info .ant-drawer-body .drawer-body-scroll .ant-table-content {
    max-height: calc(100vh - 385px);
    min-height: calc(100vh - 385px);
  }
  .roles-details button {
    right: 0;
  }
  .ant-drawer-content-wrapper {
    padding: 0 10px !important;
  }

  .ant-drawer-right .ant-select-multiple .ant-select-selection-item {
    line-height: 24px !important;
  }

  .ant-drawer-right
    .ant-select-multiple.ant-select-lg
    .ant-select-selection-item {
    height: 25px;
  }

  .ant-modal-confirm .ant-modal-confirm-btns {
    text-align: center;
  }

  .ant-modal-confirm-body > .anticon.anticon-delete {
    float: unset;
  }

  .rnw-page-title {
    font-size: 15px;
    font-weight: 500;
  }

  .add-btns .ant-btn {
    font-size: 13px;
  }

  .location-label
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item,
  .location-label .ant-select-selection-item {
    line-height: 38px !important;
  }

  .location-label
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    font-size: 12px;
    font-weight: 300;
    color: #2a2a2a;
    letter-spacing: 0.7px;
    line-height: 38px !important;
  }

  /*.modal-btn-grp {
        margin-top: 20px !important;
    }*/
  .gx-main-content-wrapper {
    padding: 20px 10px 0;
  }

  .ant-card {
    margin-bottom: 10px;
  }

  .ant-switch,
  .roles .ant-switch {
    min-width: 31px;
    height: 15px;
    line-height: 15px;
    width: 29px;
  }

  .ant-switch-handle,
  .roles .ant-switch-handle {
    width: 11px;
    height: 11px;
  }

  .ant-switch-handle {
    left: 3px;
  }

  .ant-picker.ant-picker-large,
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-input {
    height: 40px;
    box-shadow: none !important;
    background-color: #fffbfb99;
    border-color: #ffcdcd !important;
  }

  .ant-switch-checked .ant-switch-handle,
  .roles .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 12px - 2px);
  }

  .ant-avatar.ant-avatar-circle.ant-avatar-icon {
    height: 40px !important;
    width: 40px !important;
    line-height: 40px !important;
  }

  .avatar-box {
    padding: 10px !important;
  }

  .avatar-box .card-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .course-name {
    margin-top: 0;
    margin-bottom: 0;
  }

  .erp-button-group .ant-badge.ant-badge-not-a-wrapper {
    margin-right: 0;
  }

  .erp-button-group {
    padding-left: 15px;
    padding-right: 15px;
  }

  .erp-filter-btn .ant-btn,
  .erp-button-group .ant-btn {
    height: auto;
    padding: 5px;
    line-height: 1;
    font-size: 11px;
  }

  .filter-btn {
    float: right;
  }

  // .card-bottom {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: flex-start;
  // }

  .action-heading {
    text-align: left;
  }

  .erp-filter-btn .ant-btn,
  .erp-button-group .ant-btn {
    height: auto;
    padding: 8px;
    line-height: 1;
    font-size: 12px;
  }

  .erp-button-group .ant-btn {
    margin-top: 7px !important;
  }

  .erp-button-group .ant-badge-count {
    height: 16px;
    padding: 0 4px;
    font-size: 12px;
    line-height: 16px;
  }

  .gx-double-right-icon-wrapper .gx-double-right-icon {
    // height: 30px;
    width: 30px;
    position: unset;
    transform: rotate(90deg);
    // margin: 0 auto 15px auto;
  }

  .upload-btns span {
    font-size: 12px;
  }

  .upload-btns button {
    width: 100%;
  }

  .anticon svg {
    width: 16px;
  }

  .installment-number {
    padding: 5px;
    text-align: center;
    margin: 10px 0 !important;
    background: #ffeeef;
    border-radius: 10px;
  }

  .gx-make-installment-btn {
    margin: 10px 0 !important;
  }

  .ant-form-item-explain-error {
    font-size: 9px;
  }

  .ant-radio-wrapper {
    margin-right: 0;
  }

  .ant-radio-inner {
    width: 14px;
    height: 14px;
  }

  .ant-form label.as-placeholder {
    max-width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-form label {
    font-size: 12px;
  }

  .overdue-amount
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 2)
    .ant-form-item,
  .package .ant-form .ant-row .ant-col:nth-last-child(-n + 2) .ant-form-item,
  .course .ant-form .ant-row .ant-col:nth-last-child(-n + 3) .ant-form-item,
  .batch .ant-form .ant-row .ant-col:last-child .ant-form-item,
  .sub-course .ant-form .ant-row .ant-col:last-child .ant-form-item,
  .department .ant-form .ant-row .ant-col .ant-form-item:last-child,
  .categories .ant-form .ant-row .ant-col .ant-form-item,
  .sub-categories .ant-form .ant-row .ant-col .ant-form-item,
  .sub-department .ant-form .ant-row .ant-col:last-child .ant-form-item,
  .branch .ant-form .ant-row .ant-col:nth-last-child(-n + 4) .ant-form-item,
  .user .ant-form .ant-row .ant-col:nth-last-child(-n + 2) .ant-form-item {
    margin-bottom: 15px;
  }

  .gx-layout-footer-content {
    text-align: center;
  }

  .ant-select-selection-item {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .zones .ant-form .ant-row .ant-col .ant-form-item,
  .role .ant-form-item {
    margin-bottom: 15px;
  }

  .branch .branch-selected a {
    margin-left: 0px;
    font-size: 14px;
    padding: 7px 10px;
    width: 90px;
    font-size: 12px;
  }

  .branch .branch-selected a span {
    white-space: nowrap;
    max-width: 81px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .gx-linebar.gx-mr-3 {
    margin: 0 !important;
  }

  .gx-icon-btn {
    width: auto;
    margin-right: 8px;
  }

  .mobile-user {
    border: 1px solid #ea5455;
    border-radius: 50%;
    padding: 2px;
  }

  .gx-header-notifications > li:not(:last-child) {
    margin-right: 0px;
  }

  .gx-header-notifications > li {
    font-size: 16px;
  }

  .ant-card-body .ant-col {
    padding-left: 12px !important;
  }

  .ant-card-body .ant-col .ant-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .individual .ant-row .ant-col {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .sub-categories .ant-form .ant-row .ant-col .ant-form-item {
    margin-bottom: 15px;
  }

  .anticon.anticon-minus-circle svg {
    width: auto;
  }

  .gx-linebar.gx-mr-3 {
    margin: 0 !important;
  }

  .ant-drawer-left .ant-drawer-content-wrapper {
    padding: 0 0px !important;
  }

  .ant-drawer-left .ant-drawer-body {
    padding: 0;
  }

  .gx-sidebar-content {
    height: calc(100vh - 60px);
    overflow: hidden auto;
  }

  .gx-layout-sider-header {
    position: relative;
  }

  .close-btn {
    font-size: 24px;
    position: absolute;
    right: 13px;
    line-height: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
  .ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
    padding-left: 60px !important;
  }

  .ant-drawer-left > .ant-drawer-content-wrapper {
    height: 100vh;
  }

  .user .ant-form .ant-row .ant-col:nth-last-child(-n + 2) .ant-form-item,
  .last-input .ant-form-item {
    margin-bottom: 15px !important;
  }

  .ant-col.last-input:last-child .ant-form-item {
    margin-bottom: 0px !important;
  }

  .ant-table {
    font-size: 12px;
  }

  .ant-input-lg,
  .ant-select-lg {
    font-size: 14px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector::after,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 43px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-left: 4px;
  }

  .branch-list {
    right: -20px;
  }

  .branch-list li a {
    font-size: 13px;
  }

  .ant-drawer .ant-btn.btn-cancel,
  .ant-btn {
    height: 35px;
    padding: 0 10px;
  }

  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 95% !important;
  }

  .ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    top: 0;
    right: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .ant-drawer-body .ant-col {
    padding: 0 15px !important;
  }

  .ant-drawer-body .ant-col .ant-col {
    padding: 0 !important;
  }

  .ant-form div > .ant-row,
  .ant-form > .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .modal-btn-grp {
    border-top: 1px solid #e8e8e8;
    padding: 13px 0px !important;
    margin-bottom: 0px !important;
    margin-top: 15px;
  }

  .add-remove .ant-col .ant-row {
    align-items: center;
  }

  .add-remove .ant-form-item {
    margin-bottom: 0;
  }

  .add-remove .ant-col:last-child {
    padding-left: 0 !important;
  }

  .ant-btn-icon-only.ant-btn-lg,
  .add-remove .ant-col .ant-row .ant-btn {
    height: 41px;
    width: 26px;
  }

  .ant-card-body {
    padding: 20px 10px;
  }

  .add-addmission .ant-card-body {
    padding: 15px 10px 5px;
  }

  .ant-btn.btn-cancel,
  .ant-btn[type="submit"] {
    width: 100% !important;
  }
  .ant-btn.btn-check-attendance-next[type="submit"] {
    width: auto !important;
  }
  .ant-btn.next-btn[type="submit"] {
    width: auto !important;
  }
  .tab-content .ant-btn.btn-cancel,
  .tab-content .ant-btn[type="submit"] {
    width: auto !important;
  }
  .cheque-filter .ant-btn[type="submit"] {
    width: auto !important;
  }
  .ant-drawer-header {
    position: relative;
  }

  .ant-drawer-close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1px;
    left: unset;
  }

  .ant-drawer-title {
    text-align: center;
  }

  .add-roles .ant-row .ant-col {
    padding: 0 !important;
  }

  .ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    overflow: hidden;
    width: 100% !important;
  }

  .gx-double-right-icon {
    width: unset;
    font-size: 24px;
  }

  .ant-btn.upload-btn span:nth-child(2) {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .ant-btn.btn-cancel,
  .ant-btn[type="submit"] {
    width: 100%;
  }
  .ant-btn.btn-check-attendance-next[type="submit"] {
    width: auto;
  }
  .tab-content .ant-btn.btn-cancel,
  .tab-content .ant-btn[type="submit"] {
    width: auto !important;
  }
  .cheque-filter .ant-btn[type="submit"] {
    width: auto !important;
  }
  .btn-col {
    // width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .roles-details button {
    right: 0;
  }
  .gx-make-installment-btn {
    margin: 10 0 10px 0 !important;
  }

  .add-remove .ant-col:last-child {
    padding-left: 0 !important;
  }

  .gx-main-content-wrapper {
    padding: 20px 15px 0;
  }

  .ant-pagination-options .ant-select-arrow {
    right: 9px;
  }

  .ant-card {
    margin-bottom: 15px;
  }

  .ant-avatar.ant-avatar-circle.ant-avatar-icon {
    height: 50px !important;
    width: 50px !important;
    line-height: 50px !important;
  }

  .avatar-box {
    padding: 10px !important;
  }

  .avatar-box .card-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .more-btn {
    height: 25px;
    line-height: 27px;
  }

  .view-addmission .table-striped .ant-table-content {
    overflow: hidden;
  }
  .course-name {
    margin-top: 0;
    margin-bottom: 0;
  }

  .erp-button-group .ant-badge.ant-badge-not-a-wrapper {
    margin-right: 0;
  }

  .gx-search-bar input[type="search"] {
    padding-left: 10px !important;
  }

  .erp-button-group {
    padding-left: 15px;
    padding-right: 15px;
  }

  .erp-filter-btn .ant-btn,
  .erp-button-group .ant-btn {
    height: auto;
    padding: 5px;
    line-height: 1;
    font-size: 11px;
  }

  .filter-btn {
    float: right;
  }

  .card-bottom {
    display: flex;
    justify-content: flex-start;
  }

  .action-heading {
    text-align: left;
  }

  .erp-filter-btn .ant-btn,
  .erp-button-group .ant-btn {
    height: auto;
    padding: 8px 15px;
    line-height: 1;
    font-size: 14px;
    height: 42px;
  }

  .erp-button-group .ant-btn {
    margin-top: 7px !important;
  }

  // .erp-button-group .ant-badge-count {
  //     height: 16px;
  //     padding: 0 4px;
  //     font-size: 12px;
  //     line-height: 16px;
  // }

  .gx-lt-icon-search-bar-lg {
    width: 39%;
  }

  .ant-pagination-options {
    display: none;
  }

  .ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    overflow: hidden;
    width: 70% !important;
  }

  .gx-search-bar .gx-form-group {
    width: 100%;
  }

  .branch .branch-selected a {
    margin-left: 0px;
    font-size: 14px;
    padding: 7px 10px;
    width: 100px;
    font-size: 12px;
  }

  .branch .branch-selected a span {
    white-space: nowrap;
    max-width: 61px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .gx-linebar.gx-mr-3 {
    margin: 0 !important;
  }

  .ant-drawer-left .ant-drawer-content-wrapper {
    padding: 0 0px !important;
  }

  .ant-drawer-left .ant-drawer-body {
    padding: 0;
  }

  .gx-sidebar-content {
    height: 89vh;
  }

  .anticon.close-btn svg {
    width: 14px;
  }

  .gx-layout-sider-header {
    position: relative;
  }

  .close-btn {
    font-size: 24px;
    position: absolute;
    right: 13px;
    line-height: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
  .ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
    padding-left: 60px !important;
  }

  .ant-drawer-left > .ant-drawer-content-wrapper {
    height: 100vh;
  }

  .ant-table {
    font-size: 12px;
  }

  .gx-search-bar {
    padding-left: 25px;
  }

  .gx-search-bar .gx-form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .gx-lt-icon-search-bar-lg .gx-search-icon {
    position: unset;
    height: unset;
    line-height: unset;
    width: auto;
  }

  .gx-lt-icon-search-bar-lg input[type="search"] {
    padding: 5px 18px 5px 15px;
  }

  .gx-search-icon i {
    font-size: 14px;
  }

  .zones .ant-form .ant-row .ant-col .ant-form-item,
  .role .ant-col:first-child .ant-form-item,
  .role .ant-col:nth-child(2) .ant-form-item {
    margin-bottom: 15px;
  }

  .sub-categories .ant-form .ant-row .ant-col .ant-form-item,
  .categories .ant-form .ant-row .ant-col .ant-form-item,
  .course .ant-form .ant-row .ant-col:nth-last-child(-n + 3) .ant-form-item,
  .branch .ant-form .ant-row .ant-col:nth-last-child(-n + 4) .ant-form-item {
    margin-bottom: 15px;
  }

  .sub-categories
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 2)
    .ant-form-item,
  .categories .ant-form .ant-row .ant-col .ant-form-item:last-child,
  .course .ant-form .ant-row .ant-col:nth-last-child(1) .ant-form-item,
  .branch .ant-form .ant-row .ant-col:nth-last-child(-n + 2) .ant-form-item {
    margin-bottom: 0px;
  }
  .zones .ant-form .ant-row .ant-col:nth-last-child(-n + 3) .ant-form-item {
    margin-bottom: 0px;
  }

  .last-input .ant-form-item {
    margin-bottom: 15px !important;
  }

  .add-addmission .ant-col.last-input:last-child .ant-form-item {
    margin-bottom: 0px !important;
  }

  .ant-radio-wrapper {
    margin-right: 5px;
    font-size: 13px !important;
  }

  .gx-double-right-icon-wrapper .gx-double-down-icon {
    position: unset;
    transform: rotate(90deg);
    height: 35px;
    width: 35px;
    margin: 10px auto 20px auto;
  }

  .anticon svg {
    width: 20px;
  }

  .ant-form label.as-placeholder {
    white-space: nowrap;
    max-width: 83%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 5px;
    font-size: 12px;
  }

  .ant-btn {
    padding: 0 10px;
  }

  .mobile-user {
    border: 1px solid #ea5455;
    border-radius: 50%;
    padding: 2px;
  }

  .gx-user-nav .gx-avatar {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
  .zones .ant-form .ant-row .ant-col .ant-form-item {
    margin-bottom: 15px;
  }
  .zones .ant-form .ant-row .ant-col:nth-last-child(2) .ant-form-item {
    margin-bottom: 0px;
  }
  .roles-details button {
    right: 0;
  }
  .add-remove .ant-col:last-child {
    padding-left: 0 !important;
  }

  span.ant-radio + * {
    padding-right: 3px;
    padding-left: 4px;
  }

  .ant-form label span {
    font-size: 12px;
  }

  .radio-group {
    padding: 0 !important;
    margin-top: 3px;
  }

  .ant-drawer.ant-drawer-right.ant-drawer-open .ant-input-number {
    width: 100% !important;
  }

  .gx-user-nav .gx-avatar {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }

  .gx-main-content-wrapper {
    padding: 20px 15px 0;
  }

  .ant-card {
    margin-bottom: 15px;
  }

  /*.modal-btn-grp {
            margin-top: 20px !important;
        }*/
  .ant-switch,
  .roles .ant-switch {
    min-width: 33px;
    height: 16px;
  }

  .ant-switch-handle,
  .roles .ant-switch-handle {
    width: 12px;
    height: 12px;
  }

  .ant-switch-handle {
    left: 3px;
  }

  .ant-switch-checked .ant-switch-handle,
  .roles .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 13px - 2px);
  }

  .ant-card-body .ant-btn {
    padding: 0;
  }
  .tab-content .btn-submit {
    padding: 4px 15px;
  }
  .ant-card-body .exam-submit-btn .ant-btn {
    padding: 5px 10px;
  }
  .sub-department
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 3)
    .ant-form-item {
    margin-bottom: 0;
  }

  .mobile-user {
    border: 1px solid #ea5455;
    border-radius: 50%;
    padding: 2px;
  }

  .gx-search-bar input[type="search"] {
    padding-left: 10px !important;
  }

  .ant-drawer-left .ant-drawer-content-wrapper {
    padding: 0 0px !important;
    border-radius: 15px !important;
  }

  .ant-drawer-left .ant-drawer-body {
    padding: 0;
  }

  .gx-sidebar-content {
    height: calc(100vh - 60px);
    overflow: hidden auto;
  }

  .gx-layout-sider-header {
    position: relative;
  }

  .close-btn {
    font-size: 24px;
    position: absolute;
    right: 15px;
    line-height: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
  .ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
    padding-left: 60px !important;
  }

  .ant-drawer-left > .ant-drawer-content-wrapper {
    height: 100vh;
  }

  .anticon.close-btn svg {
    width: 15px;
  }

  .ant-table {
    font-size: 12px;
  }

  .sub-course .ant-form .ant-row .ant-col:nth-last-child(-n + 4) .ant-form-item,
  .batch .ant-form .ant-row .ant-col:nth-last-child(-n + 4) .ant-form-item,
  .sub-department
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 3)
    .ant-form-item,
  .department
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 3)
    .ant-form-item {
    margin-bottom: 0;
  }

  // .branch .ant-form .ant-row .ant-col:nth-last-child(-n + 4) .ant-form-item {
  //   margin-bottom: 15px;
  // }

  .anticon svg {
    width: 14px;
  }

  .anticon.anticon-minus-circle svg {
    width: auto;
  }

  .label.as-placeholder {
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px !important;
  }

  .make-installment .as-placeholder {
    top: 9px;
  }

  .branch .branch-selected a {
    font-size: 14px;
    width: 120px;
    margin-left: 0;
  }

  .branch .branch-selected a span {
    white-space: nowrap;
    max-width: 61px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }

  .gx-lt-icon-search-bar-lg {
    width: 49%;
  }

  .gx-search-bar .gx-form-group {
    width: 100%;
  }

  .gx-search-bar {
    padding-left: 25px;
  }

  .gx-search-bar .gx-form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .gx-lt-icon-search-bar-lg .gx-search-icon {
    position: unset;
    height: unset;
    line-height: unset;
    width: auto;
  }

  .gx-lt-icon-search-bar-lg input[type="search"] {
    padding: 5px 18px 5px 10px;
  }

  .erp-filter-btn .ant-btn,
  .erp-button-group .ant-btn {
    height: auto;
    padding: 8px 15px;
    line-height: 1;
    font-size: 12px;
  }

  .erp-button-group .ant-btn {
    margin-top: 7px !important;
  }

  .erp-button-group .ant-badge-count {
    height: 16px;
    padding: 0 4px;
    font-size: 12px;
    line-height: 16px;
  }

  .erp-filter-btn {
    padding-left: 15px;
    margin-top: 5px;
  }

  .card-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .avatar-box {
    margin: 0 !important;
  }

  .three-dot {
    padding: 0 !important;
    margin: 0;
  }

  .action-heading h3 {
    text-align: left;
  }

  .avatar-box {
    padding: 15px;
  }

  .course-name {
    font-size: 13px;
  }

  .ant-avatar.ant-avatar-circle.ant-avatar-icon {
    width: 36px !important;
    height: 36px !important;
    line-height: 36px !important;
    font-size: 24px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .roles-details button {
    right: 0;
  }
  .erp-button-group .ant-btn {
    margin-top: 7px !important;
  }

  .add-remove .ant-col:last-child {
    padding-left: 0 !important;
  }

  .radio-group {
    padding: 0 !important;
  }

  .sub-department
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 3)
    .ant-form-item {
    margin-bottom: 0;
  }

  .gx-search-bar .gx-form-group {
    width: 70%;
  }

  .ant-layout-sider {
    width: 190px !important;
  }

  .ant-layout-sider-collapsed {
    width: 60px !important;
  }

  .ant-layout-sider-collapsed .gx-layout-sider-header {
    padding-left: 10px;
    padding-right: 10px;
  }

  .ant-table {
    font-size: 12px;
  }

  .user .ant-form .ant-row .ant-col:nth-last-child(-n + 3) .ant-form-item {
    margin-bottom: 0px;
  }

  .gx-sidebar-notifications {
    padding: 15px 10px;
  }
  .ant-layout-sider-collapsed .icon.icon-chevron-down {
    display: none;
  }

  .ant-menu-light .ant-menu-item-active,
  .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-light .ant-menu-submenu-active {
    color: #ea5455;
  }
  .remark-admission .ant-drawer-body .drawer-body-scroll .ant-table-content {
    max-height: calc(100vh - 474px);
    min-height: calc(100vh - 474px);
  }

  // .sub-categories .ant-form .ant-row .ant-col .ant-form-item,
  // .department .ant-form .ant-row .ant-col .ant-form-item,
  // .branch .ant-form .ant-row .ant-col:nth-last-child(-n + 4) .ant-form-item {
  //   margin-bottom: 15px;
  // }

  .department .ant-form .ant-row .ant-col:nth-last-child(-n + 2) .ant-form-item,
  .branch .ant-form .ant-row .ant-col:last-child .ant-form-item {
    margin-bottom: 0;
  }

  .ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
  .ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
    padding-left: 40px !important;
  }

  .gx-avatar-name,
  .ant-layout-sider .ant-menu .ant-menu-submenu-title span,
  .ant-drawer .ant-menu .ant-menu-submenu-title span {
    white-space: nowrap;
  }

  .gx-sidebar-notifications .gx-flex-row {
    flex-wrap: nowrap !important;
  }

  // .erp-filter-btn .ant-btn,
  // .erp-button-group .ant-btn {
  //     height: auto;
  //     padding: 5px;
  //     line-height: 1;
  //     font-size: 12px;
  // }
  .erp-button-group .ant-badge-count {
    height: 16px;
    padding: 0 4px;
    font-size: 12px;
    line-height: 16px;
  }

  .erp-filter-btn {
    padding-left: 15px;
    margin-top: 5px;
  }

  /* .parent-details-input .ant-col:nth-child(4n+1), .add-addmission .ant-card-body .ant-col:nth-child(4n+1) {
        padding-right: 12px !important;
    }
    .parent-details-input .ant-col:nth-child(3n+1), .add-addmission .ant-card-body .ant-col:nth-child(3n) {
        padding-right: 0 !important;
    }*/
  .ant-layout-sider .ant-menu-vertical .ant-menu-item,
  .ant-layout-sider .ant-menu-vertical-left .ant-menu-item,
  .ant-layout-sider .ant-menu-vertical-right .ant-menu-item,
  .ant-layout-sider .ant-menu-inline .ant-menu-item,
  .ant-layout-sider .ant-menu-vertical .ant-menu-submenu-title,
  .ant-layout-sider .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-layout-sider .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-layout-sider .ant-menu-inline .ant-menu-submenu-title,
  .ant-drawer .ant-menu-vertical .ant-menu-item,
  .ant-drawer .ant-menu-vertical-left .ant-menu-item,
  .ant-drawer .ant-menu-vertical-right .ant-menu-item,
  .ant-drawer .ant-menu-inline .ant-menu-item,
  .ant-drawer .ant-menu-vertical .ant-menu-submenu-title,
  .ant-drawer .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-drawer .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-drawer .ant-menu-inline .ant-menu-submenu-title {
    padding-left: 30px !important;
  }

  .ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item,
  .ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item,
  .ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item,
  .ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item,
  .ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title,
  .ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title {
    padding: 0 calc((80px - 39px) / 2) !important;
  }

  .gx-app-sidebar
    .ant-menu-submenu-vertical
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow,
  .gx-app-sidebar
    .ant-menu-submenu-vertical-left
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow,
  .gx-app-sidebar
    .ant-menu-submenu-vertical-right
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow,
  .gx-app-sidebar
    .ant-menu-submenu-inline
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow {
    left: 17px;
  }

  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    font-size: 13px;
  }

  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    margin-right: 5px;
  }

  .gx-double-right-icon-wrapper {
    height: 30px;
    width: 30px;
  }

  .anticon svg {
    width: 14px;
  }

  .ant-radio-wrapper {
    margin-right: 0px;
  }

  .ant-form label {
    font-size: 13px;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }

  .anticon.anticon-minus-circle svg {
    width: auto;
  }

  .sub-course .ant-form .ant-row .ant-col:nth-last-child(-n + 4) .ant-form-item,
  .batch .ant-form .ant-row .ant-col:nth-last-child(-n + 4) .ant-form-item,
  .sub-department
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 3)
    .ant-form-item,
  .department
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 3)
    .ant-form-item {
    margin-bottom: 0;
  }

  .course-name {
    font-size: 16px;
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 10px;
  }

  .user-profile .gx-size-40 {
    border: 1.5px solid #ea5455;
    padding: 2px;
    padding-top: 1px;
    padding-right: 1px;
    left: 0 !important;
  }

  .ant-menu-item:hover a {
    color: #ea5455;
  }

  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: #fce9e9;
  }

  .user-profile > span {
    width: 100%;
  }

  .ant-card-body .ant-btn {
    padding: 0 9px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .roles-details button {
    right: 0;
  }
  .ant-form label.ant-radio-wrapper {
    font-size: 13px;
    margin-right: 0px;
  }

  .add-remove .ant-col:last-child {
    padding-left: 0 !important;
  }

  .ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
  .ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
    padding-left: 50px !important;
  }

  .ant-layout-sider {
    width: 210px !important;
  }

  .ant-layout-sider-collapsed {
    width: 60px !important;
  }

  .ant-table {
    font-size: 13px;
  }

  .ant-layout-sider-collapsed .gx-layout-sider-header {
    padding-left: 10px;
    padding-right: 10px;
  }

  .ant-layout-sider-collapsed .gx-sidebar-notifications {
    padding: 15px 10px;
  }

  .ant-layout-sider-collapsed .gx-avatar-row .ant-avatar {
    left: 0;
  }

  .ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item,
  .ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item,
  .ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item,
  .ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item,
  .ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title,
  .ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title {
    padding: 0 calc((40px - 20px) / 2) !important;
  }

  .ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item .icon,
  .ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item .icon,
  .ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item .icon,
  .ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item .icon,
  .ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title .icon,
  .ant-layout-sider-collapsed
    .ant-menu-vertical-left
    .ant-menu-submenu-title
    .icon,
  .ant-layout-sider-collapsed
    .ant-menu-vertical-right
    .ant-menu-submenu-title
    .icon,
  .ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title .icon {
    margin: 0;
  }

  // .ant-layout-sider.ant-layout-sider-collapsed
  //   .ant-menu
  //   .ant-menu-submenu-title
  //   span,
  // .ant-drawer .ant-menu .ant-menu-submenu-title span {
  //   justify-content: center;
  // }

  .user-profile .gx-avatar-name {
    display: inline-block;
    white-space: nowrap;
    max-width: 105px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  .gx-size-40 {
    border: 1.5px solid #ea5455;
    padding: 1px 1px 2px 2px !important;
  }
}

.add-remove .ant-col:last-child {
  padding-left: 0 !important;
}

.quill .ql-editor {
  min-height: 8em;
}

.ql-toolbar.ql-snow {
  padding: 15px;
}

.ant-drawer {
  overflow-y: scroll;
  overflow-x: hidden;
}

#subtopiceditor label.label.as-placeholder {
  top: 85px !important;
}

// zenil style start
.overdue-amount-title {
  margin-bottom: 25px;
  font-size: 17px;
  color: #262626;
}

.success {
  background: green;
}
.completed {
  background: #badbcc !important;
}
.ongoing {
  background: #b6effb;
}

// .pending {
//     background: #FFD580;
// }

.upcoming {
  background: #e2e3e5;
}

.cancelled {
  background: #f5c2c7;
}

.on_hold {
  background: #ffecb5;
}

// zenil style end

@media print {
  * {
    overflow: visible !important;
  }
  .no-print {
    display: none;
  }

  .print-btn {
    display: none;
  }
}

.payment-receipt thead,
.payment-receipt th,
.payment-receipt tbody,
.payment-receipt tr,
.payment-receipt td {
  display: block;
}

.print-receipt {
  background: #fff;
}
.receipt-header {
  flex: 1 1 50%;
}
.receipt-header:last-child {
  flex: 1 1 20%;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder,
.ant-select-multiple.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector::after,
.ant-select-multiple.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-multiple.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 42px;
  font-size: 15px;
  text-align: left;
}

.ant-select.ant-select-in-form-item {
  text-align: left;
}

.line-through-add {
  text-decoration: line-through;
}
.ant-select-multiple .ant-select-selection-item-content {
  font-size: 11px;
}
// ADD-ADMISSION-CSS
// .erp-filter-btn .ant-btn.ant-btn-default, .erp-button-group .ant-btn.ant-btn-default {
//     color: #fff !important;;
//     border-color:initial!important;
// }

// .erp-button-group .ant-btn.ant-btn-primary{
//     border:1px solid #ea5455!important;
// }
// ADD-ADMISSION-CSS

// VIEW-ADMISSION-CSS
// .payment-details .ant-btn.ant-btn-default {
//   color: #fff !important;
//   border-color: initial !important;
// }
.ant-drawer.ant-drawer-right.crm-history.ant-drawer-open.ant-drawer-right
  .ant-drawer-content-wrapper,
.ant-drawer.ant-drawer-right.payment-details.ant-drawer-open.ant-drawer-right
  .ant-drawer-content-wrapper {
  width: 70% !important;
}
// .ant-drawer.ant-drawer-right.course-info.ant-drawer-open.ant-drawer-right
//   .ant-drawer-content-wrapper {
//   width: 40% !important;
// }

.course-info .ant-table-wrapper,
.payment-details .ant-table-wrapper {
  box-shadow: 0 0 2.1875rem rgb(90 97 105 / 7%),
    0 0 1.40625rem rgb(90 97 105 / 7%), 0 0 0.53125rem rgb(90 97 105 / 9%),
    0 0 0.1875rem rgb(90 97 105 / 7%);
  border-radius: 10px;
}

.course-info .ant-drawer-header,
.course-info .ant-drawer-body,
.payment-details .ant-drawer-body,
.payment-details .ant-drawer-header {
  padding: 16px 8px;
}

.course-info .ant-btn.ant-btn-default {
  color: #fff !important;
  border: none;
}

.course-info .ant-btn.ant-btn-default:hover {
  color: #fff !important;
  background: #54ca68 !important;
}

.payment-details .custom-label-com {
  padding: 10px 0 5px;
}

.payment-details .ant-btn {
  height: 30px;
  line-height: 30px;
}

.payment-details a {
  color: #ea5455;
}

.edit-details.edit-icon-wrapper {
  bottom: 13px;
  top: unset;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ea5455;
}
.edit-details.edit-icon-wrapper {
  height: 43px;
  width: 90%;
  border-radius: 5px;
  color: #fff;
  border: none;
  max-width: 350px;
}
.ant-drawer.ant-drawer-right.postal-modal.ant-drawer-open.ant-drawer-right
  .ant-drawer-content-wrapper {
  width: 45% !important;
}
.erp-filter-btn .ant-btn.ant-btn-default,
.erp-button-group .ant-btn.ant-btn-primary,
.erp-button-group .ant-btn.ant-btn-default {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  top: 0;
  transition: all 0.2s ease-in-out;
}
.erp-filter-btn .ant-btn.ant-btn-default {
  border: none;
}
.erp-filter-btn .ant-btn.ant-btn-default:hover,
.erp-button-group .ant-btn.ant-btn-primary:hover,
.erp-button-group .ant-btn.ant-btn-default:hover {
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  top: 2px;
  background-color: #fff !important;
  color: inherit !important;
}

@media (max-width: 389px) {
  .erp-filter-btn.erp-filter-button {
    margin-top: 10px;
  }
}

.erp-button-group .bg-blue {
  background-color: #fff !important;
  color: #3abaf4 !important;
}

.erp-button-group .bg-blue.active {
  border: 1px solid #3ab9f4be !important;
}

.erp-button-group .bg-darkBlue.active {
  border: 1px solid #5864bd87 !important;
}

.erp-button-group .bg-warning.active {
  border: 1px solid #ffa426 !important;
}

.erp-button-group .bg-purple.active {
  border: 1px solid #9852a5 !important;
}

.erp-button-group .bg-danger.active {
  border: 1px solid #dd0d0d !important;
}

.erp-button-group .bg-success.active {
  border: 1px solid #44a789 !important;
}

.erp-button-group .bg-orange.active {
  border: 1px solid #fc544b !important;
}

.erp-button-group .bg-green.active {
  border: 1px solid #54ca68 !important;
}

.erp-button-group .bg-warning {
  background-color: #fff !important;
  color: #ffa426 !important;
}

.erp-button-group .bg-purple {
  background-color: #fff !important;
  color: #9852a5 !important;
}

.erp-button-group .bg-danger {
  background-color: #fff !important;
  color: #dd0d0d !important;
}

.erp-button-group .bg-success {
  background-color: #fff !important;
  color: #44a789 !important;
}

.erp-button-group .ant-btn {
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.3px;
}

.erp-button-group .bg-orange {
  background-color: #fff !important;
  color: #fc544b !important;
}

.erp-button-group .bg-green {
  background-color: #fff !important;
  color: #54ca68 !important;
}

.erp-filter-btn .bg-darkBlue,
.erp-button-group .bg-darkBlue {
  background-color: #fff !important;
  color: #5864bd !important;
}

.erp-button-group .ant-badge-count {
  max-width: 36px;
  min-width: 36px;
}

.admission-card .username {
  font-size: 16px;
}

.admission-card .mobile-number,
.admission-card .grID {
  font-size: 13px;
  font-weight: 500;
}

.top-details {
  background: #f9f9f9;
  width: 100%;
  padding: 10px 0;
  margin: 0;
  border-radius: 5px;
}

.enrollment,
.email {
  font-weight: 500;
  color: #545454 !important;
}

.admission-card .ant-typography b {
  font-weight: 600;
}

.payment-status {
  color: #fff;
  padding: 4px 8px 3px;
  text-transform: lowercase !important;
  border-radius: 5px;
  font-size: 12px;
}

.payment-status::first-letter {
  text-transform: uppercase;
}

// .ant-empty.ant-empty-normal{
//     height: 50vh;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }
.view-addmission .ant-table-thead {
  display: none;
}

.view-addmission .table-bg {
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
}

.ant-badge {
  margin: 0;
  margin-left: 5px;
}

.card-bottom {
  width: 100%;
}

.admission-card .ant-avatar {
  margin-right: 10px;
}

.course-info .ant-table-thead th .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #fff !important;
}

.course-info hr,
.basic-info hr {
  margin: 10px 0;
}

.course-info .ant-drawer-body
// .basic-info .ant-drawer-body
{
  padding: 10px 0;
}

.popup i,
.popup span {
  color: #ea5455;
  width: 20px;
  text-align: center;
  font-size: 17px;
}

.popup p {
  color: #545454;
}

.status-btns {
  background: #fff;
  backdrop-filter: blur(10px);
  width: 250px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

.overlay {
  opacity: 0.5;
  background: #000;
  backdrop-filter: blur(10px);
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.status-heading {
  padding: 25px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.absent-data-image .ant-image-mask-info .anticon {
  margin: 5px 5px 5px 0;
}
.show-studentList .ant-table-content {
  max-height: calc(100vh - 250px);
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ea5455;
}
.completeStudentUpdateBtn {
  height: 27px;
}
.ant-picker-range .ant-picker-active-bar {
  background: #ea5455;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #ea5455;
  background: #fff3f3;
  border-color: #ffc6c6;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #ffecec;
}

.ant-modal-title {
  text-align: center;
}

.admission-filter .ant-modal-body {
  padding: 10px;
}

.admission-filter .ant-picker.ant-picker-range.ant-picker-large {
  width: 100%;
}

.ant-tabs-tab:hover {
  color: #ea5455;
}

.remark-admission .ant-select-selector {
  height: 43px !important;
  box-shadow: none !important;
  background-color: #fffbfb99 !important;
  border-color: #ffcdcd !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 44px;
  display: flex;
  align-items: center;
}
.remark-table .ant-select-selector {
  height: 37px !important;
}

.payment-details .ant-btn.ant-btn-default.unpaid-amount:hover,
.payment-details .ant-btn.ant-btn-default.unpaid-amount {
  background: #fff !important;
  color: #ea5455 !important;
  border: none !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.payment-details .ant-btn.ant-btn-default.unpaid-amount.disabled {
  cursor: no-drop;
}

.pay-text {
  background-color: #ea5455;
  line-height: 40px;
  height: 40px;
  width: 35px;
  padding-left: 7px;
  color: #fff !important;
  display: inline-block;
  font-size: 8px;
  text-align: left;
  border-radius: 50%;
  position: absolute !important;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.payment-details .ant-btn.ant-btn-default.paid-amount:hover,
.payment-details .ant-btn.ant-btn-default.paid-amount {
  background: #fff !important;
  color: #54ca68 !important;
  border: none !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  font-size: 13px;
  min-width: 120px;
  text-align: center;
  position: relative;
}

.ant-table-content {
  overflow-x: auto;
}

.ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right.remark-admission
  .ant-drawer-content-wrapper {
  width: 50% !important;
}

.view-addmission .ant-table-content {
  overflow: unset;
}
.card-bottom h3 {
  line-height: 0;
}
.admission-card a.ant-typography-ellipsis,
.admission-card span.ant-typography-ellipsis {
  max-width: 97%;
}
.admission-card span.ant-typography-ellipsis:has(> .missingCrmCourse) {
  display: block;
}

.admission-card span.ant-typography-ellipsis span.ant-typography-ellipsis {
  width: 100% !important;
  max-width: 100% !important;
}
.admission-card span.ant-typography-ellipsis.username {
  max-width: 180px;
}
.installment-payment .ant-modal-body {
  padding: 15px 15px 0;
}
.sendSms {
  margin: 15px 0 !important;
}
.sendSms .ant-form-item {
  margin-bottom: 5px;
}

@media (max-width: 1199px) {
  .erp-button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .erp-button-group .ant-btn {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px !important;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .course-info .ant-drawer-body .drawer-body-scroll .ant-table-content {
    max-height: calc(100vh - 390px);
    min-height: calc(100vh - 390px);
  }
  .table-striped .ant-table-content {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .ant-drawer.ant-drawer-right.crm-history.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper,
  .ant-drawer.ant-drawer-right.course-info.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper,
  .ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right.remark-admission
    .ant-drawer-content-wrapper,
  .ant-drawer.ant-drawer-right.payment-details.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 100% !important;
  }

  .card-bottom h3,
  .action-icon > div,
  .action-icon-disable > div {
    margin: 0px 5px;
  }

  .view-addmission .table-bg {
    padding-top: 0px;
  }

  .card-bottom {
    flex-direction: row;
  }

  .card-bottom h3,
  .action-icon > div,
  .action-icon-disable > div {
    margin: 0px 5px;
  }
  .admission-card a.ant-typography-ellipsis,
  .admission-card span.ant-typography-ellipsis {
    max-width: 100%;
    white-space: pre-wrap;
  }
  .course-info-btn .ant-btn {
    width: 100%;
    margin: 0 0 5px 0 !important;
  }
  .btn-apply-filter {
    width: auto !important;
    // margin-left: 0 !important;
    // margin-right: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  // .ant-drawer.ant-drawer-right.course-info.ant-drawer-open.ant-drawer-right
  //   .ant-drawer-content-wrapper,
  .ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right.remark-admission
    .ant-drawer-content-wrapper {
    width: 90% !important;
  }

  .table-striped .ant-table-content {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .avatar-box {
    padding: 17px 10px !important;
  }
  .avatar-box .card-header > div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .avatar-box .card-header {
    width: 100%;
  }
  .action-icon > div,
  .action-icon-disable > div {
    margin: 2.5px auto;
  }
  .admission-card {
    margin: 10px;
    padding: 10px;
  }

  .admission-card .username {
    font-size: 15px;
  }

  .admission-card .mobile-number,
  .admission-card .grID {
    font-size: 10px;
  }
  .ant-drawer.ant-drawer-right.crm-history.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper,
  .ant-drawer.ant-drawer-right.payment-details.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 95% !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .erp-button-group .ant-badge-count {
    max-width: 25px;
    min-width: 25px;
  }

  // .ant-drawer.ant-drawer-right.course-info.ant-drawer-open.ant-drawer-right
  //   .ant-drawer-content-wrapper {
  //   width: 75% !important;
  // }
  .admission-card {
    margin: 10px 10px;
    padding: 10px;
  }

  .avatar-box {
    padding: 10px !important;
  }

  .avatar-box .card-header > div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .avatar-box .card-header {
    width: 100%;
  }

  .avatar-box .card-header .gx-text-left {
    text-align: center !important;
  }

  .admission-card .ant-avatar {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .admission-card .username {
    font-size: 12px;
  }
  .admission-card span.ant-typography-ellipsis.username {
    max-width: 110px;
  }

  .admission-card .mobile-number,
  .admission-card .grID {
    font-size: 11px;
  }
  .ant-drawer.ant-drawer-right.crm-history.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper,
  .ant-drawer.ant-drawer-right.payment-details.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 90% !important;
  }
}

@media (min-width: 1400px) and (max-width: 1409px) {
  .erp-button-group .ant-badge-count {
    font-size: 11px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .admission-card .ant-avatar {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .GR-id-filter .as-placeholder {
    top: 9px;
  }

  .ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right.remark-admission
    .ant-drawer-content-wrapper {
    width: 70% !important;
  }
  .erp-filter-btn .ant-input-number {
    height: auto;
  }

  .erp-filter-btn .ant-btn,
  .erp-button-group .ant-btn {
    padding: 0 8px;
    font-size: 12px;
  }

  .erp-button-group .ant-badge-count {
    font-size: 9px;
  }

  .admission-card {
    margin: 10px 11px;
    padding: 10px;
  }

  .avatar-box {
    padding: 10px !important;
  }

  .avatar-box .card-header > div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .avatar-box .card-header {
    width: 100%;
  }

  .avatar-box .card-header .gx-text-left {
    text-align: center !important;
  }

  .admission-card .ant-row.gx-align-items-start {
    align-items: center !important;
  }

  .admission-card .ant-avatar {
    margin-right: 0px;
  }
  .ant-drawer.ant-drawer-right.crm-history.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper,
  .ant-drawer.ant-drawer-right.payment-details.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 80% !important;
  }
  .admission-card a.ant-typography-ellipsis,
  .admission-card span.ant-typography-ellipsis {
    display: inline-block;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .roles-details button {
    right: 0;
  }

  .erp-filter-btn .ant-btn,
  .erp-button-group .ant-btn {
    padding: 0 10px;
    font-size: 14px;
  }

  .admission-card {
    margin: 10px 11px;
    padding: 10px;
  }

  .avatar-box {
    padding: 10px !important;
  }

  .ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right.remark-admission
    .ant-drawer-content-wrapper {
    width: 60% !important;
  }

  .avatar-box .card-header > div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .avatar-box .card-header {
    width: 100%;
  }

  .avatar-box .card-header .gx-text-left {
    text-align: center !important;
  }

  .admission-card .ant-row.gx-align-items-start {
    align-items: center !important;
  }

  .admission-card {
    margin: 10px;
  }

  .admission-card .ant-avatar {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .gx-avatar-name {
    white-space: nowrap;
    max-width: 120px;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    text-overflow: ellipsis;
  }
  .admission-card a.ant-typography-ellipsis,
  .admission-card span.ant-typography-ellipsis {
    display: inline-block;
  }
}
@media (min-width: 1600px) and (max-width: 1699px) {
  .roles-details button {
    right: 0;
  }
  .roles-details p {
    max-width: 110px;
  }
  .user-role-details p {
    max-width: initial;
  }
  .avatar-box {
    padding: 27px 10px !important;
  }
}
// VIEW-ADMISSION-CSS

// OVERDUE-AMOUNT-CSS
.ant-picker {
  height: 43px;
  box-shadow: none !important;
  background-color: #fffbfb99;
  border-color: #ffcdcd !important;
}

.ant-picker-input > input::placeholder {
  color: #2a2a2a;
  font-weight: 300;
  font-size: 14px;
}

.ant-picker-input > input {
  line-height: 1;
}

// OVERDUE-AMOUNT-CSS
.gx-avatar-name {
  white-space: nowrap;
  max-width: 120px;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
}

#root {
  overflow-y: auto;
  overflow-x: hidden;
}

.payment-receipt {
  padding-bottom: 15px;
}

// USER-BATCH-CSS
.userbatch .rnw-page-title {
  margin-bottom: 20px;
}

// USER-BATCH-CSS
.upload-images .img-thumbnail {
  max-height: 110px;
  object-fit: cover;
  margin-top: 10px;
}
.upload-images .no-uploaded-btn .img-thumbnail {
  height: 85px;
  object-fit: cover;
}
.upload-image-box {
  height: 110px;
  overflow: hidden;
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 0px;
  min-width: 18px;
  font-size: 18px;
}

.menu-name {
  line-height: 1;
}
.ant-menu-title-content span.anticon {
  color: #ea5455;
}
.permission-info {
  border: none;
}
.ant-collapse-item {
  background: #fff;
  border-bottom: none !important;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0;
}
.ant-collapse-content {
  border-top: 1px solid #ffebec;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #ea5455;
  font-size: 16px;
}
.permission-content .ant-row {
  display: flex;
  align-items: center;
}
.permission-content .ant-form-item-label > label {
  height: unset;
  color: #363636;
  font-size: 15px;
}

.permission-info .ant-form-item-label {
  min-width: 350px;
  text-align: left;
}
.permission-info .ant-collapse-content > .ant-collapse-content-box {
  padding-left: 32px;
}
.permission-info .ant-checkbox-wrapper {
  min-width: 100px;
}
.permission-info .ant-checkbox-inner::after {
  top: 48%;
}
.ant-checkbox-inner {
  border-radius: 4px;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ea5455;
}

.permission-info .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
}
.permission-info .ant-checkbox-wrapper {
  color: #7a7a7a;
}
.all-none .ant-checkbox-wrapper {
  min-width: 30px;
}
.all-none .ant-checkbox-wrapper {
  color: #313131;
}

.payment-details .ant-drawer-body {
  padding-bottom: 0;
}

@media (max-width: 575px) {
  .rnw-page-title {
    margin-bottom: 10px !important;
  }
  .more-btn {
    height: 25px;
    line-height: 26px;
  }
  .remark-admission .ant-drawer-body .drawer-body-scroll .ant-table-content {
    max-height: calc(100vh - 504px);
    min-height: calc(100vh - 504px);
  }
}
.course-modification .ant-modal-body {
  padding-bottom: 0;
}
@media (max-width: 575px) {
  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    height: calc(100vh - 107px);
  }
  .gx-double-right-icon {
    transform: rotate(90deg);
  }
}
.upload-images {
  background: #fffbfbab;
  z-index: 999;
  height: 160px;
  width: 160px;
  margin: 0 6px 15px;
  border-radius: 10px;
  border: 1px solid #ea5455;
  padding: 10px;
  position: relative;
}
.view-admission-documents {
  position: absolute;
  top: -18%;
  right: -3px;
}
.view-admission-documents .ant-btn.ant-btn-default {
  border: none;
  background: transparent !important;
  box-shadow: none;
  color: #ea5455 !important;
  height: unset;
  z-index: 9999;
  padding: 0;
}
.view-admission-documents
  .ant-btn.ant-btn-default:hover
  .anticon.anticon-upload {
  color: #ea5455 !important;
}
.view-admission-documents .upload-btn {
  line-height: 1;
}
.view-btn {
  position: absolute;
  bottom: 28%;
  display: inline-block;
  right: 32%;
  background: #ea5455;
  height: 25px;
  width: 25px;
  z-index: 9999;
  border-radius: 50%;
  color: #fff !important;
  text-align: center;
  line-height: 25px;
  transform: translate(-50%, -50%);
}

.view-uploaded-img ~ .view-btn,
.custom-label-com ~ .view-btn {
  bottom: 7px;
  right: 7px;
}
.ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right.course-info
  .ant-drawer-content-wrapper {
  overflow: hidden;
  width: 55% !important;
}

.no-found-page {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 999;
  left: 0;
  right: 0;
  display: flex;
  bottom: 0;
  align-items: center;
  justify-content: center;
}
.no-found-text {
  height: 700px;
  width: 700px;
}
.no-found-text img {
  max-width: 100%;
}
.no-found-text h2 {
  font-size: 40px;
  margin-top: -50px;
  font-weight: 500;
  color: #ea5455;
}
.goto-return-btn {
  padding: 8px 15px;
  border-radius: 5px;
  color: #ea5455;
  font-size: 16px;
  margin-top: 15px;
  font-weight: 500;
  background: transparent;
  border: 1px solid #ea5455;
  transition: all 0.5s;
  display: inline-block;
}

.goto-return-btn:hover {
  background: #ea5455;
  color: #fff;
}
.single-branch {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: #fff;
  z-index: 9999;
  align-items: center;
  justify-content: center;
}

.single-branch-list {
  width: 25%;
  background: #fff;
  padding: 15px;
  box-shadow: 0 0 2.1875rem rgb(90 97 105 / 7%),
    0 0 1.40625rem rgb(90 97 105 / 7%), 0 0 0.53125rem rgb(90 97 105 / 9%),
    0 0 0.1875rem rgb(90 97 105 / 7%);
  border-radius: 10px;
}
.single-branch-list h2 {
  text-align: center;
  color: #262626;
  padding: 15px 0 20px;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0;
}
.single-branch-list > div {
  max-height: 60vh;
  overflow: auto;
}
.custom-branch-selection .ant-radio {
  display: none;
  opacity: 0;
}
.custom-branch-selection {
  position: relative;
  width: 100%;
  padding: 20px;
  letter-spacing: 0.5px;
  margin: 0;
  font-size: 15px;
  margin: 5px 0;
  font-weight: 500;
}
.custom-branch-selection:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid #efefef;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: border-color 400ms ease;
}
.ant-radio-wrapper-checked.custom-branch-selection:before {
  border-color: #ea5455;
}
.ant-radio-wrapper-checked.custom-branch-selection {
  color: #ea5455;
  box-shadow: 0 0 10px 0 rgb(234 84 85 / 10%);
}
.custom-branch-selection span:not(.anticon, .ant-radio) {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  z-index: 999;
}
.custom-branch-selection span.anticon {
  display: none;
}
.ant-radio-wrapper-checked.custom-branch-selection span.anticon {
  display: inline-block;
  font-size: 20px;
}
.custom-branch-selection:hover:before {
  border-color: #ea5455;
}
.custom-branch-selection:hover {
  color: #ea5455;
  box-shadow: 0 0 10px 0 rgb(234 84 85 / 10%);
}
.single-branch-list .ant-radio-group {
  max-height: 75vh;
  overflow: auto;
}
.no-permission {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-permission-box {
  height: 300px;
  width: 500px;
  border-radius: 10px;
  padding: 30px 20px;
  text-align: center;
}
.no-permission-box h3 {
  color: #ea5455;
  font-size: 26px;
  font-weight: 400;
  margin-top: 15px;
}
.no-permission-box h5 {
  color: #2a2a2a;
  font-size: 18px;
  font-weight: 400;
}
.no-permission-box .no-permission-btn {
  padding: 8px 30px;
  border: none;
  border-radius: 5px;
  background: #ea5455;
  color: #fff;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
}
.gx-user-popover a {
  color: #ea5455;
}
.ant-form-item-label > label {
  height: auto;
}

.has-no-data li:has(> span:empty) {
  display: none;
}
.ant-popover-inner-content:empty {
  padding: 0;
}
.action-icon div:has(> span:empty),
.action-icon-disable div:has(> span:empty) {
  display: none;
}
.remark-table .ant-table-body {
  max-height: calc(100vh - 410px) !important;
}
.modal-md {
  max-width: 700px;
  width: 700px;
}

.modal-xl {
  max-width: 70vw;
  width: 70vw !important;
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #ea5455;
  border-color: #ea5455;
  background: #fff;
}
.postal-address-text {
  margin: 0;
}
.postal-address-text span {
  display: inline-block;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
}
.user-profile-name {
  display: flex;
  border-bottom: solid 1px #ffedee;
  background: #fff5f5 !important;
}
.user-profile-name span {
  margin-right: 10px;
}
@media (max-width: 767px) {
  .ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right.course-info
    .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .pagination {
    text-align: center;
  }
  .gx-double-right-icon {
    transform: rotate(90deg);
  }
  .ant-drawer.ant-drawer-right.postal-modal.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    overflow: hidden;
    width: 100% !important;
  }
  .mobile-datepicker .ant-picker {
    width: 135px !important;
  }

  .postal-modal .ant-drawer-body {
    max-height: calc(100vh - 112px);
  }
  .upload-images .ant-typography {
    font-size: 14px;
  }
  .ant-modal {
    max-width: 95% !important;
    width: 95% !important;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .branch-popover {
    max-height: 100px;
    overflow: auto;
  }

  .ant-modal-body {
    padding: 15px;
  }
  .ant-modal-header {
    padding: 10px 15px;
  }
  .total-attendance h3 {
    font-size: 12px;
  }
  .ant-drawer-header .ant-drawer-title {
    font-size: 16px;
  }
  .edit-icon-wrapper {
    height: 25px;
    width: 25px;
  }
}
.ant-drawer {
  right: -50%;
}
.ant-drawer-open {
  z-index: 999;
  right: -4px;
}
.course-modification .ant-modal-body {
  max-height: 87vh;
  overflow: auto;
}
@media (min-width: 768px) {
  .pagination {
    text-align: right;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right.course-info
    .ant-drawer-content-wrapper {
    width: 70% !important;
  }
  .ant-drawer.ant-drawer-right.postal-modal.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 95% !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right.course-info
    .ant-drawer-content-wrapper {
    width: 60% !important;
  }
  .ant-drawer.ant-drawer-right.postal-modal.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 70% !important;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .ant-drawer.ant-drawer-right.postal-modal.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 60% !important;
  }
}

.branch-popover {
  max-height: 100px;
  overflow: auto;
}
.user-profile .ant-popover {
  width: 90% !important;
  left: 5% !important;
  transform-origin: unset !important;
  z-index: 99999;
  padding-top: 0 !important;
}
.user-profile.gx-user-popover {
  margin: 0px !important;
  padding: 0px 0 !important;
}
.user-profile .gx-user-popover li {
  padding: 15px 20px;
  width: 100%;
}
.more-options .ant-popover {
  width: 250px !important;
  left: unset !important;
  right: 35px !important;
  max-width: unset;
  padding: 0;
}
.more-options .ant-popover .popup {
  margin: 0 !important;
  transition: all 0.5s;
  padding: 10px 15px;
}
.more-options .ant-popover .popup:hover {
  background: #fff3f3;
}

.ant-card {
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .user-profile .ant-popover {
    left: unset !important;
    right: 5px;
    width: 119px !important;
    margin-top: 3px;
    top: 59px !important;
  }
  .gx-popover-horizantal .ant-popover-inner-content {
    width: unset;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .user-profile .ant-popover {
    left: unset !important;
    right: 5px;
    width: 119px !important;
    margin-top: 3px;
    top: 59px !important;
    padding: 0;
  }
  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    height: calc(100vh - 107px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .ant-card {
    margin-bottom: 20px;
  }
  .user-profile .ant-popover {
    width: 95% !important;
    top: 69px !important;
    left: 3% !important;
  }
  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    height: calc(100vh - 177px);
  }
  .user-profile .gx-user-popover li {
    padding: 10px 20px;
  }
  .user-profile .gx-avatar-name {
    max-width: 100px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .user-profile .ant-popover {
    width: 90% !important;
    top: 56px !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .user-profile .ant-popover {
    width: 176px !important;
    top: 62px !important;
  }
  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    height: calc(100vh - 183px);
  }
  .user-profile .gx-user-popover li {
    padding: 10px 20px;
  }
  .user-profile .gx-avatar-name {
    max-width: 100px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .gx-sidebar-notifications {
    padding: 12px 10px;
  }
  .gx-user-popover li {
    padding: 12px 20px;
  }
}
.gx-user-popover {
  margin: 0;
}
.back-btn {
  color: #ea5455;
}
.back-btn .anticon {
  font-size: 20px;
}
.ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none;
}
.ant-table-thead tr th:nth-last-child(2):before {
  display: none;
}

.ant-layout-sider-collapsed .gx-layout-footer-content span.copy-text {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}
.gx-layout-footer-content {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  justify-content: center;
}
.ant-layout-sider-collapsed .gx-layout-footer-content {
  flex-direction: column;
}
.ant-layout-sider-collapsed .ant-layout-footer {
  padding: 8px 6px;
}

.ant-layout-sider-collapsed .user-profile .ant-popover {
  width: 190px !important;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  height: calc(100vh - 176px);
}
.ant-btn {
  line-height: 1;
}
.zone .ant-table-content {
  max-height: calc(100vh - 329px) !important;
}
.branches .ant-table-content {
  max-height: calc(100vh - 445px) !important;
}
.courses .ant-table-content {
  max-height: calc(100vh - 388px) !important;
}

.packages .ant-table-content,
.subCourse .ant-table-content {
  max-height: calc(100vh - 453px) !important;
}
.rnw-card.batches .ant-table-content {
  max-height: calc(100vh - 280px) !important;
  min-height: calc(100vh - 280px) !important;
}
.ant-table-content thead th {
  position: sticky;
  top: 0;
  z-index: 999;
}
.ant-table-tbody .ant-dropdown.ant-dropdown-placement-bottomRight {
  z-index: 99;
}
.ant-popover {
  z-index: 99;
}
.ant-table-content td table thead th {
  z-index: 99 !important;
}
.gx-app-nav li {
  padding: 0 20px 0 0;
}
.sub-department .ant-form-item,
.department .ant-form-item {
  margin-bottom: 0px;
}
.view-addmission .table-bg {
  max-height: calc(100vh - 240px);
  min-height: calc(100vh - 240px);
}
.nsdc-addmission .table-bg {
  max-height: calc(100vh - 200px);
  min-height: calc(100vh - 200px);
}
@media (max-width: 1800px) {
  .view-addmission .table-bg {
    max-height: calc(100vh - 275px);
    min-height: calc(100vh - 275px);
  }
}
@media (min-width: 1199px) and (max-width: 1799px) {
  .nsdc-addmission .table-bg {
    max-height: calc(100vh - 190px) !important;
    min-height: calc(100vh - 190px) !important;
  }
}
.view-addmission.missing-addmission .table-bg {
  max-height: calc(100vh - 200px);
  min-height: calc(100vh - 200px);
}
.transfer-addmission {
  margin-bottom: 0;
}
.transfer-addmission .ant-card-body {
  box-shadow: none;
  padding: 0;
}

.transfer-addmission .ant-table-content {
  max-height: calc(100vh - 344px);
}
.ant-drawer.ant-drawer-right.course-modification.ant-drawer-open.ant-drawer-right
  .ant-drawer-content-wrapper {
  overflow: hidden;
  width: 800px !important;
}
.course-modification .ant-table-wrapper {
  padding: 5px;
}
.course-modification .ant-table {
  box-shadow: 0 0 2.1875rem rgb(90 97 105 / 7%),
    0 0 1.40625rem rgb(90 97 105 / 7%), 0 0 0.53125rem rgb(90 97 105 / 9%),
    0 0 0.1875rem rgb(90 97 105 / 7%);
}
.view-details-postal > .ant-row {
  justify-content: center;
}
.view-details-postal .gx-double-right-icon-wrapper {
  transform: rotate(90deg);
}
@media (max-width: 575px) {
  .view-addmission {
    width: 100%;
  }
  .search-gr-id .ant-input-number-input {
    height: 43px;
  }

  .view-details-postal .gx-double-right-icon-wrapper {
    transform: rotate(0deg);
  }
  .btn-col {
    width: 100%;
  }
  .sub-department .ant-form-item,
  .department .ant-form-item {
    margin-bottom: 15px;
  }
  .zone .ant-table-content {
    max-height: calc(100vh - 524px) !important;
  }
  .ant-form label {
    font-size: 13px;
  }

  .ant-form#myFormAttendence label {
    font-size: 11px;
  }

  .ant-drawer.ant-drawer-right.course-modification.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    overflow: hidden;
    width: 100% !important;
  }
  .add-admission-upload .img-thumbnail {
    height: auto;
    max-height: 145px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .search-gr-id .ant-input-number-input {
    height: 43px;
  }
  .view-addmission .table-bg {
    max-height: calc(100vh - 200px);
    min-height: calc(100vh - 200px);
  }
  .view-addmission.missing-addmission .table-bg {
    max-height: calc(100vh - 195px);
    min-height: calc(100vh - 195px);
  }
  .view-details-postal .gx-double-right-icon-wrapper {
    transform: rotate(0deg);
  }
  .sub-department .ant-form-item,
  .department .ant-form-item {
    margin-bottom: 15px;
  }
  .sub-department
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 3)
    .ant-form-item,
  .department
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 3)
    .ant-form-item {
    margin-bottom: 0;
  }
  .zone .ant-table-content {
    max-height: calc(100vh - 383px) !important;
  }
  .gx-header-notifications > li:not(:last-child) {
    margin-right: 0px;
  }
  .ant-form label {
    font-size: 13px;
  }
  .course .ant-form .ant-row .ant-col:nth-last-child(-n + 4) .ant-form-item {
    margin-bottom: 0;
  }
  .courses .ant-table-content {
    max-height: calc(100vh - 448px) !important;
  }
  .ant-drawer.ant-drawer-right.course-modification.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    overflow: hidden;
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .search-gr-id .ant-input-number-input {
    height: 43px;
  }
  .ant-drawer.ant-drawer-right.admission-filter.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 50% !important;
  }
  .sub-department .ant-form-item,
  .department .ant-form-item {
    margin-bottom: 15px;
  }
  .sub-department
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 3)
    .ant-form-item,
  .department
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 3)
    .ant-form-item {
    margin-bottom: 0;
  }
  .zone .ant-table-content {
    max-height: calc(100vh - 387px) !important;
  }
  .gx-header-notifications > li:not(:last-child) {
    margin-right: 0px;
  }
  .course .ant-form .ant-row .ant-col:nth-last-child(-n + 4) .ant-form-item {
    margin-bottom: 0;
  }
  .view-addmission .table-bg {
    max-height: calc(100vh - 202px);
    min-height: calc(100vh - 202px);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .view-addmission .table-bg {
    max-height: calc(100vh - 195px);
    min-height: calc(100vh - 195px);
  }
  .view-addmission.missing-addmission .table-bg {
    max-height: calc(100vh - 190px);
    min-height: calc(100vh - 190px);
  }
  .search-gr-id .ant-input-number-input {
    height: 43px;
  }
  .erp-button-group {
    margin-top: 0;
  }
  .erp-button-group .ant-btn.ant-btn-default {
    width: 100%;
    margin: 5px 0;
  }
  .sub-department .ant-form-item,
  .department .ant-form-item {
    margin-bottom: 15px;
  }
  .sub-department
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 3)
    .ant-form-item,
  .department
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 3)
    .ant-form-item {
    margin-bottom: 0;
  }
  .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 25px !important;
  }

  .zone .ant-table-content {
    max-height: calc(100vh - 387px) !important;
  }
  .zones .ant-form .ant-row .ant-col .ant-form-item {
    margin-bottom: 15px;
  }

  .zones .ant-form .ant-row .ant-col:nth-last-child(2) .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-layout-footer {
    padding: 10px 12px;
  }
  .course .ant-form .ant-row .ant-col:nth-last-child(-n + 4) .ant-form-item {
    margin-bottom: 0;
  }
  .course .ant-form .ant-row .ant-col:nth-last-child(-n + 4) .ant-form-item {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .ant-layout-sider-collapsed .icon.icon-chevron-down {
    display: none;
  }

  .zones .ant-form .ant-row .ant-col .ant-form-item {
    margin-bottom: 15px;
  }
  .sub-department .ant-form-item,
  .department .ant-form-item {
    margin-bottom: 15px;
  }
  .sub-department
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 3)
    .ant-form-item,
  .department
    .ant-form
    .ant-row
    .ant-col:nth-last-child(-n + 3)
    .ant-form-item {
    margin-bottom: 0;
  }
  .zones .ant-form .ant-row .ant-col:nth-last-child(2) .ant-form-item {
    margin-bottom: 0px;
  }
  .zone .ant-table-content {
    max-height: calc(100vh - 387px) !important;
  }
  .course .ant-form .ant-row .ant-col:nth-last-child(-n + 4) .ant-form-item {
    margin-bottom: 0;
  }
  .admission-card span.ant-typography-ellipsis.username {
    max-width: 120px;
  }
  .admission-card .username {
    font-size: 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .admission-card span.ant-typography-ellipsis.username {
    max-width: 120px;
  }

  .missing-addmission .admission-card span.ant-typography-ellipsis.username {
    max-width: 80%;
  }
  .missing-addmission .admission-card .ant-btn.ant-btn-primary {
    padding: 0 5px;
    font-size: 12px;
  }
  .admission-card .username {
    font-size: 13px;
  }
  .avatar-box .card-header .gx-text-left {
    text-align: center !important;
  }
  .admission-card .ant-avatar {
    margin-right: 0px;
    margin-bottom: 10px;
  }
}
@media (min-width: 1300px) and (max-width: 1499px) {
  .admission-card span.ant-typography-ellipsis.username {
    max-width: 140px;
  }
  .admission-card .username {
    font-size: 15px;
  }
}
@media (min-width: 1500px) and (max-width: 1849px) {
  .admission-card span.ant-typography-ellipsis.username {
    max-width: 150px;
  }
  .admission-card .username {
    font-size: 15px;
  }
}
.msg {
  display: inline-block;
  position: relative;
  top: -11px;
  font-size: 13px;
  font-weight: 400;
  color: red;
}
.more-options .ant-popover-inner-content {
  padding: 10px 0;
}
.more-options .ant-popover-inner-content:empty {
  padding: 0;
}
.more-options .ant-popover-inner:has(> .ant-popover-inner-content:empty) {
  box-shadow: none;
}
.discount-price {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5;
}
.pay-amount {
  font-size: 16px;
}
.star-rating {
  display: none;
}
@media (max-width: 575px) {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
  .subTopic .ant-form .ant-row .ant-col:nth-last-child(-n + 2) .ant-form-item {
    margin-bottom: 15px;
  }
  .ant-rate-star {
    display: none;
  }
  .star-rating {
    display: inline-block;
  }
  // .ant-table-cell {
  //   text-align: center;
  // }
  .close-btn {
    right: 10px;
  }
  .add-admission-document .ant-card-body .upload-inputs .add-admission-upload {
    width: 50% !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .permission-info .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 12px;
  }
  .permission-info .ant-form-item-label {
    min-width: 200px;
  }
  .permission-content .ant-row {
    align-items: flex-start;
  }
  .permission-info .ant-checkbox-wrapper {
    min-width: 80px;
  }
  .all-none {
    display: flex;
  }
}
// @media (max-width: 360px) {
//   .create-subdepartment {
//     margin-top: 10px !important;
//   }
// }
@media (max-width: 280px) {
  .ant-btn.btn-cancel,
  .ant-btn[type="submit"] {
    width: 100% !important;
  }
  .branch-selected .ant-select.ant-select-single.ant-select-show-arrow {
    width: 120px !important;
  }
  .create-subdepartment {
    margin-top: 10px !important;
  }
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  border: none;
  background: transparent;
  border-bottom: 2px solid transparent;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: red;
}
.ant-layout-sider-collapsed .gx-sidebar-content {
  height: calc(100vh - 60px);
}
@media (max-width: 767px) {
  .permission-content .ant-row {
    flex-direction: column;
    align-items: unset;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title {
    padding: 0 !important;
  }
  .ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu,
  .ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item {
    padding: 0 21.5px !important;
  }
}
.ant-avatar.ant-avatar-circle.ant-avatar-image .ant-image-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.ant-avatar.ant-avatar-circle.ant-avatar-image .ant-image {
  height: 100%;
  width: 100%;
}
// .ant-image-mask-info:not(span){
//   display: none;
// }
.ant-drawer-footer {
  padding: 10px 8px;
  text-align: center;
}
.ant-drawer.ant-drawer-right.admission-filter.ant-drawer-open.ant-drawer-right
  .ant-drawer-content-wrapper {
  width: 25% !important;
}
.ant-drawer-mask {
  background: #1e1e1ee0;
}
.ant-form .ant-row > .ant-col.gx-pr-0,
.ant-form .ant-row > .ant-col.gx-px-0 {
  padding-right: 0 !important;
}
.ant-drawer-footer .ant-space {
  display: flex;
  width: 100%;
  justify-content: center;
}
.ant-drawer-footer .ant-space .ant-space-item {
  display: flex;
  width: 50%;
  max-width: 250px;
}
.ant-drawer-title {
  font-size: 18px;
}
.add-remove-input .ant-row .ant-form-item {
  margin-bottom: 0px !important ;
}

.rnw-card .ant-table-content,
.table-card .ant-table-content {
  max-height: calc(100vh - 225px) !important;
  min-height: calc(100vh - 225px) !important;
}

.rnw-card .ant-table-content,
.table-card .ant-table-content .table-scroll {
  max-height: calc(100vh - 230px) !important;
  min-height: calc(100vh - 230px) !important;
}

.rnw-card.view-student .ant-table-content {
  max-height: calc(100vh - 280px) !important;
  min-height: calc(100vh - 280px) !important;
}
.table-card.cheque-amount .ant-table-content {
  max-height: calc(100vh - 265px) !important;
  min-height: calc(100vh - 265px) !important;
}
.rnw-card.absent-present .ant-table-content {
  max-height: calc(100vh - 260px) !important;
  min-height: calc(100vh - 260px) !important;
}

.rnw-card.transfer-addmission .ant-table-content {
  max-height: calc(100vh - 280px) !important;
  min-height: calc(100vh - 280px) !important;
}

.rnw-card.scs-card .ant-table-content {
  max-height: calc(100vh - 280px) !important;
  min-height: calc(100vh - 280px) !important;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 99;
}
@media (max-width: 575px) {
  .csv-btn {
    position: relative;
  }
  .csv-btn:has(> span .ant-upload-list) {
    margin-bottom: 30px;
  }

  .csv-btn .ant-upload-list {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
  .ant-drawer.ant-drawer-right.admission-filter.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 100% !important;
    margin-right: 0px;
  }
  .ant-drawer-open {
    right: -4px;
    left: -1px;
  }
  .ant-drawer .ant-drawer-footer .ant-btn.btn-cancel,
  .ant-drawer .ant-drawer-footer .ant-btn.btn-submit {
    height: 38px;
    font-size: 14px;
  }
  .ant-pagination-item a {
    font-size: 12px;
  }
  .ant-pagination.ant-pagination-mini .ant-pagination-total-text,
  .ant-pagination.ant-pagination-mini .ant-pagination-simple-pager {
    font-size: 12px;
  }
  .reset-filter,
  .cancel-filter {
    width: 30%;
  }
  .rnw-card .ant-table-content,
  .table-card .ant-table-content {
    max-height: calc(100vh - 270px) !important;
    min-height: calc(100vh - 270px) !important;
  }
  .rnw-card.expense-table .ant-table-content {
    max-height: calc(100vh - 240px) !important;
    min-height: calc(100vh - 240px) !important;
  }
  .rnw-card.transfer-addmission .ant-table-content {
    max-height: calc(100vh - 286px) !important;
  }
  .rnw-card.scs-card .ant-table-content,
  .table-card.scs-card .ant-table-content .table-scroll {
    max-height: calc(100vh - 287px) !important;
    min-height: calc(100vh - 287px) !important;
  }
  .view-addmission .table-bg {
    max-height: calc(100vh - 250px);
    min-height: calc(100vh - 250px);
  }
  .rnw-card.absent-present .ant-table-content {
    max-height: calc(100vh - 740px) !important;
    min-height: calc(100vh - 740px) !important;
  }
  .ant-cascader-menu {
    min-width: auto;
  }
  .ant-cascader-menu-item {
    max-width: 130px;
  }
  .ant-cascader-menus {
    flex-wrap: wrap;
  }
  .view-addmission.missing-addmission .table-bg {
    max-height: 73vh;
    min-height: 73vh;
  }
  .payment-details .ant-table-content {
    max-height: calc(100vh - 265px);
  }
  .payment-details .ant-drawer-body {
    max-height: unset;
  }
  .upload-images {
    height: 167px;
    width: 157px;
  }
  .ant-btn > .anticon + span,
  .ant-btn > span .anticon {
    margin-left: 2px;
    font-size: 12px;
  }
  .upload-images .ant-typography {
    font-size: 13px;
  }
  .add-view-upload-images {
    left: 19% !important;
  }
  .ant-drawer-body .drawer-body-scroll {
    overflow: hidden auto;
  }
  .ant-btn:not([disabled]):not(.disabled) {
    margin-bottom: 3px;
  }
}

@media (max-width: 378px) {
  .view-addmission .table-bg {
    max-height: calc(100vh - 260px);
    min-height: calc(100vh - 260px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .ant-drawer.ant-drawer-right.admission-filter.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 30% !important;
    margin-right: 0px;
  }
  .erp-button-group .ant-btn:not(:last-child) {
    margin-right: 0px;
  }
  .gx-text-md-right {
    text-align: right !important;
  }
  .rnw-card.absent-present .ant-table-content {
    max-height: calc(100vh - 735px) !important;
    min-height: calc(100vh - 735px) !important;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .rnw-card.absent-present .ant-table-content {
    max-height: calc(100vh - 740px) !important;
    min-height: calc(100vh - 740px) !important;
  }
  .rnw-card.expense-table .ant-table-content {
    max-height: calc(100vh - 255px) !important;
    min-height: calc(100vh - 255px) !important;
  }
  .csv-btn {
    position: relative;
    margin-bottom: 30px;
  }
  .csv-btn .ant-upload-list {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
  .ant-drawer.ant-drawer-right.admission-filter.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 40% !important;
  }
  .erp-button-group .ant-btn:not(:last-child) {
    margin-right: 0px;
  }
  .erp-button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .add-view-upload-images {
    left: 19% !important;
  }
}
.ant-modal-body .ant-col.ant-col-xs-24:last-child .ant-form-item,
.filter-drawer
  .ant-drawer-body
  .ant-col.ant-col-xs-24:last-child
  .ant-form-item {
  margin-bottom: 0;
}
.btn-apply-filter {
  width: 70%;
}
.reset-filter,
.cancel-filter {
  width: 30%;
}
.basic-info {
  padding: 15px 20px;
  background: #fffafa;
}
.basic-info:not(:last-child) {
  border-bottom: 1px solid #f9e4e4;
}

.basic-info .ant-typography.title {
  width: 200px;
  display: inline-block;
}
.ant-picker-dropdown.ant-picker-dropdown-range {
  max-width: 100%;
}

a,
.gx-link {
  color: #ea5455;
}
.drawer-input-picker .ant-picker-clear {
  right: 0px;
}
.ant-drawer-right .gx-double-down-icon {
  font-size: 22px;
  margin-top: 6px;
}
.no-radio {
  position: relative;
}
.no-radio .ant-radio {
  visibility: hidden;
}
.no-radio .anticon-check {
  position: absolute;
  left: -25px;
  color: #35870d;
  font-size: 16px;
}

.no-radio .anticon-check svg {
  width: 18px;
}

.ant-modal-footer .btn-apply-filter {
  width: auto;
}
.from-to-date .ant-picker-dropdown.ant-picker-dropdown-range {
  max-width: unset;
}

.ant-drawer-body .ant-picker-date-panel {
  width: 100%;
}

.ant-drawer-body .ant-picker-date-panel .ant-picker-body {
  padding: 8px 5px;
  width: 255px;
}

.ant-drawer-body .ant-picker-content {
  table-layout: auto;
  width: 100%;
}
.dynamic-delete-button {
  position: absolute;
}
.add-view-upload-images {
  box-shadow: none;
  position: absolute;
  text-shadow: none;
  top: 50.6%;
  height: 100%;
  left: 18%;
  transform: translate(-50%, -50%);
}
.add-view-upload-images .ant-btn {
  height: 35px;
}
.close-btn {
  font-size: 16px;
  color: #ea5455 !important;
  position: absolute;
  top: 87%;
  right: 10px;
  opacity: 0.5;
  transition: all 0.5s;
  z-index: 999;
  background: #fff;
  padding: 2px;
  border-radius: 50px;
}
.close-btn:hover {
  opacity: 1;
}
.ant-drawer.ant-drawer-right.add-admission-document.ant-drawer-open
  .ant-drawer-content-wrapper {
  width: 35%;
}
.upload-images .ant-form-item-explain-error {
  bottom: -5px;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 105%;
  font-size: 10px;
}
.ant-drawer-body .upload-images .ant-form-item-explain-error {
  bottom: -33px;
  width: 100%;
  z-index: 9999;
  background: #fff;
}
.ant-drawer-body .add-new-upload .ant-input {
  height: 35px;
  padding-left: 10px !important;
  width: 100%;
}
.upload-images .anticon-close {
  cursor: pointer;
}
.ant-drawer-body .add-new-upload + .label.as-placeholder {
  top: 9px;
  left: 10px;
}
.add-new-upload > .ant-row > .ant-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ant-drawer-body .add-new-upload + .label.as-placeholder {
  font-size: 14px !important;
}
.add-admission-upload .ant-form .ant-row > .ant-col.ant-form-item-control {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.add-admission-document .ant-card-body .upload-inputs {
  display: flex;
  flex-wrap: wrap;
}

.ant-drawer.upload-documents .upload-inputs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.add-admission-upload {
  position: relative;
  // margin-top: 15px;
}
.add-admission-document .add-admission-upload-btn {
  position: absolute;
  width: auto !important;
  right: 19px;
  top: 9px;
}
.add-admission-document .add-admission-upload-btn button {
  margin-top: 0 !important;
  height: 30px;
}
.add-admission-document .close-btn {
  line-height: 1;

  top: -9px;
  right: -3%;
  opacity: 1;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.Upload_Btn .ant-form-item:not(.add-new-upload) {
  position: absolute;
  top: 50%;
  transform: translateY(-58%);
  right: 12px;
  margin: 0;
}
.add-new-upload .ant-input {
  padding-right: 83px;
}
.Upload_Btn .upload-btn {
  // position: absolute;
  padding: 0 !important;
  border: none !important;
  height: unset !important;
}

.Upload_Btn .ant-form-item-control-input + div {
  position: absolute;
  bottom: 0;
  width: 180px;
  right: 0;
}

.Upload_Btn .doubleError.false .ant-form-item-control-input + div {
  bottom: -28px;
  right: -12px;
}
.add-admission-upload.Upload_Btn
  .ant-btn.ant-btn-default:hover
  .anticon.anticon-upload,
.add-admission-upload.Upload_Btn .ant-btn.ant-btn-default.upload-btn:hover {
  border-color: transparent !important;
  background-color: transparent !important;
  color: #ea5455 !important;
}
.Upload_Btn {
  margin-top: 21.5px;
}
.no-uploaded-btn .view-admission-documents {
  top: 11%;
  right: 13px;
}
.no-uploaded-btn .view-admission-documents .ant-form-item {
  margin-bottom: 0;
}
.add-admission-upload.Upload_Btn .gx-m-0.false {
  position: relative;
  top: -35px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .add-admission-document .ant-card-body .upload-inputs .add-admission-upload {
    width: 33%;
    padding: 0 10px;
  }
  .gx-text-md-right {
    text-align: right !important;
  }
  .missing-addmission .admission-card span.ant-typography-ellipsis.username {
    max-width: 80%;
  }
  .missing-addmission .admission-card .ant-btn.ant-btn-primary {
    padding: 0 5px;
    font-size: 12px;
  }
  .rnw-card.absent-present .ant-table-content {
    max-height: calc(100vh - 735px) !important;
    min-height: calc(100vh - 735px) !important;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .add-admission-document .ant-card-body .upload-inputs .add-admission-upload {
    width: 25%;
    padding: 0 10px;
  }
  .add-admission-document .close-btn {
    right: -2%;
  }
  .gx-text-md-right {
    text-align: right !important;
  }
  .view-addmission .table-bg {
    max-height: calc(100vh - 255px);
    min-height: calc(100vh - 255px);
  }
  .view-addmission.missing-addmission .table-bg {
    max-height: calc(100vh - 187px);
    min-height: calc(100vh - 187px);
  }
}
@media (min-width: 1400px) {
  .add-admission-document .ant-card-body .upload-inputs .add-admission-upload {
    width: 16.66%;
    padding: 0 10px;
  }
  .gx-text-md-right {
    text-align: right !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .add-admission-document .ant-card-body .upload-inputs .add-admission-upload {
    width: 33%;
    padding: 0 10px;
  }
  .ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right.sub-course-drawer
    .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 10px;
  }
  .add-admission-document .close-btn {
    top: 1px;
    right: -3%;
  }
}
@media (min-width: 992px) {
  .ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right.sub-course-drawer
    .ant-drawer-content-wrapper {
    width: 950px !important;
  }
}
.sub-course-drawer .ql-toolbar.ql-snow {
  padding: 5px;
}
.sub-course-drawer .quill .ql-editor {
  min-height: 2.9em;
}

.sub-course-drawer .ql-container.ql-snow {
  border-color: #ffcdcd !important;
  border-radius: 0px 0px 6px 6px;
}
.sub-course-drawer #subtopiceditor label.label.as-placeholder {
  top: 48px !important;
}
.sub-course-drawer .ql-toolbar.ql-snow {
  background-color: #fffbfb99;
  border-color: #ffcdcd !important;
  border-radius: 6px 6px 0 0;
}
@media (max-width: 575px) {
  .img-thumbnail {
    height: 80px;
  }
}
.upload-icon .ant-btn.upload-btn span:nth-child(2) {
  display: none;
}
@media (max-width: 767px) {
  .ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right.sub-course-drawer
    .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .add-admission-document .ant-card-body .upload-inputs .add-admission-upload {
    width: 50%;
    padding: 0 10px;
  }
}
@media (max-width: 476px) {
  .sub-course-drawer #subtopiceditor label.label.as-placeholder {
    top: 75px !important;
  }
}
@media (min-width: 477px) and (max-width: 551px) {
  .sub-course-drawer #subtopiceditor label.label.as-placeholder {
    top: 73px !important;
  }
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 0;
}
.rnw-card .ant-table-content .ant-table-content {
  min-height: unset !important;
}

.ant-drawer.ant-drawer-right.template-drawer.ant-drawer-open.ant-drawer-right
  .ant-drawer-content-wrapper {
  width: 60% !important;
}

.view-topic-scroll {
  overflow: auto;
  max-height: calc(100vh - 236px);
}

.anticon.anticon-double-right.gx-double-right-icon.disabled {
  cursor: no-drop;
  pointer-events: unset;
}
.direction-btn .gx-double-right-icon-wrapper {
  margin: unset;
}
.ant-list-item-meta-content {
  width: 100%;
}
.ant-dropdown-menu-title-content .ant-btn-dangerous .anticon.anticon-delete,
.ant-dropdown-menu-title-content
  .ant-btn-dangerous
  .anticon.anticon-delete
  ~ span,
.ant-table-row-expand-icon:focus-visible,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  color: #ea5455;
}
table .ant-empty-normal {
  height: calc(100vh - 410px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
table.faculty-signing .ant-empty-normal {
  height: auto;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .ant-drawer .ant-drawer-footer .ant-btn.btn-cancel,
  .ant-drawer .ant-drawer-footer .ant-btn.btn-submit {
    height: 40px;
    font-size: 15px;
  }
  #gr_id_gr_id {
    height: 32px !important;
  }
  .ant-btn {
    height: 30px;
  }
  .rnw-card .ant-table-content,
  .table-card .ant-table-content .table-scroll {
    max-height: calc(100vh - 210px) !important;
    min-height: calc(100vh - 210px) !important;
  }
  .rnw-card.batches .ant-table-content {
    max-height: calc(100vh - 260px) !important;
    min-height: calc(100vh - 260px) !important;
  }
  .rnw-card.transfer-addmission .ant-table-content {
    max-height: calc(100vh - 260px) !important;
    min-height: calc(100vh - 260px) !important;
  }
  .table-card.cheque-amount .ant-table-content {
    max-height: calc(100vh - 245px) !important;
    min-height: calc(100vh - 245px) !important;
  }
}
@media (max-width: 575px) {
  .add-new-upload .ant-input {
    padding-right: 25px;
  }

  .add-admission-document .close-btn {
    top: 2px;
    right: -10%;
  }
  .Upload_Btn .ant-form-item-control-input + div {
    top: -35px;
    width: 140px;
    right: -3px;
  }
}
.add-addmission-form .ant-select-dropdown {
  max-width: 100%;
  min-width: 100% !important;
  width: 100% !important;
}
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-inline-end: 8px;
}

.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
  float: right;
}
.no-preview {
  background: #fffbfbab;
  border: 1px solid #ea5455;
  border-radius: 10px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  width: 100%;
}
.no-preview img {
  mix-blend-mode: multiply;
}
.add-admission-document .ant-card-body {
  padding: 15px 14px 40px;
}
.ant-form .add-admission-upload .ant-row > .ant-col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.add-admission-upload.Upload_Btn > div {
  position: relative;
}
.add-admission-upload.Upload_Btn > div div:nth-child(3),
.add-admission-upload .ant-form-item-control-input-content div:nth-child(2) {
  // position: absolute !important;
  background: #fff;
  height: 150px;
  max-width: 100%;
  width: 100%;
  overflow: hidden !important;
  margin-top: 15px !important;
  border-radius: 10px;
  margin-bottom: 0 !important;
}
.add-admission-upload.Upload_Btn > div div:nth-child(3) {
  margin-top: 0px !important;
}
@media (max-width: 575px) {
  .add-admission-upload .ant-form-item-control-input-content div:nth-child(2) {
    background: #fff;
    height: 150px;
    width: 100%;
  }
  .Upload_Btn {
    margin-top: 15px;
  }
  .add-admission-upload.Upload_Btn .close-btn {
    right: -4%;
  }
}
.faculty-signing-table .ant-card-body {
  overflow-x: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .add-admission-upload .ant-form-item-control-input-content div:nth-child(2) {
    // position: absolute !important;
    background: #fff;
    height: 150px;
  }
  .student-mobile-gr {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .Upload_Btn {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .add-admission-document .close-btn {
    top: 1px;
    right: -3%;
  }
  .add-admission-upload.Upload_Btn .gx-m-0.false {
    top: -38px;
    right: 6px;
  }
}
.view-remarks .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  display: none;
}

.text-editor .label {
  top: 52px;
}
.text-editor .ql-editor {
  padding-top: 27px;
}
.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content:has(> .remark-message) {
  margin-left: 0;
}
.ant-modal-confirm-body .anticon-info-circle {
  display: none;
}
.ant-modal-confirm-info .ant-modal-confirm-title {
  font-size: 18px;
  flex: 1 1;
  margin: 0;
  color: #535353;
  font-weight: 500;
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  line-height: 22px;
}
.ant-modal-confirm .ant-modal-body {
  padding: 15px;
}
.remark-message {
  padding-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #7e7e7e;
}
.fixHeight-model {
  height: 300px;
  overflow: scroll;
}
.upload-images .ant-upload-list-item,
.add-admission-upload .ant-upload-list-item {
  z-index: 9999;
  margin-inline-end: 0;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.upload-images .ant-upload-list-item {
  bottom: -123px;
  right: -5px;
}
.upload-images .ant-upload-list-item .ant-upload-span {
  justify-content: center;
}
.upload-images .ant-upload-list-item .ant-upload-list-item-name,
.upload-images .ant-upload-list-item .ant-upload-text-icon,
.add-admission-upload .ant-upload-list-item .ant-upload-list-item-name,
.add-admission-upload .ant-upload-list-item .ant-upload-text-icon {
  display: none;
}

.upload-images .ant-upload-list-item .ant-upload-list-item-card-actions-btn,
.add-admission-upload
  .ant-upload-list-item
  .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.upload-images .ant-upload-list-item .ant-upload-list-item-info,
.add-admission-upload .ant-upload-list-item .ant-upload-list-item-info {
  background-color: #ea5455;
}
.upload-images .ant-upload-list-item-card-actions .anticon,
.upload-images .ant-upload-list-text .ant-upload-span,
.add-admission-upload .ant-upload-list-item-card-actions .anticon {
  color: #ffffff;
}
.add-admission-upload .ant-upload-list-text .ant-upload-span {
  justify-content: center;
}
.signin-sheet-table {
  width: 100%;
}
.signin-sheet-table td,
.signin-sheet-table th {
  border: 1px solid #d7d7d7;
  padding: 7px 20px;
  font-size: 16px;
  font-weight: 500;
}
.signin-sheet-table tbody tbody td {
  padding: 4px 20px;
}
.signin-sheet-table th {
  background: #ea5455;
  color: #fff;
}
.table-striped tbody tr:nth-of-type(even) {
  background: #fff8f8;
}
.table-striped tbody tr tbody tr {
  background: #fff8f8;
}
.table-striped tbody tr tbody.sheet-data tr {
  background: transparent;
}
// .signIn-sheet .ant-card-body tr > td {
//   border: none;
// }
.signin-sheet-table td {
  font-weight: 400;
}
.signin-sheet-table td b {
  font-weight: 500;
}
.signin-sheet-table th:nth-child(1) {
  width: 5%;
  text-align: center;
}
.signin-sheet-table th:nth-child(2) {
  width: 50%;
  text-align: left;
}
@media (min-width: 768px) and (max-width: 991px) {
  .ant-modal-confirm-info {
    width: 75% !important;
  }
}
@media (min-width: 992px) {
  .ant-modal-confirm-info {
    width: 45% !important;
  }
}
@media (max-width: 575px) {
  .gx-popover-horizantal {
    right: 10px !important;
    left: unset !important;
    top: 74px !important;
  }
  .ant-drawer.ant-drawer-right.assign-batch.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    overflow: hidden;
    width: 100% !important;
  }
  .student-mobile-gr {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .ql-toolbar.ql-snow {
    padding: 7px;
  }
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 5px;
  }
  .ant-drawer.ant-drawer-right.template-drawer.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}
@media (min-width: 344px) and (max-width: 533px) {
  .text-editor .label {
    top: 67px !important;
  }
}

@media (min-width: 533px) and (max-width: 575px) {
  .text-editor .label {
    top: 43px !important;
  }
}
@media (max-width: 322px) {
  .text-editor .label {
    top: 98px;
  }
}
.ant-drawer-body table .ant-empty-normal {
  height: calc(100vh - 213px);
}
.ant-dropdown-menu-item:has(> span ul.batches-status-menu):hover {
  background: #fff;
}

.ant-dropdown-menu-item:has(> span ul.batches-status-menu) {
  padding: 0;
}
.ant-dropdown-menu-item:has(> span ul.batches-status-menu):hover
  .ant-menu-light
  .ant-menu-item {
  color: #545454;
}
.ant-dropdown-menu .ant-menu-vertical.batches-status-menu .ant-menu-item {
  padding: 5px 12px !important;
}
.batches-status-menu li:hover {
  background-color: #fff2f3c9;
}
.batches-status-menu li:hover .ant-menu-title-content {
  color: #ea5455;
}
.ongoing-status span {
  color: #54b3ea;
}

.upcoming-status span {
  color: #ffa426;
}

.upcoming-status span {
  color: #ffa426;
}
.hold-status span {
  color: #9852a5;
}

.completed-status span {
  color: #44a789;
}
.cancelled-status span {
  color: #ea5455;
}
.absent-present .card-header h5,
.faculty-dashboard-card .card-header h5 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #545454;
}
.signIn-sheet-table {
  height: calc(100vh - 214px);
}
.signIn-sheet-table .sticky-top {
  position: sticky;
  top: 0;
  z-index: 9999;
}
.faculty-dashboard-card .ant-card-body {
  padding: 20px;
}
.faculty-dashboard-card .card-header {
  padding-bottom: 18px;
}
.faculty-dashboard-card .card-body {
  height: 241px;
  overflow: auto;
}
.faculty-signing {
  width: 100%;
}
.faculty-dashboard-card.total-card {
  .ant-card-body {
    box-shadow: none;
    padding: 0 !important;
  }
}
.dashboard-card-main {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 2.1875rem rgba(90, 97, 105, 0.07),
    0 0 1.40625rem rgba(90, 97, 105, 0.07),
    0 0 0.53125rem rgba(90, 97, 105, 0.09),
    0 0 0.1875rem rgba(90, 97, 105, 0.07);
  border-radius: 10px;
}

.faculty-dashboard-card.total-card {
  margin-bottom: 0px;
  padding: 15px 10px 10px 10px;
  background: #fff4f4;
}
.faculty-signing thead tr th {
  padding: 8px 10px;
  font-weight: 500;
  position: sticky;
  top: 0;
  color: #fff;
  background: #ea5455;
  z-index: 99;
}
.faculty-signing thead tr th:before {
  background-color: hsla(0, 0%, 100%, 0.5);
  content: "";
  height: 1.6em;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 9999;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  width: 1px;
}
.faculty-signing thead tr th:last-child:before {
  display: none;
}
.faculty-signing tbody tr td {
  border: 1px solid #e1e1e1;
  padding: 8px 10px;
  font-weight: 500;
}
.main-topic {
  font-size: 16px;
  font-weight: 600;
  background: #fff8f8;
}
.faculty-signing tbody tr.sub-topic td {
  opacity: 0.9;
  font-weight: 400;
}
.faculty-signing tbody tr td:has(td) {
  padding: 0;
}
.faculty-signing tbody tr td td {
  border-top: none;
  border-bottom: none;
  border-left: none;
}
.faculty-signing tbody tr td td:last-child {
  border-right: 0;
}

.faculty-dashboard-card .card-body table {
  width: 100%;
}
.faculty-signing-th {
  height: 1.6em;
  padding: 8px 8px;
  font-weight: 500;
  border-top: none;
  border-bottom: none;
  border-left: none;
}

.faculty-signing-th:nth-child(3n + 1) {
  border-right: 1px solid #e1e1e1;
}

.faculty-signing-th:not(:nth-child(3n)) {
  border-right: 1px solid #e1e1e1;
}
.faculty-dashboard-card .ant-card-body .card-header {
  height: 50px;
}
.faculty-dashboard .ant-table-thead > tr > th {
  background: #fff4f4 !important;
  color: #545454 !important;
  font-weight: 500;
  padding: 8px 10px;
  font-size: 15px;
}
.faculty-dashboard .ant-table-summary > tr > td {
  background: #fff4f4 !important;
  color: #545454 !important;
  font-weight: 500;
  padding: 8px 10px;
  font-size: 15px;
}
.faculty-dashboard .ant-table-column-sorter {
  color: #545454 !important;
  opacity: 0.5;
}
.faculty-dashboard .ant-table-thead > tr > th::before {
  background-color: rgb(169 169 169 / 26%) !important;
  position: absolute !important;
  top: 50% !important;
  right: 0 !important;
  width: 1px !important;
  height: 1.6em !important;
  background-color: rgba(0, 0, 0, 0.06) !important;
  transform: translateY(-50%) !important;
  transition: background-color 0.3s !important;
  content: "";
}
.faculty-dashboard .ant-table-summary > tr > td::before {
  background-color: rgb(169 169 169 / 26%) !important;
  position: absolute !important;
  top: 50% !important;
  right: 0 !important;
  width: 1px !important;
  height: 1.6em !important;
  background-color: rgba(0, 0, 0, 0.06) !important;
  transform: translateY(-50%) !important;
  transition: background-color 0.3s !important;
  content: "";
}
.faculty-dashboard
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child::before {
  right: unset;
  left: 0;
}
.faculty-dashboard .ant-table-cell {
  border: none;
}
.faculty-dashboard-card .card-body table tr th {
  font-weight: 500;
  padding: 8px 10px;
  font-size: 15px;
}
.faculty-dashboard-card .card-body table tbody tr {
  border-bottom: 1px solid #e8eaf2;
}
.faculty-dashboard-card .card-body table tbody tr:last-child {
  border: none;
}
.faculty-dashboard-card .card-body table tr td {
  padding: 12px 10px;
  text-align: center;
  font-size: 14px;
  color: #626262;
}
.faculty-dashboard-card.total-card .ant-card-body .card-header,
.faculty-dashboard-card.student-card .ant-card-body .card-header {
  height: auto;
  border-bottom: 1px solid #e8eaf2;
}
.faculty-dashboard-card.total-card .ant-card-body .card-body,
.faculty-dashboard-card.student-card .ant-card-body .card-body {
  height: auto;
  padding: 24px 0;
}

.faculty-dashboard-card .ant-card-body {
  padding: 20px;
}
.faculty-dashboard-card .card-header {
  padding-bottom: 15px;
}
.faculty-dashboard-card .card-body {
  height: 240px;
  overflow: auto;
}

.faculty-dashboard-card .card-body table {
  width: 100%;
}

.faculty-dashboard-card .ant-card-body .card-header {
  height: 50px;
}
.faculty-dashboard-card .card-body table thead tr th.sticky-top {
  position: sticky;
  top: 0;
  background: #fff4f4;
}
.faculty-dashboard-card .card-body table tr th {
  font-weight: 500;
  padding: 8px 10px;
  font-size: 15px;
}
.faculty-dashboard-card .card-body table tbody tr {
  border-bottom: 1px solid #e8eaf2;
}
.faculty-dashboard-card .card-body table tbody tr:last-child {
  border: none;
}
.faculty-dashboard-card .card-body table tr td {
  padding: 12px 10px;
  text-align: left;
  font-size: 14px;
  color: #626262;
}
.faculty-dashboard-card
  .ant-select-multiple.ant-select-lg
  .ant-select-selection-item {
  height: 24px;
}
.faculty-dashboard-card.total-card .ant-card-body .card-header,
.faculty-dashboard-card.student-card .ant-card-body .card-header {
  height: auto;
  border-bottom: 1px solid #ea545533;
}
.faculty-dashboard-card.total-card .ant-card-body .card-body,
.faculty-dashboard-card.student-card .ant-card-body .card-body {
  height: auto;
  padding: 15px 0 0;
}

.faculty-dashboard-card.total-card .ant-card-body,
.faculty-dashboard-card.total-card .ant-card-body .card-body {
  padding-bottom: 0px;
}
.faculty-dashboard-card.total-card .ant-card-body {
  padding-bottom: 5px;
}
.faculty-dashboard-card.student-card .ant-card-body .card-body h6 {
  font-size: 14px;
  color: #73788b;
  font-weight: 400;
}
.faculty-dashboard-card.total-card .ant-card-body .card-body h5,
.faculty-dashboard-card.student-card .ant-card-body .card-body h5 {
  font-size: 20px;
  color: #ea5455;
  margin-bottom: 0;
  font-weight: 600;
}
.dashboardInfoBtn {
  // top: 56%;
  // transform: translateY(-50%);
  // right: -20px;
  color: #ea5455;
  // position: absolute;
  cursor: pointer;
  z-index: 9;
}
.performance-details:not(:last-child) {
  border-right: 1px solid #d6d9e5a3;
}
.faculty-dashboard-card.student-card .ant-card-body {
  padding-bottom: 0;
}
.project-table {
  width: 100%;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
.project-table thead tr th {
  border: 1px solid #ea5455;
  padding: 8px 10px;
  font-weight: 500;
  position: relative;
  color: #fff;
  background: #ea5455;
}
.project-table thead tr th:before {
  background-color: hsla(0, 0%, 100%, 0.5);
  content: "";
  height: 1.6em;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  width: 1px;
}
.project-table thead tr th:last-child:before {
  display: none;
}
.ant-tabs-tab:has(> .ant-tabs-tab-btn .success-tab):hover,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .success-tab {
  color: #4bb543;
}
.project-table tr td {
  padding: 10px;
}
.project-table tr.even {
  background: #fff8f8;
}
.student-marks h6 {
  font-size: 14px;
  margin: 0;
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.student-marks {
  width: 100%;
  display: block;
}
.student-marks input {
  background: #fff8f8;
  border: none;
  padding: 6px 8px;
  border-radius: 4px;
  width: 60px;
  border: 1px solid #ea54558f;
  text-align: center;
  margin-right: 10px;
}
.student-marks td {
  display: flex;
  width: 20%;
  align-items: center;
}
.faculty-dashboard-card table .ant-empty-normal {
  height: 176px;
  margin: 0;
}

.present-badge .ant-badge-count {
  background: #1da735;
}
.sub-table {
  width: 100%;
}

.sub-table tr {
  border-bottom: 1px solid #e1e1e1;
  height: 33.99px;
  display: flex;
  justify-content: center;
}
.sub-table tr:last-child {
  border-bottom: none;
}

.faculty-signing tbody tr td.sub-table {
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  border: none;
  height: 34px;
  line-height: 19px;
  padding: 8px 40px;
}
.sub-table.great {
  background: #e2f5e3;
  color: #2f9742;
}

.sub-table.good {
  background: #e2f2f5;
  color: #436e76;
}

.sub-table.average {
  background: #f5eee2;
  color: #6c5b3c;
}
.sub-table.poor {
  background: #f7dbdd;
  color: #c52f37;
}
.add-admission-upload.Upload_Btn > div div:nth-child(3),
.add-admission-upload
  .ant-form-item-control-input-content
  > div:not(.false):last-child {
  // height: calc(100% - -110px) !important;
  border: 1px solid #ea545547;
}

.add-admission-upload .no-preview img {
  opacity: 0.5;
}
.upload-images .dynamic-delete-button.close-btn {
  right: -9%;
}
.logo-upload .delete-btn,
.no-uploaded-btn .delete-btn,
.add-admission-upload.Upload_Btn .delete-btn,
.ant-form-item-control-input .delete-btn {
  position: absolute;
  bottom: 0px;
  right: 0;
  background: #ea5455;
  height: 30px;
  width: 30px;
  padding: 0 0px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
}
.logo-upload .delete-btn,
.add-admission-upload.Upload_Btn .delete-btn,
.add-admission-upload .ant-form-item-control-input .delete-btn {
  bottom: 10px;
  right: 10px;
}

.ant-menu-item span a.nav-link[aria-current="page"] {
  color: #ea5455;
}
.ant-menu-item:has(span a.nav-link[aria-current="page"]) {
  background: #ffecec;
}
.GR-id-filter .ant-form-item {
  margin-bottom: 0;
}
.GR-id-filter {
  position: relative;
}
.GR-id-filter button.ant-btn[type="submit"] {
  position: absolute;
  top: 50%;
  right: 10px;
  color: #ea5455;
  transform: translateY(-50%);
  width: 35px !important;
  border: none;
}

.user-profile-card .card-header {
  position: relative;
  padding: 0 10px;
}
.user-profile-card .card-header .gx-page-title {
  font-size: 16px;
}
.user-profile-card .card-header span {
  font-weight: 500;
}
.user-profile-card .card-header .line {
  bottom: -12px;
}
.user-profile-card .ant-card-body .card-body {
  padding: 30px 0 0;
}
.user-profile-card .ant-card-body .card-body label {
  font-size: 16px;
  opacity: 0.7;
}
.user-profile-card .ant-card-body .card-body h4 {
  margin: 5px 0 0;
  font-size: 16px;
  font-weight: 400;
}
.user-zone-name {
  font-size: 14px !important;
  padding: 6px 15px;
  background: #ea54551f;
  width: 100%;
  border-radius: 5px;
  color: #a32b2c;
}
.disable-pay-btn .ant-btn[disabled] {
  background: #fff !important;
  color: #ea5455 !important;
  border: none !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  font-size: 13px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  overflow: hidden;
}
.disable-pay-btn.paid-amount .ant-btn[disabled] {
  color: #54ca68 !important;
}
.erp-button-group .ant-badge-count {
  max-width: max-content;
  min-width: 20px;
  padding: 0 5px;
}

span span.ant-badge:empty {
  display: none;
}
.ptm-input .ant-form-item-control-input-content,
.ptm-input .ant-input-number {
  height: 35px;
}
.ptm-input .label.as-placeholder {
  top: 9px;
}
.btn_config {
  height: auto;
}
.ant-btn-ghost.btn_config {
  border-color: #bcbcbc;
  background: #fff;
}
.ant-dropdown-menu-item:has(> .ant-dropdown-menu-title-content ul:empty) {
  padding: 0 !important;
}
.user-config-select .ant-select-selector {
  height: 43px !important;
}
.department-selected {
  padding: 10px;
  background: #ea54550d;
  border-radius: 5px;
  color: #8f2a2b;
  margin: 10px 0 0 0;
}
.department-selected .ant-col {
  margin: 5px 0;
}
.department-selected:empty {
  padding: 0;
  margin: 0;
}
.user-config-select .ant-form-item {
  margin-bottom: 0 !important;
}
.user-config-table table tr {
  vertical-align: top;
}
.user-config-select .ant-select-selection-item {
  margin-bottom: 0;
}
.ant-card-body .multidate-select .ant-picker-clear {
  right: 0;
}
.ant-drawer-right .multidate-select .ant-picker-clear {
  right: 11px;
}
.sheet-data tr td {
  border-bottom: 1px solid #e1e1e1 !important;
}

.sheet-data tr:last-child td,
.sheet-data tr td td {
  border-bottom: none !important;
}
.faculty-signing tbody tr td:has(> .sheet-data:empty) {
  border: none;
}

.type-a .ant-scroll-number {
  background: #2f9742;
  color: #ffffff;
}
.type-b .ant-scroll-number {
  background: #436e76;
  color: #ffffff;
}

.type-c .ant-scroll-number {
  background: #6c5b3c;
  color: #ffffff;
}
.type-d .ant-scroll-number {
  background: #c52f37;
  color: #ffffff;
}
.signingSheet-gr {
  opacity: 0.7;
  font-size: 14px;
  color: #000;
}
.signingSheet-gr a {
  color: #000;
}
.sheet-data tr td.border-bottom-none {
  border-bottom: none !important;
}
.qr-scanner .anticon.anticon-close {
  position: absolute;
  right: -10px;
  z-index: 999;
  background: #ea5455;
  padding: 5px;
  border-radius: 50%;
  top: -8px;
  color: white;
  font-weight: 600;
}
.verify-button {
  background: #54ca68 !important;
  color: #fff !important;
  background-color: #54ca68 !important;
  border-color: #54ca68 !important;
}

.ant-btn-primary.verify-button:hover,
.ant-btn-primary.verify-button:focus {
  background-color: transparent !important;
  color: #54ca68 !important;
  border-color: #54ca68 !important;
}

.faculty-dashboard-card .card-body .ant-table-content {
  height: 240px;
}
.transaction-date .ant-picker-clear {
  right: 0px;
}
.upload-images .view-btn {
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.5s;
}
.upload-images:hover .view-btn {
  transform: translate(-50%, -50%) scale(1);
}

.user-config-table.table-card .ant-card-body {
  padding: 30px;
}
.ant-select-selector .ant-select-selection-overflow {
  max-width: 90%;
}
.user-config-select {
  margin-bottom: 10px;
}
.user-config-select .ant-form-item-control-input-content button {
  float: right;
  margin-left: 20px;
}
.user-config-select .ant-btn:not(:last-child) {
  margin-right: 0px;
}
.user-config-select .drawer-form .ant-form-item {
  margin-bottom: 0px;
}
.GR-id-filter .ant-form-item-explain-error {
  position: absolute;
  margin: 0;
}

.signingsheet-card .ant-card-body,
.faculty-signing-table .ant-card-body {
  overflow: hidden;
}
.signingsheet-card .ant-card-body > div,
.faculty-signing-table .ant-card-body > div {
  max-height: calc(100vh - 230px);
  overflow: auto;
}
table tr td ul {
  margin-bottom: 0;
}
.attendence-table .ant-table-content {
  max-height: calc(100vh - 229px);
  overflow: auto;
}
.faculty-dashboard-card .ant-select-show-search {
  width: 150px !important;
}
@media (max-width: 575px) {
  .user-config-select {
    width: 100% !important;
    flex-wrap: wrap;
  }
  .user-config-select .ant-form-item-control-input-content.ant-select-selector {
    width: 100% !important;
  }
  .user-config-select {
    border-bottom: 2px solid #fef6f6;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .user-config-select {
    border-bottom: 2px solid #fef6f6;
  }
  .user-config-select .ant-form .ant-row > .ant-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .attendence-table .ant-table-content {
    max-height: calc(100vh - 233px);
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .user-config-select button {
    width: auto !important;
  }
  .single-branch-list {
    width: 75%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .user-config-select {
    border-bottom: 2px solid #fef6f6;
  }
  .attendence-table .ant-table-content {
    max-height: calc(100vh - 244px);
  }
}

@media (max-width: 575px) {
  .faculty-dashboard-card .card-header h5 {
    font-size: 13px;
  }
  .faculty-dashboard-card .ant-select-show-search {
    width: 40% !important;
  }
  .faculty-dashboard-card .ant-select-selector {
    height: 35px !important;
  }
  .faculty-signing-table .ant-card-body {
    max-height: calc(100vh - 220px);
  }
  .faculty-signing-table .ant-card-body > div {
    max-height: calc(100vh - 250px);
    overflow: auto;
  }
}
@media (max-width: 767px) {
  .faculty-signing-table .ant-card-body {
    max-height: calc(100vh - 187px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .dashboard-card-main .faculty-dashboard-card .card-header h5 {
    font-size: 13px;
  }
  .faculty-dashboard-card.ant-select-show-search {
    width: 200px !important;
  }
  .faculty-dashboard-card .ant-select-selector {
    height: 35px !important;
  }
  .faculty-dashboard-card.total-card .ant-card-body,
  .faculty-dashboard-card.total-card .ant-card-body .card-body {
    padding: 12px 10px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector::after,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 41px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .user-config-select {
    border-bottom: 2px solid #fef6f6;
  }
  .single-branch-list {
    width: 35%;
  }
  .user-config-select {
    border-bottom: 2px solid #fef6f6;
  }
  .faculty-dashboard-card .card-header h5 {
    font-size: 14px;
  }
  .faculty-dashboard-card .ant-select-show-search {
    width: 180px !important;
  }
  .faculty-dashboard-card .ant-select-selector {
    height: 40px !important;
  }
  .faculty-dashboard-card.total-card .ant-card-body,
  .faculty-dashboard-card.total-card .ant-card-body .card-body {
    padding: 10px 10px;
  }
}
@media (min-width: 768px) and (max-width: 992) {
  .faculty-dashboard-card table .ant-empty-normal {
    height: 180px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .faculty-dashboard-card table .ant-empty-normal {
    height: 170px;
  }
}

@media (max-width: 575px) {
  .user-profile-card .ant-card-body .card-body h4 {
    font-size: 12px;
  }
  .user-profile-card .ant-card-body .card-body label {
    font-size: 15px;
  }

  .single-branch-list {
    width: 85%;
    margin-top: 60px;
  }
  .custom-branch-selection {
    padding: 10px 15px;
    font-size: 13px;
  }
  .ant-popover-hidden .menu-overlay {
    height: 0;
    bottom: unset;
    transition: all 5s;
  }
  .menu-overlay {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0.5;
    z-index: -9;
  }
}
@media (max-width: 280px) {
  .user-profile-card .ant-card-body .card-body h4 {
    font-size: 9px;
  }
  .user-profile-card .ant-card-body .card-body label {
    font-size: 11px;
  }
  .user-profile-card .user-zone-name {
    font-size: 12px !important;
  }
  .user-profile-card .card-header .gx-page-title {
    font-size: 14px;
  }
  .user-profile-card .card-header span {
    font-size: 11px;
  }
  .user-profile-card .ant-drawer .ant-btn.btn-cancel,
  .user-profile-card .ant-btn {
    height: 28px;
  }
}
@media (max-width: 375px) {
  .user-profile-card .card-header span {
    font-size: 11px;
  }
  .user-profile-card .ant-drawer .ant-btn.btn-cancel,
  .user-profile-card .ant-btn {
    height: 28px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .user-profile-card .ant-card-body .ant-btn {
    padding: 8px 10px !important;
  }
  .single-branch-list {
    width: 50%;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .user-profile-card .ant-btn {
    height: 40px;
  }
  .single-branch-list {
    width: 35%;
  }
  .GR-id-filter .ant-form-item-explain-error {
    font-size: 10px;
  }
}
.upload-images .ant-form-item {
  margin-bottom: -10px;
}

.project-main {
  max-height: calc(100vh - 210px);
  overflow: auto;
}
.project-list {
  background: #ea54550d;
  padding: 15px;
  cursor: pointer;
  margin-bottom: 10px;
}
.student-list-box {
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.project-list:last-child {
  margin-bottom: 0px;
}
.project-list h5 {
  font-size: 15px;
  opacity: 0.8;
  line-height: 1.5;
  font-weight: 500;
  margin: 0;
}
.report-card .ant-col.ant-col-md-6:nth-child(n + 3) .ant-form-item {
  margin-bottom: 0;
}
.ant-form > .ant-row.report-card-row {
  margin-left: -7px !important;
  margin-right: -7px !important;
}
.ant-slider-handle {
  border: solid 2px #ea5455;
}
.ant-slider-track {
  background-color: #ffb0b0;
}
.ant-slider-handle.ant-tooltip-open,
.ant-slider-dot-active {
  border-color: #ea5455;
}
.ant-slider:hover .ant-slider-track {
  background-color: #ffcdcd;
}
.ant-slider-handle:focus {
  border-color: #ffcdcd;
  outline: none;
  box-shadow: 0 0 0 5px rgba(255, 205, 205, 0.4);
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #ffcdcd;
}
.project-list span {
  margin-top: 5px;
  display: inline-block;
  color: #000;
  opacity: 0.5;
  text-transform: capitalize;
}
.list-filter-btn {
  padding: 0 15px 15px;
}
.list-filter-btn .exam-btn {
  width: 32%;
}
.project-lists {
  height: calc(100vh - 158px);
}
@media (max-width: 768px) {
  .project-lists {
    height: calc(100vh - -227px);
  }
}
@media (max-width: 378px) {
  .project-lists {
    height: calc(100vh - -300px);
  }
}
.project-lists:has(> .ant-card-body .ant-empty.ant-empty-normal) {
  height: auto;
}
.project-lists .ant-card-body:has(.ant-empty.ant-empty-normal) {
  padding: 50px 0;
}
.project-lists .ant-card-body {
  height: 100%;
}
.tab-content {
  padding-bottom: 15px;
  border-bottom: 1px solid #dbdbdb;
}
.tab-title {
  font-size: 20px;
  margin-bottom: 0;
}
.marks input {
  border: none;
  display: inline-block;
  border-bottom: 1px solid #262626b6;
  width: 33px;
  text-align: center;
  border-radius: 0;
  text-align: center;
  padding: 0 !important;
  background: transparent;
  border: none;
  color: #262626;
  font-weight: 400;
  font-size: 15px;
  height: auto;
  border-bottom: 1px solid #2626265c !important;
}
.marks input.ant-input-disabled {
  border: none !important;
  background: transparent;
  color: rgba(0, 0, 0, 1);
}
.marks input.ant-input:focus {
  border-bottom: 1px solid #2626265c !important;
}
.marks input::-webkit-outer-spin-button,
.marks input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.project-lists .ant-form-item {
  margin-bottom: 0;
}
/* Firefox */
.marks input[type="number"] {
  -moz-appearance: textfield;
}
.marks h6 {
  font-size: 16px;
  margin: 0;
  padding-left: 5px;
}
.student-list-box h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 75%;
}
.student-mark-list {
  max-height: calc(100vh - 265px);
  overflow: hidden auto;
  padding: 5px 10px;
}
@media (max-width: 768px) {
  .student-mark-list {
    max-height: calc(100vh - 285px);
  }
}
@media (max-width: 588px) {
  .student-mark-list {
    max-height: calc(100vh - 315px);
  }
}
.student-list-box span {
  font-size: 12px;
  opacity: 0.7;
}
.student-list-box h4 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  margin: 5px 0;
}

.project-list span.total-student {
  text-wrap: nowrap;
  color: #ea5455;
  font-weight: 600;
  opacity: 1;
  color: #ea5455;
  font-weight: 600;
  opacity: 1;
  background: #fff;
  padding: 4px 20px 3px;
  border-radius: 3px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.active.project-list {
  background: #ea545533;
}
.project-date {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 400;
}
.project-list > div:first-child {
  padding-right: 30px;
  max-width: 80%;
}
.marks .ant-form-item-explain-error {
  position: absolute;
  left: -84px;
  font-size: 10px;
  width: 150px;
  text-align: right;
}
.exam-answer-sheet .marks .ant-form-item-explain-error {
  left: -44px;
  text-align: center;
}
.ant-form-item.field-name.add-new-upload {
  margin-bottom: 0;
}
.add-admission-upload .ant-input {
  margin-bottom: 15px !important;
}
.pay-amount {
  font-weight: bold;
}
@media (max-width: 575px) {
  .faculty-dashboard-card .ant-card-body {
    padding: 15px;
  }
  .faculty-dashboard-card table .ant-empty-normal {
    height: 165px;
  }
  .faculty-dashboard-card.total-card .ant-card-body,
  .faculty-dashboard-card.total-card .ant-card-body .card-body {
    padding: 15px;
  }
}
@media (min-width: 992px) and (max-width: 1010px) {
  .faculty-dashboard-card table .ant-empty-normal {
    height: 175px;
    margin: 0;
  }
}
@media (min-width: 768px) and (max-width: 790px) {
  .faculty-dashboard-card table .ant-empty-normal {
    height: 175px;
    margin: 0;
  }
}
@media (max-width: 1440px) {
  .exam-answer-sheet .marks .ant-form-item-explain-error {
    left: -15px;
    font-size: 9px;
    width: 95px;
  }
}
.view-remarks {
  display: flex;
  align-items: baseline;
}

.view-remarks p {
  margin: 0;
}
.payment-details .ant-table-tbody > tr > td span.receiptNo {
  white-space: pre-wrap;
  display: inline-block;
  width: 207px;
}
.parent-details .ant-drawer-body span,
.parent-details .ant-card-body span {
  word-wrap: break-word;
  display: inline-block;
  max-width: 100%;
}
.upload-images .add-new-upload .ant-input {
  padding-right: 40px;
}
.upload-images .ant-divider-inner-text {
  max-width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
}
.upload-images .custom-label-com {
  width: 75%;
  overflow: hidden;
}
.msg:empty {
  display: none;
}
.drawerDisable span u {
  text-decoration: none;
  opacity: 0.7;
  cursor: no-drop;
}
.ant-input-number-input#gr_id_gr_id {
  height: 43px;
}
.student-ptm-report-span {
  font-size: 18px;
  font-weight: 500;
  color: #3c3c3c;
}

.student-ptm-report-span span {
  color: #ea5455;
}
.course-details ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.course-details ul li {
  flex: 1 1 48%;
  padding: 6px 8px;
  margin: 3px;
  border-radius: 3px;
}

.course-details ul.running-course li {
  background: #ffd07a38;
  color: #938039;
}
.course-details ul.completed-course li {
  background: #3993591f;
  color: #399359;
}

.no-uploaded-btn .ant-input-suffix {
  display: none;
}

.no-uploaded-btn .ant-input-affix-wrapper {
  position: unset;
  height: auto;
  transition: unset;
}
.no-uploaded-btn
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input {
  background-color: #fffbfb99;
  border-color: #ffcdcd !important;
}
.no-uploaded-btn .ant-input:focus {
  border: 1px solid #ffcdcd !important;
}
.faculty-name {
  opacity: 0.7;
}
.ant-dropdown-trigger.gx-icon-btn.icon.icon-ellipse-v {
  margin: auto;
}
.ant-dropdown-menu-item:has(> .ant-dropdown-menu-title-content:empty) {
  padding: 0;
}
.cheque-status {
  width: 70px;
  display: inline-block;
  padding: 2px 5px;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
}
.bounced-cheque {
  background: #dd0d0d;
}
.cancelled-cheque {
  background: #fc544b;
}
.collected-cheque {
  background: #3abaf4;
}
.deposited-cheque {
  background: #ffa426;
}
.cleared-cheque {
  background: #44a789;
}
.attendence-table table .ant-form-item {
  margin-bottom: 0px;
}
table .ant-dropdown-menu li.ant-dropdown-menu-item {
  padding: 0;
}
table
  .ant-dropdown-menu
  li.ant-dropdown-menu-item
  .ant-dropdown-menu-title-content {
  height: auto;
  line-height: 1;
}
table
  .ant-dropdown-menu
  li.ant-dropdown-menu-item
  .ant-dropdown-menu-title-content
  button {
  width: 100%;
  text-align: left;
  padding: 5px 15px;
  background: transparent;
  border-radius: 0;
}
table
  .ant-dropdown-menu
  li.ant-dropdown-menu-item
  .ant-dropdown-menu-title-content
  button:hover {
  background: #ea545514;
}
.ant-table-thead th.ant-table-column-has-sorters:hover::before {
  background-color: rgb(245 170 170) !important;
}
.transfer-addmission .ant-btn {
  height: 30px;
  width: 40px;
  text-align: center;
  padding: 0;
}

.signingsheet-card .ant-card-body {
  overflow: auto;
}
.ant-btn-ghost.btn_assign {
  color: #545454;
  border-color: #d9d9d9;
  height: 30px;
  background: #fff;
}
.signin-sheet-table .ant-checkbox-wrapper {
  padding-left: 0 !important;
}
.batch-signing .ant-badge:not(:last-child) {
  margin-right: 0px;
}
.batch-signing .present-badge,
.batch-signing .absent-badge {
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  border: none;
  height: 34px;
  line-height: 19px;
  padding: 8px 40px;
}
.batch-signing tbody tr td {
  padding: 3px 18px;
}
@media (max-width: 1680px) {
  .batch-signing tbody tr td {
    padding: 3px 6px;
  }
}

.semi-table tr td span span .ant-scroll-number {
  font-size: 15px;
  padding: 0;
  font-weight: 500;
  line-height: 24px;
  min-width: 25px;
  height: 25px;
  border-radius: 19px;
}
.batches-status-option ul li button {
  padding: 5px 12px !important;
}
.loader {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #ffffff69;
  z-index: 999;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader .ant-spin-dot {
  width: 3em;
  height: 3em;
}
.loader .ant-spin-dot-item {
  width: 29px;
  height: 29px;
  background-color: #ea5455;
}

.ant-message:has(.CRM-message) {
  top: 50%;
  left: 50%;
  width: auto;
  transform: translate(-50%, -50%);
}
.permission-info {
  min-height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
  overflow: auto;
  margin-bottom: 20px;
  background: transparent;
}

div:has(> .ant-message .CRM-message) {
  position: relative;
}
.csv-btn {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 175px;
}
@media (max-width: 575px) {
  table
    .ant-dropdown-menu
    li.ant-dropdown-menu-item
    .ant-dropdown-menu-title-content
    button {
    padding: 5px 10px;
  }
  .permission-info {
    min-height: calc(100vh - 240px);
    max-height: calc(100vh - 240px);
  }
}
div:has(> .ant-message .CRM-message):before {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: 999;
  opacity: 0.3;
}
div:has(> .ant-message div:empty)::before {
  display: none;
}
.ant-message-notice-content:has(.CRM-message) {
  padding: 30px 36px 40px;
  width: 305px;
  height: 142px;
}
@media (max-width: 575px) {
  .user-button {
    margin-right: -5px;
  }
}
.CRM-message .anticon.anticon-exclamation-circle,
.CRM-message .anticon.anticon-check-circle {
  margin: 0;
}
.CRM-message .anticon.anticon-exclamation-circle svg,
.CRM-message .anticon.anticon-check-circle svg {
  font-size: 40px;
  margin-bottom: 10px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .more-options .ant-popover {
    left: 40px !important;
  }
  .permission-info {
    min-height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .permission-info {
    min-height: calc(100vh - 210px);
    max-height: calc(100vh - 210px);
  }
}

.view-topic-scroll .ant-checkbox + span {
  display: flex;
}
.draggable-item .ant-checkbox + span h4 {
  display: flex;
}
.draggable-item .ant-checkbox + span h4 ul {
  padding-left: 15px;
}
.view-topic-scroll .ant-checkbox + span > span ul {
  padding-left: 15px;
}
.sub-table {
  position: relative;
}
.sub-table .anticon.anticon-reload {
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(270deg);
  right: 10px;
}
.mobile-wp-icon i {
  font-size: 12px;
  color: #fff;
}
.mobileNumber small {
  font-size: 12.5px;
  line-height: 12px;
  margin-right: 5px;
}
.mobile-wp-icon a {
  height: 18px;
  text-align: center;
  line-height: 18px;
  width: 18px;
  background: #ea5455;
  color: #fff;
  display: inline-block;
  border-radius: 3px;
}
.tableEmail {
  text-transform: lowercase;
}

.absent-present td:has(.sub-table) {
  padding: 0;
  border: none;
}
.ant-drawer-body .drawer-body-scroll .ant-form-item-explain-error {
  text-align: right;
}
.ConsessionAmount {
  background: #ea54551a;
  color: #a32c2d;
  padding: 11px 15px;
  display: flex;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 15px;
}
.subCourseTooltip {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 575px) {
  .more-options .ant-popover {
    left: 65px !important;
  }
}
.batch-week-days
  div.ant-col.ant-col-lg-6
  .ant-col.ant-form-item-control
  > div:has(> .ant-form-item-explain) {
  display: none !important;
}
.batch-week-days
  div.ant-col.ant-col-lg-6:nth-child(5)
  .ant-col.ant-form-item-control
  > div:has(> .ant-form-item-explain) {
  position: relative;
  width: 200px;
  margin: 3px 0 0px;
  display: block !important;
}
.admission-card .avatar-img-box .ant-image-mask-info .anticon {
  margin: 7px;
}
.avatar-box .ant-avatar-image .ant-image-mask-info .anticon {
  margin: 7px;
}
.subcourse-title h2 {
  word-wrap: break-word;
  display: inline-block;
  max-width: 100%;
}
.addNewTopic .ant-form-item {
  margin-bottom: 0;
}
.addNewTopic .ant-btn {
  height: 40px;
  width: 100%;
}
@media (max-width: 767px) {
  .addNewTopic .ant-btn {
    width: auto;
  }
  .addNewTopic .ant-form-item {
    margin-bottom: 15px;
  }
  .addNewTopic .ant-form-item:has(.ant-btn) {
    margin-bottom: 0;
  }
}
.add-addmission-form .ant-form-item-explain-error {
  text-align: right;
}
.add-addmission-form
  .ant-form-item-control-input:has(> div .ant-radio-group)
  + div
  .ant-form-item-explain
  .ant-form-item-explain-error {
  text-align: left;
}
.add-addmission-form .ant-form-item-explain-error {
  text-align: right;
}
.examPortalIcon {
  color: #ea5455;
  font-size: 14px;
  margin-left: 4px;
}
.student-report-table {
  max-width: 800px;
  margin: auto;
  background: #fff;
  padding: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
@media print {
  html,
  body {
    padding: 20px;
  }
}
.student-report-table td .radio-label {
  font-weight: 500;
  position: relative;
}
.behaviour thead tr {
  border-bottom: 1px solid #545454b0;
}
.behaviour thead tr th {
  width: 33%;
}
.behaviour thead tr th:nth-child(2) {
  border-width: 0 1px;
  border-style: solid;
  border-color: #545454b0;
}
.behaviour tbody td {
  border-right: 1px solid #545454b0;
  text-align: center;
  font-weight: 600;
}
.attendenceTable td:last-child,
.behaviour tbody td:last-child {
  border: none;
}
.attendenceTable td {
  font-weight: 500;
  text-align: center;
  border-right: 1px solid #545454b0;
}
.attendenceTable tr:nth-child(2) td {
  border-top: 1px solid #545454b0;
  border-bottom: 1px solid #545454b0;
}
.attendenceTable tr:last-child td {
  height: 48px;
}
.workSubmission td {
  text-align: center;
  border-right: 1px solid #545454b0;
  border-bottom: 1px solid #545454b0;
  width: 25%;
}
.remarkSpace {
  min-height: 75px;
}
.parentMendatory {
  margin: 15px 0 0;
}
.parentMendatory li {
  margin-top: 15px;
  line-height: 24px;
  font-size: 13px;
  font-weight: 500;
}
.branchInfo td {
  text-align: center;
  border-right: 1px solid #545454b0;
  border-bottom: 1px solid #545454b0;
}
.branchInfo td:last-child,
.workSubmission td:last-child {
  border-right: none;
}
.branchInfo tr:last-child td,
.workSubmission tr:last-child td {
  border-bottom: none;
}
.text-decoration-line-through {
  text-decoration: line-through;
}
@media (min-width: 768px) {
  .rnw-main-content
    .ant-dropdown-menu-title-content
    .ant-menu-item
    .anticon
    + span {
    margin-left: 6px;
  }
}
.make-payment-split {
  position: relative;
  min-width: 120px;
  max-width: 120px;
  margin: auto;
}
.paymentSplit {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 9%;
  color: #ea5455;
  opacity: 0;
  z-index: -99;
  transition: all 0.5s;
}
.make-payment-split:hover .paymentSplit {
  opacity: 1;
  z-index: 99;
}
.rnw-main-content .button {
  margin-left: -1px;
}
.back-icon {
  margin-right: 8px;
  font-size: 12px;
}
.template-drawer .ant-form-item-explain-error {
  position: absolute;
  bottom: 2%;
  right: 0px;
}
.ant-drawer-left .ant-drawer-content-wrapper {
  z-index: 99999;
}
.absent-studentList {
  width: 700px !important;
}
.overlapDrawer {
  z-index: 9999;
}
.course-modification-modal .ant-table-content {
  max-height: calc(100vh - 614px);
  min-height: 200px;
}
.course-modification-modal-installment .ant-table-content {
  max-height: 192px;
}
@media (min-width: 1400px) {
  .rnw-main-content .config-icon {
    margin-left: 2px;
    margin-right: -1px;
  }
  .rnw-main-content .button {
    margin-left: -1px;
  }
}
@media (max-width: 400px) {
  .rnw-main-content .button {
    margin-left: -2px;
  }
}
@media (max-width: 575px) {
  .rnw-main-content .config-icon {
    margin-left: -3px;
    margin-right: 0px;
  }
  .paymentSplit {
    opacity: 1 !important;
    z-index: 99 !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .rnw-main-content .config-icon {
    margin-left: -4px;
    margin-right: -1px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .rnw-main-content .config-icon {
    margin-left: 1px;
    margin-right: -1px;
  }
  .course-modification-modal-installment .ant-table-content {
    max-height: calc(100vh - 636px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .rnw-main-content .config-icon {
    margin-left: 1px;
    margin-right: -1px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .rnw-main-content .config-icon {
    margin-left: 1px;
    margin-right: -1px;
  }
  .course-modification-modal .ant-table-content {
    max-height: calc(100vh - 620px);
  }
  .course-modification-modal-installment .ant-table-content {
    max-height: calc(100vh - 640px);
  }
}
@media (max-width: 575px) {
  .gx-popover-horizantal {
    top: 61px !important;
  }
}
@media (max-width: 575px) {
  .view-addmission.missing-addmission .table-bg {
    max-height: calc(100vh - 240px);
    min-height: calc(100vh - 240px);
  }
  .view-admission .rnw-page-title {
    font-size: 15px;
    font-weight: 500;
  }
  .ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
    margin-bottom: 3px;
  }
  .remark-admission .ant-drawer-body table .ant-empty-normal {
    height: calc(100vh - 630px);
  }
}
@media (max-width: 575px) {
  .rnw-card.absent-present .ant-table-content {
    max-height: calc(100vh - 315px) !important;
    min-height: calc(100vh - 315px) !important;
  }
  .rnw-card.absent-present.student-completed .ant-table-content {
    max-height: 350px !important;
    min-height: 350px !important;
  }

  .rnw-card.absent-present.student-completed
    .ant-table-content
    .ant-empty.ant-empty-normal {
    height: 180px;
  }
  .view-more-heading,
  .signingSheet-gr {
    font-size: 12px;
  }
  .back-icon {
    margin-right: 0px;
  }
  .signIn-sheet-table {
    height: calc(100vh - 215px);
  }
  .signIn-sheet-table .sticky-top {
    z-index: 999;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 34px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .rnw-card.absent-present.student-completed
    .ant-table-content
    .ant-empty.ant-empty-normal {
    max-height: calc(100vh - 415px) !important;
    min-height: calc(100vh - 415px) !important;
  }
  .rnw-card.absent-present .ant-table-content {
    max-height: calc(100vh - 500px) !important;
    min-height: calc(100vh - 500px) !important;
  }
  .rnw-page-title {
    font-size: 16px;
  }
  .signIn-sheet-table .sticky-top {
    z-index: 999;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 40px;
  }
  .course-modification-modal .ant-table-content {
    max-height: calc(100vh - 635px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .rnw-card.absent-present .ant-table-content {
    max-height: calc(100vh - 547px) !important;
    min-height: calc(100vh - 547px) !important;
  }
  .signingsheet-card .ant-card-body > div,
  .faculty-signing-table .ant-card-body > div {
    max-height: calc(100vh - 238px);
  }
  .course-modification-modal-installment .ant-table-content {
    max-height: calc(100vh - 631px);
  }
  .rnw-card.absent-present.student-completed
    .ant-table-content
    .ant-empty.ant-empty-normal {
    max-height: calc(100vh - 450px) !important;
    min-height: calc(100vh - 450px) !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .rnw-card.absent-present .ant-table-content {
    max-height: calc(100vh - 533px) !important;
    min-height: calc(100vh - 533px) !important;
  }
  .signingsheet-card .ant-card-body > div,
  .faculty-signing-table .ant-card-body > div {
    max-height: calc(100vh - 224px);
  }
  .rnw-card.absent-present.student-completed
    .ant-table-content
    .ant-empty.ant-empty-normal {
    max-height: calc(100vh - 410px) !important;
    min-height: calc(100vh - 410px) !important;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 38px;
  }
  .course-modification-modal-installment .ant-table-content {
    max-height: calc(100vh - 640px);
  }
  .course-modification-modal .ant-table-content {
    max-height: calc(100vh - 640px);
  }
}
.attendance-table .ant-empty.ant-empty-normal {
  height: calc(100vh - 880px);
}
.user-popover .ant-popover {
  z-index: 99;
}
.add-remove-user .ant-btn-icon-only.ant-btn-lg {
  height: 43px;
}
.permission-info .ant-checkbox-inner::after {
  top: 42.5%;
}
.batch-week-days .ant-checkbox-inner::after {
  top: 44.5%;
}
.subcourse-description ul {
  padding-left: 0px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  z-index: 9 !important;
}
.course-modification-modal .ant-form-item {
  margin-bottom: 0px;
}
.absent-card-body p {
  margin-bottom: 4px;
  font-size: 12px;
  opacity: 0.6;
}
.studentAttendenceRemark .ql-toolbar.ql-snow {
  display: none;
}
.studentAttendenceRemark .ql-container.ql-snow {
  border-radius: 6px;
  border: 1px solid #ffcdcd !important;
  overflow: hidden;
}
.studentAttendenceRemark .quill .ql-editor {
  background-color: #fffbfb99;
  border-color: #ffcdcd !important;
  box-shadow: none !important;
  height: 43px;
  min-height: unset;
  padding: 7.9px 11px;
  line-height: 1.3;
}
.absent-card-body .ant-avatar {
  background: #ea5455;
}
.absent-card-body .profile-detail b {
  font-weight: 600;
}
#basic_gender .ant-radio-checked:after {
  display: none !important;
}
.ant-modal-wrap:has(.remark-message) {
  z-index: 99999;
}
.card-header-main {
  max-height: 35px;
}
.crm-history-list-main {
  padding: 0;
}
.crm-history-list-main li {
  padding: 3px 0;
}
.lead-history {
  margin-top: 30px;
}
.crm-history-table table td {
  vertical-align: top !important;
}
@media (min-width: 576px) and (max-width: 767px) {
  .rnw-card.absent-present .ant-table-content {
    max-height: calc(100vh - 286px) !important;
    min-height: calc(100vh - 286px) !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .rnw-card.absent-present .ant-table-content {
    max-height: calc(100vh - 305px) !important;
    min-height: calc(100vh - 305px) !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .rnw-card.absent-present .ant-table-content {
    max-height: calc(100vh - 260px) !important;
    min-height: calc(100vh - 260px) !important;
  }
}
.present-count {
  color: #fff;
  display: inline-block;
  padding: 3px 0px;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  background: #186587;
  font-weight: 500;
  cursor: pointer;
}
.add-admission-document
  .Upload_Btn
  .doubleError.false
  .ant-form-item-control-input
  + div {
  bottom: -23px;
  right: -12px;
}
.present-count {
  color: #fff;
  display: inline-block;
  padding: 3px 0px;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  background: #186587;
  font-weight: 500;
  cursor: pointer;
}
.add-admission-document
  .Upload_Btn
  .doubleError.false
  .ant-form-item-control-input
  + div {
  bottom: -28px;
  right: -12px;
}
.ant-drawer-body table .ant-empty-normal {
  height: calc(100vh - 700px);
  min-height: 57px;
}
.crm-history .ant-drawer-body table .ant-empty-normal {
  height: calc(100vh - 213px);
}
.dashboard-card-main .Outstanding-fees,
.dashboard-card-main .overdue-fees {
  background-color: #e1dfff69 !important;
}
.dashboard-card-main .Outstanding-fees .card-body h5,
.dashboard-card-main .overdue-fees .card-body h5 {
  color: #8379ff !important;
}
.dashboard-card-main .outstanding-collection,
.dashboard-card-main .overdue-collection,
.dashboard-card-main .registration-amount,
.dashboard-card-main .Total-income-month {
  background-color: #ebffee !important;
}
.dashboard-card-main .outstanding-collection .card-body h5,
.dashboard-card-main .overdue-collection .card-body h5,
.dashboard-card-main .registration-amount .card-body h5,
.dashboard-card-main .Total-income-month .card-body h5 {
  color: rgb(84, 202, 104) !important;
}
.dashboard-card-main .expense,
.dashboard-card-main .total-expense-month {
  background-color: #fff4f4 !important;
}
.dashboard-card-main .expense .card-body h5,
.dashboard-card-main .total-expense-month .card-body h5 {
  color: #ea5455 !important;
}
.dashboard-card-main .Ongoing {
  background-color: #f2f9ff !important;
}
.dashboard-card-main .Ongoing .card-body h5 {
  color: rgb(58, 186, 244) !important;
}
.dashboard-card-main .Pending {
  background-color: #fff7ec !important;
}
.dashboard-card-main .Pending .card-body h5 {
  color: rgb(255, 164, 38) !important;
}
.dashboard-card-main .Terminated {
  background-color: #dd72721c !important;
}
.dashboard-card-main .Terminated .card-body h5 {
  color: #ad0909 !important;
}
.dashboard-card-main .Transfer,
.dashboard-card-main .revenue-runnig-month,
.dashboard-card-main .no-of-Admision {
  background-color: #e6fcf5 !important;
}
.dashboard-card-main .Transfer .card-body h5,
.dashboard-card-main .revenue-runnig-month .card-body h5,
.dashboard-card-main .no-of-Admision .card-body h5 {
  color: rgb(68, 167, 137) !important;
}
.dashboard-card-main .Cancelled {
  background-color: #ffe7e6 !important;
}
.dashboard-card-main .Cancelled .card-body h5 {
  color: rgb(252, 84, 75) !important;
}
.dashboard-card-main .Completed {
  background-color: #ebffee !important;
}
.dashboard-card-main .Completed .card-body h5 {
  color: rgb(84, 202, 104) !important;
}
.dashboard-card-main .Ongoing .ant-card-body .card-header {
  border-bottom: 1px solid #d1eaff !important;
}
.dashboard-card-main .Pending .ant-card-body .card-header {
  border-bottom: 1px solid #f8dbb3 !important;
}
.dashboard-card-main .Terminated .ant-card-body .card-header {
  border-bottom: 1px solid #ad090930 !important;
}
.dashboard-card-main .Transfer .ant-card-body .card-header,
.dashboard-card-main .revenue-runnig-month .ant-card-body .card-header,
.dashboard-card-main .no-of-Admision .ant-card-body .card-header {
  border-bottom: 1px solid #aeedd9 !important;
}
.dashboard-card-main .Cancelled .ant-card-body .card-header {
  border-bottom: 1px solid #ffbebb !important;
}
.dashboard-card-main .Completed .ant-card-body .card-header {
  border-bottom: 1px solid #aaffb7 !important;
}
.dashboard-card-main .Outstanding-fees .ant-card-body .card-header,
.dashboard-card-main .overdue-fees .ant-card-body .card-header {
  border-bottom: 1px solid #8379ff40 !important;
}
.dashboard-card-main .overdue-collection .ant-card-body .card-header,
.dashboard-card-main .outstanding-collection .ant-card-body .card-header,
.dashboard-card-main .registration-amount .ant-card-body .card-header,
.dashboard-card-main .Total-income-month .ant-card-body .card-header {
  border-bottom: 1px solid #aaffb7 !important;
}
.dashboard-card-main .expense .ant-card-body .card-header,
.dashboard-card-main .total-expense-month .ant-card-body .card-header {
  border-bottom: 1px solid #ea545533 !important;
}
.faculty-dropdown
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(
    .ant-select-customize-input
  )
  .ant-select-selection-search-input {
  padding-left: 10px !important;
}
.faculty-dropdown
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  padding-right: 20px !important;
}

.reload-modal {
  width: 400px !important;
}
.reload-modal .ant-btn-default {
  background-color: #ea5455 !important;
  color: #fff !important;
}
.reload-modal .ant-modal-body {
  text-align: center;
}

.ant-message {
  z-index: 9999 !important;
}
@media (min-width: 575px) and (max-width: 1199px) {
  .faculty-dashboard-card .ant-select-show-search {
    width: 95px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .faculty-dashboard-card .ant-select-selector {
    height: 43px !important;
  }
  .faculty-dashboard-card
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector::after,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item,
  .faculty-dashboard-card
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 39px !important ;
  }
}
.ant-modal-confirm-body span {
  justify-content: center;
}
.ant-modal-confirm-body span svg {
  font-size: 25px;
}
.faculty-dashboard-card
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.faculty-dashboard-card
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 39px !important ;
}

.reload-modal .ant-modal-body {
  padding: 17px;
}
.alert-success {
  color: rgb(84, 202, 104);
}
.alert-pending {
  color: #ffa426;
}
.alert-ongoing {
  color: #ea5455;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 99 !important;
}
.crm-history .ant-table-content {
  max-height: calc(100vh - 90px);
  min-height: 195px;
}
.ant-menu-title-content .nav-link {
  display: flex;
  align-items: center;
}

.ant-message {
  z-index: 999999 !important;
}
.drawer-form table .ant-empty-normal {
  height: calc(100vh - 785px);
  min-height: 177px;
}
.bulk-remark-editer .ql-toolbar.ql-snow .ql-formats {
  margin-right: 11px !important;
}
.company-visit-card .table-bg .ant-empty-normal {
  height: calc(100vh - 785px);
}
.inactive-status {
  font-style: italic;
  color: #8f8f8f90;
}
.course_error {
  margin-top: -12px;
  font-size: 12px;
  color: #ea5455;
}
.branch-code {
  display: grid;
}
.zone_code {
  font-size: 12px;
}
@media (max-width: 576px) {
  .no-radio .anticon-check svg {
    width: 15px;
  }
  .rnw-card.absent-present .ant-table-content {
    max-height: calc(100vh - 289px) !important;
    min-height: calc(100vh - 389px) !important;
  }
}

// .dueListRow:has(.dueDateRow) {
//   background: #fff4f4 !important;
// }
.ptmListRow:has(.report-row) {
  background: #ffd07a38 !important;
}
.ptmListRow:has(.exceed-due-date-row) {
  background: #ff7a7a61 !important;
}
.ptmListRow:has(.draft-created) {
  background: #fbda3161 !important;
}
.attention-button
  .ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin: 0px 30px !important;
}
.more-days {
  display: inherit;
  text-align: right;
  color: #ea5455;
  cursor: pointer;
  font-size: 14px;
  margin: -2px 0px 2px 0px;
  padding: 0 10px;
}
.more-days-closeBtn {
  text-align: right;
  padding: 0 6px;
  margin-bottom: 15px !important;
}
.more-days-closeBtn .anticon-close {
  border: 1px solid red;
  border-radius: 50%;
  padding: 3px;
  background: #ea5455;
  color: #fff;
  font-size: 14px;
}
.attendence-table .disabled-input {
  margin: 0 6px;
}
.holiday-card .card-body table thead tr th.sticky-top {
  z-index: 99;
}
.holiday-card .card-body {
  height: 620px;
}
.holiday-form .ant-picker .ant-picker-large .ant-picker-focused {
  width: 100% !important;
}
.holiday-form .float-label {
  width: 100% !important;
}
.more-days {
  margin-bottom: 15px !important;
  padding: 0px !important;
}
.holiday-card .card-body table tr td {
  padding: 5px 10px !important;
}
.holiday-card .ant-form-item {
  margin: 5px 0px !important;
}

.course_error {
  margin-top: -5px;
  margin-left: 6px;
}
.project-type {
  font-size: 14px;
  margin-left: 10px;
  color: #ff8586;
}

.holiday-exits-card .ant-form-item {
  max-height: 40px;
  min-height: 40px;
  overflow: auto;
}
.modal-inputs {
  flex-wrap: wrap;
}
.filter-icon-btn {
  height: 36px !important;
}
@media (max-width: 576px) {
  .ant-pagination.ant-pagination-mini .ant-pagination-total-text,
  .ant-pagination.ant-pagination-mini .ant-pagination-simple-pager,
  .counsellor-page .ant-pagination-total-text,
  .counsellor-page .ant-pagination-simple-pager {
    height: 24px;
    line-height: 24px;
    width: 100% !important;
  }
  .faculty-dashboard-card.total-card {
    height: 115px;
  }
}
@media (max-width: 575px) {
  .faculty-dashboard-card .ant-select-show-search {
    width: 83% !important;
  }
  .card-header .ant-picker {
    height: 35px;
  }
  .faculty-dashboard-card .ant-select-show-search {
    width: 100% !important;
    max-width: 80px;
    min-width: 100px;
  }
}
.ant-picker-panel-container {
  overflow: auto !important;
  min-width: 300px !important;
  max-width: 510px !important;
  margin: 0px 5px !important;
}
@media (max-width: 991px) {
  .dashboard-popover
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 41px;
  }
}
.faculty-signing tbody tr.sub-topics td {
  opacity: 0.9;
  font-weight: 400;
}
.faculty-signing-table {
  height: calc(100vh - 240px);
}
.holiday-exits-card .ant-form-item {
  max-height: 84px;
  min-height: 24px;
  overflow: auto;
}
.update-button {
  width: inherit !important;
}
@media (max-width: 575px) {
  .student-attendence .ant-btn.btn-cancel,
  .student-attendence .ant-btn[type="submit"] {
    width: initial !important;
  }
}
.holiday-card .card-body {
  height: calc(100vh - 496px);
  min-height: 150px;
}
.holiday-branch:last-child {
  word-break: break-all;
}
.holiday-button button {
  margin-top: 0px !important;
}
.update-button .ant-btn:not([disabled]):not(.disabled) {
  margin-bottom: 0px !important;
}
@media (max-width: 575px) {
  .student-attendence .ant-btn:not([disabled]):not(.disabled) {
    margin-bottom: 0px !important;
  }

  .gx-text-right {
    text-align: left !important;
  }
  .dash-board-filter .ant-select-selection-placeholder {
    line-height: 41px !important;
  }
}
.ant-picker-panel-container {
  overflow: auto !important;
  min-width: 260px !important;
  max-width: 510px !important;
  margin: 0px 5px !important;
}
.dashboard-card-main {
  margin-bottom: 20px;
}
.overall-cards {
  height: 130px;
}
@media (min-width: 1199px) and (max-width: 1440px) {
  .overall-cards,
  .student-today-card {
    height: 120px;
  }
}
.dash-board-filter .ant-select-selection-placeholder {
  color: #818181 !important;
  font-weight: 300;
  font-size: 15px;
}
.ant-form label.as-placeholder {
  color: #818181 !important;
  font-weight: 300;
}
.dashboard-today-card {
  padding: 25px;
}
.overall-card {
  padding: 25px;
}
.holiday-drawer .ant-drawer-body .ant-col {
  padding-left: 0.25rem !important;
}

@media (max-width: 575px) {
  .holiday-drawer .ant-drawer-body .ant-col {
    padding-left: 0.25rem !important;
  }
  .more-days-closeBtn {
    padding-left: 6px;
    padding-right: 2px;
  }
  .holiday-drawer .anticon svg {
    width: 15px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .holiday-drawer .anticon svg {
    width: 14px;
  }
  .holiday-drawer .ant-drawer-body .ant-col {
    padding-left: 0.25rem !important;
  }
  .more-days-closeBtn {
    padding-left: 6px;
    padding-right: 2px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .holiday-drawer .anticon svg {
    width: 14px;
  }
  .holiday-drawer .ant-drawer-body .ant-col {
    padding-left: 0.25rem !important;
  }
  .more-days-closeBtn {
    padding-left: 6px;
    padding-right: 2px;
  }
}
.shining-sheet-student p,
.shiningsheet-students p {
  margin-bottom: 0px;
}
.change-psw .ant-input-password {
  padding: 0 11px;
}
.change-psw input {
  border: 0 !important;
  padding: 0 !important;
}

.total-stud {
  white-space: nowrap;
}
.branch-selected
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0px 28px 0 10px;
}
.ant-tooltip {
  z-index: 999;
}
.ant-dropdown {
  z-index: 9 !important;
}
.rnw-main-content .ant-popover {
  z-index: 9 !important;
}
.ant-layout-header {
  z-index: 99 !important;
}
.ant-table-content thead th {
  z-index: 999 !important;
}
.dis_sort .ant-select-selection-item {
  margin-right: 18px !important;
}
.sub-topics-checkbox .ant-form-item {
  margin: 7px 0;
}
@media (max-width: 390px) {
  .gx-main-content-wrapper {
    padding: 8px 10px 0;
  }
  .view-admission .ant-select-arrow {
    right: 10px;
  }
}
@media (max-width: 640px) {
  .view-admission .ant-select-arrow {
    right: 4px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .dis_sort .ant-select-selector {
    height: 32px !important;
  }
}

.dash_input.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 22px !important;
}
.dash-progressBar {
  margin: 10px 11px;
  padding: 0px 10px 10px 10px;
}
.dash-progressBar .ant-progress-line {
  margin-right: 8px;
  margin-bottom: 8px;
}
.dash-progressBar .ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 22px);
  padding-right: calc(2em + 24px);
}
.dash-progressBar .ant-progress-text {
  width: 3.5em;
  font-size: 0.9em;
  text-align: right;
}
.dash-progressBar .ant-progress-success-bg,
.dash-progressBar .ant-progress-bg {
  background-color: #ff6869;
}
@media screen and (max-width: 991px) {
  .ant-drawer.ant-drawer-right.course-modification.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

@media (max-width: 575px) {
  .course-modification .ant-btn.btn-cancel:not([disabled]):not(.disabled) {
    margin-bottom: 0px;
  }
  .absent-card-body .ant-avatar.ant-avatar-circle.ant-avatar-icon {
    font-size: 40px !important;
  }
}
.profile-detail .mobileNumber {
  flex-wrap: nowrap;
}
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #ea5455;
}
.payment-lable .ant-select-selector {
  height: 43px !important;
  box-shadow: none !important;
  background-color: #fffbfb99 !important;
  border-color: #ffcdcd !important;
}

.holiday-zone {
  opacity: 0.7;
  font-size: 11px;
}
.packages .table-striped .ant-spin-nested-loading {
  z-index: 01;
}
.ant-select-multiple.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector::after {
  line-height: 28px;
}
@media (min-width: 1600px) and (max-width: 1699px) {
  .roles-details p {
    max-width: inherit;
  }
}
.dgread-cancelleat {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
  white-space: nowrap;
}
.dgread-cancelleat > * {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
  white-space: nowrap;
  display: inline-block;
}
@media (max-width: 575px) {
  .holiday-drawer .ant-btn:not([disabled]):not(.disabled) {
    margin-bottom: 0px;
  }
  .dashboard-rang-picker .ant-picker {
    height: 40px;
  }
  .plus-button.ant-btn {
    height: 44px !important;
  }
}
.dashboard-filter-header .ant-select-selector {
  padding-right: inherit !important;
}
@media (max-width: 1400px) {
  .batches-status-option .ant-dropdown-menu {
    max-height: 170px;
    overflow: auto;
  }
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: inherit !important;
}
@media (max-width: 768px) {
  .upcoming-status span,
  .hold-status span,
  .completed-status span,
  .cancelled-status span,
  .ongoing-status span {
    font-size: 12px;
    margin-left: 6px;
  }
  .upcoming-status .ant-btn > .anticon + span,
  .ant-btn > span .anticon {
    margin-left: 4px;
    font-size: 12px;
  }
}
.report-download-error {
  display: flex;
  justify-content: center;
}

.report-download-error span {
  border: 1px solid #ea5455 !important;
  border-radius: 50%;
  padding: 10px;
}
.report-download-error svg {
  font-size: 29px;
  color: #ea5455 !important;
}

.error-modal .ant-modal-footer {
  border-top: none !important;
  display: flex;
  justify-content: center;
}
.report-download-error-text {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: inherit !important;
}
.company-visit-card
  .ant-select-multiple.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item {
  margin-bottom: 0px !important;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #fbcbcb !important;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  background-color: #fff;
  border-color: #ffcdcd !important;
}
.report-card .ant-form-item-explain-error {
  margin-bottom: 7px;
}
.report-card .ant-picker-clear {
  right: 0;
}
.error-modal .ant-modal-body {
  padding: 20px 10px 0px 10px;
}

.error-modal .ant-modal-close {
  display: none;
}
.ptm-input .ant-form-item-explain-error {
  margin-bottom: 7px;
}
.ant-form .ant-row > .ant-col.create-user-pl {
  padding-left: 0 !important;
}
@media (max-width: 575px) {
  .ant-drawer-body .user-role-clr .ant-col {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
}

.batch-week-days .ant-select-selector {
  height: 43px !important;
}
.student-report-card .ant-form-item {
  margin-bottom: 15px !important;
}

.report-card-row-box .ant-card-body {
  height: 156px;
}
.behaviour-card .ant-form-item {
  margin-top: 12px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .remark-button .ant-btn {
    padding: 10px !important;
  }
}
@media print {
  @page {
    margin: 0;
  }
  html,
  body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .student-detail-dashboard {
    overflow: hidden;
  }
  .ant-card-body {
    box-shadow: none !important;
    border-radius: 0px !important;
  }

  .footer-button {
    display: none !important;
  }
  .ant-card {
    background-color: transparent !important;
    width: 100% !important;
    height: 100% !important;
  }
  .branch-selected .ant-select-selector {
    border: none !important;
  }
  .faculty-dashboard-card .table-striped tbody tr:nth-of-type(odd) {
    background-color: white !important;
  }

  .faculty-dashboard-card .ant-card-body {
    padding: 0px;
  }
  .faculty-dashboard-card .card-body {
    margin-right: 20px !important;
  }
  .report-card {
    margin-top: 0px !important;
  }
}
.branches-checkbox {
  text-align: left;
}
.branches-checkbox .ant-row {
  display: flex;
  align-items: center;
}
.branches-card table .ant-empty-normal {
  height: calc(100vh - 445px);
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:active {
  color: #535353 !important;
}
.ant-tabs-tab:hover {
  color: #ea5455 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ea5455 !important;
}
.progress-sheet-btn button:hover {
  border: 1px solid;
}
@media (max-width: 1200px) {
  .make-installment .label.as-placeholder {
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px !important;
  }
  .text-editor .label {
    top: 52px;
  }
}
@media (min-width: 576px) and (max-width: 991px) {
  .text-editor .label {
    top: 50px;
  }

  .create-new-user.ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: inherit !important;
  }
}
@media (max-width: 576px) {
  body,
  html,
  .ant-drawer,
  .ant-drawer-content-wrapper,
  .ant-drawer-content,
  .ant-drawer-body {
    overflow-x: hidden !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  .drawer-form .ant-col-xs-24 {
    width: 100% !important;
    margin-bottom: 6px !important;
  }

  .drawer-form .ant-select,
  .drawer-form .ant-input {
    font-size: 14px;
  }

  .drawer-form .gx-d-flex {
    font-size: 12px;
  }

  .ant-row {
    margin-bottom: 6px !important;
    row-gap: 0px !important;
  }
}
@media (min-width: 576px) and (max-width: 991px) {
  .drawer-form .ant-col-xs-24 {
    width: 100% !important;
    // flex: 0 0 100% !important;
    margin-bottom: 6px !important;
  }

  .drawer-form .ant-select,
  .drawer-form .ant-input {
    font-size: 14px;
  }

  .drawer-form .gx-d-flex {
    font-size: 12px;
  }

  .ant-row {
    margin-bottom: 6px !important;
    row-gap: 0px !important;
  }
}

.ant-drawer-content-wrapper {
  max-width: 100vw !important;
}

.create-new-user.ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right
  .ant-drawer-content-wrapper {
  width: 900px !important ;
}
@media (min-width: 991px) and (max-width: 1900px) {
  .create-new-user.ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 730px !important ;
  }
  .remark-table .as-placeholder {
    top: 50px;
  }

  .add-remove-input .ant-select-selector {
    height: 42px !important;
  }
  .plus-button.ant-btn {
    height: 44px !important;
  }
  .ant-btn .anticon.anticon-plus > svg,
  .ant-btn .anticon.anticon-minus > svg {
    shape-rendering: auto;
  }
  .basic-info .ant-form-item-row {
    align-items: center;
  }
}
.batchname {
  color: black;
  font-weight: 400 !important;
}
.installments-count strong {
  white-space: nowrap;
  margin-top: 8px;
}
.installments-count {
  margin-top: 12px;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .addmission-placeholder .label {
    max-width: 140px;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media (min-width: 1024px) and (max-width: 1024px) {
  .remark-admission .ant-drawer-body table .ant-empty-normal {
    min-height: inherit !important;
  }
  .asign-editer label {
    top: 50px !important;
  }
  .text-editor label {
    top: 50px !important;
  }
  .remark-admission .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0px !important;
  }
}
.note-text {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: clip;
  max-height: 28px;
  line-height: 1.9;
}

.assign-card .ant-card-body .ant-tabs-content-holder {
  max-height: calc(100vh - 563px) !important;
  min-height: calc(100vh - 563px) !important;
  overflow: auto;
}
.lab-portal .ant-tabs-content-holder {
  padding-top: 1px;
}
.Hardware-drawer
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 44px !important;
}
.Hardware-drawer .ant-form-item {
  margin-bottom: 0px !important;
}
.Hardware-drawer .ant-table-cell h6 {
  margin-bottom: 0px !important;
}
.branches-checkbox .ant-form-item-required {
  margin-top: 4px !important;
}
.Hardware-drawer .ant-table-content {
  max-height: calc(100vh - 158px) !important;
  min-height: calc(100vh - 158px) !important;
}

.role-modal {
  max-height: 130px;
  overflow: auto !important;
}
.analysis-input .ant-input-number-input-wrap {
  line-height: 42px;
}

.course-info .ant-progress-inner {
  width: 64px !important;
  height: 58px !important;
}
.course-info .ant-progress-circle .ant-progress-text {
  font-weight: bold;
  font-size: 10px;
  text-align: inherit !important ;
}
.course-info .ant-progress-circle {
  margin-left: 8px !important;
  margin-top: 4px !important;
}
.text-editers .ql-editor {
  padding-top: 18px !important;
}
.note-editer label {
  top: 52px;
}
.note-editer .ql-editor,
.Enter-editer .ql-editor,
.asign-editer .ql-editor {
  padding-top: 24px !important;
}
.Enter-editer label {
  top: 46px;
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .Enter-editer label {
    top: 72px !important;
  }
}
@media (max-width: 575px) {
  .remark-table .as-placeholder {
    top: 70px;
  }
}
@media (min-width: 576px) and (max-width: 1200px) {
  .remark-table .as-placeholder {
    top: 50px;
  }
}
.branches-card .ant-table-content {
  max-height: calc(100vh - 320px) !important;
  min-height: calc(100vh - 320px) !important;
}
.text-editers label {
  top: 45px;
}
@media (max-width: 991px) {
  .text-editers label {
    top: 70px;
  }
}
@media (max-width: 767px) {
  .note-editer label,
  .Enter-editer label {
    top: 71px;
  }
  .Enter-editer label {
    top: 75px;
  }
}
@media (max-width: 575px) {
  .text-editers label {
    top: 80px;
  }
  .note-editer label,
  .Enter-editer label {
    top: 71px;
  }
  .Enter-editer label {
    top: 75px !important;
  }
}
@media (max-width: 500px) {
  .text-editers label {
    top: 90px;
  }
}
.add-remove-input .ant-select-selector {
  height: 42px !important;
}

@media (min-width: 767px) and (max-width: 1400px) {
  .note-editer .ql-editor,
  .Enter-editer .ql-editor {
    padding-top: 18px !important;
  }
}
.report-card .ant-table-tbody > tr > td {
  padding: 15px 16px !important;
}
.branch-card .ant-table-content {
  height: 560px;
  overflow: auto;
}
.summary-row td {
  text-align: center;
}
.summary-row .ant-statistic {
  display: flex;
  align-items: center;
  margin-top: 18px;
  justify-content: center;
}
.summary-row .ant-statistic-content {
  font-size: 18px !important;
  font-weight: 400;
}
.summary-row .ant-table-thead > tr > th {
  background: #ea5455 !important;
  color: #fff !important;
}
.summary-row .ant-badge-status-processing {
  margin-right: 8px !important;
  margin-left: 0px !important;

  background-color: #ea5455 !important;
}
.summary-row .ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  border: 1px solid #ea5455 !important;
}
.summary-row .ptmListRow .ant-table-cell {
  text-align: left;
}
.Hardware-drawer .ant-select-dropdown {
  z-index: 9;
}
.range label {
  margin-top: 6px;
  padding-right: 0px !important;
}
.branch-summary h5 {
  white-space: nowrap !important;
}
.admission-fillter .ant-btn {
  height: 42px !important;
}
.admission-fillter .ant-select-in-form-item .ant-select-selector {
  padding-left: 7px !important;
}
.branch-summary .ant-card {
  border: 1px solid #ffcdcd;
}
@media (min-width: 1600px) and (max-width: 2000px) {
  .branch-summary {
    max-width: max-content !important;
  }
  .branch-summary .ant-card {
    padding: 15px 15px !important;
  }
}
.lab-card {
  max-height: calc(100vh - 150px) !important;
  min-height: calc(100vh - 150px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.lab-card .rnw-card.transfer-addmission .ant-empty-normal {
  max-height: calc(100vh - 473px) !important;
}
.lab-card .rnw-card.transfer-addmission .ant-table-content {
  max-height: calc(100vh - 350px) !important;
  min-height: calc(100vh - 350px) !important;
}
@media (max-width: 1024px) {
  .lab-card {
    max-height: calc(100vh - 166px) !important;
    min-height: calc(100vh - 166px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .lab-card .rnw-card.transfer-addmission .ant-table-content {
    max-height: calc(100vh - 190px) !important;
    min-height: calc(100vh - 190px) !important;
  }
  .assign-card .ant-card-body .ant-tabs-content-holder {
    max-height: calc(100vh - 426px) !important;
    min-height: calc(100vh - 426px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .lab-card .rnw-card.transfer-addmission .ant-empty-normal {
    max-height: calc(100vh - 373px) !important;
  }
}
@media (max-width: 991px) {
  .lab-card {
    max-height: calc(100vh - 180px) !important;
    min-height: calc(100vh - 180px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .lab-card .rnw-card.transfer-addmission .ant-table-content {
    max-height: calc(100vh - 410px) !important;
    min-height: calc(100vh - 410px) !important;
  }
  .lab-card .rnw-card.transfer-addmission .ant-empty-normal {
    max-height: calc(100vh - 531px) !important;
  }
  .assign-card .ant-card-body .ant-tabs-content-holder {
    max-height: calc(100vh - 591px) !important;
    min-height: calc(100vh - 591px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.add-addmission input::placeholder {
  color: transparent !important;
}
.project-analysis .faculty-dashboard-card .card-body,
.topic-analysis .faculty-dashboard-card .card-body {
  height: 260px;
  overflow: auto;
}
.project-analysis .faculty-dashboard-card .card-body .ant-table-content,
.topic-analysis .faculty-dashboard-card .card-body .ant-table-content {
  height: 260px;
}
.range-input-main .ant-row {
  width: 100%;
}
.counsellor-select-input .ant-form-item {
  margin-bottom: 0;
}
.pie-chart-container.summary-row {
  height: 100%;
}
.pie-chart-container.summary-row .ant-card-body {
  height: 439px;
  max-height: 439px;
  padding-bottom: 15px;
}

.user-badge .ant-badge-status-dot {
  width: 9px;
  height: 9px;
}

.lab-report-input {
  justify-content: end;
}

@media (max-width: 575px) {
  .lab-report-input {
    justify-content: start;
    flex-wrap: wrap;
  }

  .lab-report-input .lab-report-input-inner {
    margin-bottom: 6px;
  }
}
.completed-status-tag span {
  color: #44a789 !important;
}
.completed-status-tag:hover span {
  color: #ea5455 !important;
}
.amt-span {
  font-size: 13px;
  font-weight: 500;
}
.missing-admission-card .ant-table-content {
  max-height: 470px;
  overflow: auto;
}
.lab-report-input {
  justify-content: end;
}

@media (max-width: 575px) {
  .lab-report-input {
    flex-wrap: wrap;
    justify-content: start;
  }

  .lab-report-input .lab-report-input-inner {
    margin-bottom: 6px;
  }
}
.student-list-gr-id form {
  margin-bottom: 10px;
}

.batches-status-menu .hold-status-tag span,
.hold-status-tag i {
  margin-left: 6px;
  color: #9852a5;
}
.hold-status-tag:hover span,
.hold-status-tag:hover i {
  color: #ea5455 !important;
}

.hold-date-model .ant-table-content {
  max-height: 220px;
  min-height: 180px;
  overflow: auto;
}
.hold-date-model .ant-table.ant-table-empty .ant-table-content {
  max-height: 200px;
  overflow: auto;
}

.hold-date-model .ant-empty.ant-empty-normal {
  height: auto;
}

.hold-date-model .ant-popover {
  width: 300px;
}

.hold-date-model .roles-details p {
  white-space: inherit;
}
.gr-id-mobile {
  margin-top: 10px !important;
}
.missing-admission-analysis .ant-table-content {
  height: calc(100vh - 198px);
}

.topic-analysis .ant-table-content,
.topic-analysis .faculty-dashboard-card .card-body {
  height: 248px !important;
}
.faculty-available-slot-container {
  max-height: calc(100vh - 230px);
}

@media (max-width: 575px) {
  .faculty-available-slot-container {
    max-height: calc(100vh - 260px);
  }
}

@media print {
  .back-icon {
    margin-right: 0px;
  }
}

@media screen and (max-width: 501px) {
  .branches-card .ant-table-content {
    max-height: calc(100vh - 280px) !important;
    min-height: calc(100vh - 320px) !important;
  }
}
.hold-popover {
  height: 100px;
  overflow: auto !important;
}
.gx-flex-wrap {
  flex-wrap: wrap !important;
}

.gx-flex-nowrap {
  flex-wrap: nowrap !important;
}

.gx-gap-3 {
  gap: 8px;
}

.available-pcs-btn {
  height: 40px !important;
  padding: 0 9px !important;
}

.receipt-card {
  display: flex;
  justify-content: end;
}

@media (max-width: 690px) {
  .table-layout {
    overflow: auto;
  }

  .receipt-card {
    justify-content: start;
  }
}

@media (max-width: 690px) {
  .table-layout .img-thumbnail {
    height: auto;
  }
}
@media (max-width: 1200px) {
  .missing-admission-analysis .ant-table-content {
    height: calc(100vh - 230px);
  }
}
@media (max-width: 575px) {
  .missing-admission-analysis .ant-table-content {
    height: calc(100vh - 300px);
  }
}
.select-zone-input {
  width: 200px;
}
@media print {
  body {
    background-color: #fffffe;
  }

  @page {
    size: A4;
  }

  .back-button {
    display: none;
  }

  .ant-card-body {
    padding: 0;
  }

  .ant-layout-sider,
  .ant-layout-header {
    display: none;
  }

  .faculty-dashboard-card .card-body table tr td,
  .faculty-dashboard-card .card-body table tr th {
    padding: 8px !important;
  }
}

.crm-lead-info-ul {
  padding: 0;
  list-style-type: none;
}

.crm-lead-info-li {
  margin-bottom: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 8px;
}

.crm-lead-info-font {
  font-weight: bold;
}
@media (min-width: 991px) and (max-width: 1900px) {
  .user-config-table .label.as-placeholder {
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px !important;
  }
}
@media (max-width: 1599px) {
  .user-config-table .user-config-select {
    border-bottom: 2px solid #fef6f6;
  }
}
.overflow-wrap-break-word {
  overflow-wrap: break-word;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .faculty-dashboard-card .card-header .ant-btn {
    height: 40px;
  }
}
@media (min-width: 820px) and (max-width: 912px) {
  .faculty-dashboard-card .card-header .ant-btn .anticon.anticon-plus > svg {
    shape-rendering: auto;
  }
}
@media (width: 1280px) {
  .project-analysis .faculty-dashboard-card .card-body,
  .topic-analysis .faculty-dashboard-card .card-body {
    max-height: calc(100vh - 580px) !important;
    min-height: calc(100vh - 580px) !important;
    overflow: auto;
  }
  .project-analysis .faculty-dashboard-card .card-body .ant-table-content,
  .topic-analysis .faculty-dashboard-card .card-body .ant-table-content {
    max-height: calc(100vh - 585px) !important;
    min-height: calc(100vh - 585px) !important;
  }
  .project-analysis .faculty-dashboard-card table .ant-empty-normal,
  .topic-analysis .faculty-dashboard-card table .ant-empty-normal {
    max-height: calc(100vh - 655px) !important;
    min-height: calc(100vh - 655px) !important;
    margin: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .project-analysis .ant-btn,
  .topic-analysis .ant-btn {
    height: 40px;
  }
}
@media (max-width: 575px) {
  .project-analysis .ant-btn,
  .topic-analysis .ant-btn {
    height: 38px;
    padding: 0 11px;
  }
}

.subtopic-text {
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
  text-overflow: clip;
  max-height: 15px;
  line-height: 1.9;
}
@media (min-width: 575px) and (max-width: 1199px) {
  .missing-admission-analysis .lab-report-input {
    margin-left: 33px;
  }
}

div.ql-toolbar.ql-snow {
  padding-bottom: 5px;
}
.subtopic-text ul {
  padding-left: 16px;
}

@media (max-width: 575px) {
  .missing-admission-card .ant-table-content {
    max-height: calc(100vh - 380px) !important;
    min-height: calc(100vh - 380px) !important;
    overflow: auto;
  }
  .missing-admission-card .ant-empty-normal {
    max-height: calc(100vh - 510px) !important;
    min-height: calc(100vh - 510px) !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .missing-admission-card .ant-table-content {
    max-height: calc(100vh - 635px) !important;
    min-height: calc(100vh - 635px) !important;
    overflow: auto;
  }
  .missing-admission-card .ant-empty-normal {
    max-height: calc(100vh - 760px) !important;
    min-height: calc(100vh - 760px) !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .missing-admission-card .ant-table-content {
    max-height: calc(100vh - 535px) !important;
    min-height: calc(100vh - 535px) !important;
    overflow: auto;
  }
  .missing-admission-card .ant-empty-normal {
    max-height: calc(100vh - 660px) !important;
    min-height: calc(100vh - 660px) !important;
  }
}
@media (width: 1024px) {
  .missing-admission-card .ant-table-content {
    max-height: calc(100vh - 470px) !important;
    min-height: calc(100vh - 470px) !important;
    overflow: auto;
  }
  .missing-admission-card .ant-empty-normal {
    max-height: calc(100vh - 595px) !important;
    min-height: calc(100vh - 595px) !important;
  }
}
@media (min-width: 1025px) and (max-width: 1499px) {
  .missing-admission-card .ant-table-content {
    max-height: calc(100vh - 485px) !important;
    min-height: calc(100vh - 485px) !important;
    overflow: auto;
  }
  .missing-admission-card .ant-empty-normal {
    max-height: calc(100vh - 610px) !important;
    min-height: calc(100vh - 610px) !important;
  }
}

.concession .transparentBorderNone {
  border: none;
  background-color: transparent;
}
@media (max-width: 540px) {
  .add-admission-document
    .Upload_Btn
    .doubleError.false
    .ant-form-item-control-input-content {
    margin: 11px;
  }
  .add-admission-document .Upload_Btn .ant-form-item:not(.add-new-upload) {
    position: absolute;
    top: 50%;
    transform: translateY(-47%);
    right: 9px;
    margin: 0;
  }
  .add-admission-document .ant-form-item-explain-error {
    font-size: 10px;
    position: absolute;
    top: 72px;
    right: 5px;
    letter-spacing: -0.5px;
    margin-top: 3px;
  }
}
@media (max-width: 280px) {
  .add-admission-document .ant-form-item-explain-error {
    font-size: 9px;
    position: absolute;
    top: 70px;
    right: 5px;
    letter-spacing: 0.5px;
    margin-top: 3px;
  }
}
.branch-card .ant-empty-normal {
  height: 388px;
}

// Counsellor Page Style start
.counsellor-page .ant-pagination.ant-table-pagination {
  align-items: center;
  margin: 0;
}

.counsellor-page .ant-table-tbody > tr:nth-child(2n) {
  background-color: #fff8f8;
}

@media (max-width: 576px) {
  .counsellor-page .ant-pagination-total-text,
  .counsellor-page .ant-pagination-simple-pager {
    text-align: center;
  }

  .counsellor-page ul li:nth-child(n + 2),
  .counsellor-page ul button {
    border: transparent;
    padding: 0px;
    margin: 0px;
  }

  .counsellor-page .ant-pagination.ant-table-pagination {
    justify-content: center;
    align-items: center;
  }

  .counsellor-title .rnw-page-title {
    margin-top: 10px;
  }
}
// Counsellor Page Style end

@media (min-width: 1200px) and (max-width: 1394px) {
  .lab-report-input .ant-btn {
    height: 40px;
  }
}
.crm-lead-enquiries-table .crm-push-btn-mb .ant-btn {
  height: 42px;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 425px) {
  .crm-lead-enquiries-table .crm-push-btn-mb .ant-btn {
    height: 40px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.counsellor-more-btn.roles-details p {
  margin-left: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: unset;
}
.counsellor-more-btn .hold-popover {
  word-break: break-word;
  width: 200px;
  margin-left: 0px;
  max-height: 300px;
  white-space: unset;
  height: auto;
  max-height: 150px;
  font-size: 12px;
}

.text-editor-border .ql-container.ql-snow,
.text-editor-border .ql-toolbar.ql-snow {
  border: 1px solid #ffcdcd;
  border-radius: 6px;
}
@media (max-width: 576px) {
  .missing-admission-analysis .ant-row.ant-row-end {
    row-gap: 0px !important;
  }
  .missing-admission-analysis .csv-download-btn {
    flex: 0 !important;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .missing-admission-analysis .csv-download-btn .ant-btn {
    height: 38px;
  }
}

.sub-course-form #subtopiceditor label.label.as-placeholder,
.package-form #subtopiceditor label.label.as-placeholder {
  top: 52px !important;
}

@media (max-width: 510px) {
  .sub-course-form #subtopiceditor label.label.as-placeholder,
  .package-form #subtopiceditor label.label.as-placeholder {
    top: 70px !important;
  }
}

@media (max-width: 344px) {
  .ant-layout-header .select-zone-input {
    width: 130px !important;
  }
}

@media (max-width: 575px) {
  .sheet-note .btn-apply-filter,
  .theory-note .btn-apply-filter,
  .absent-remark-form .btn-apply-filter {
    width: 21% !important;
  }
}
@media (max-width: 344px) {
  .sheet-note .btn-apply-filter,
  .theory-note .btn-apply-filter,
  .absent-remark-form .btn-apply-filter {
    width: 23% !important;
  }
}

.body-column-time-slot-border:first-child {
  border-left: 1px solid rgb(161, 161, 161);
}

@media (max-width: 575px) {
  .ant-btn.save-btn {
    width: 100% !important;
  }
}

.subCourse-topic .ql-editor {
  height: 68px !important;
}

.question-form #subtopiceditor label.label.as-placeholder {
  top: 55px !important;
}

@media (max-width: 505px) {
  .question-form #subtopiceditor label.label.as-placeholder {
    top: 75px !important;
  }
}

.content-wrappera {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.plus-btn {
  margin-left: 6px;
  height: 17px;
  width: 17px;
  padding: 0 2px !important;
}

.plus-btn.ant-btn-icon-only > * {
  font-size: 13px;
}

.question-table .ant-card-body > div {
  max-height: calc(100vh - 319px);
  overflow: auto;
}

.tag-name {
  opacity: 0.9;
  font-weight: 400;
  font-size: 12px;
}

.exam-portal-alert .ant-modal-confirm-body span svg {
  font-size: 45px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .exam-portal-alert .anticon svg {
    width: 24px;
  }
}

.question-modal-form .ant-modal-body {
  max-height: 655px;
  overflow: auto;
}

@media (max-width: 540px) {
  .question-modal-form .ant-modal-body {
    max-height: 505px !important;
    overflow: auto;
  }
}

.ptm-data .card-body {
  max-height: calc(100vh - 320px) !important;
  min-height: calc(100vh - 320px) !important;
  overflow: auto;
}

.ptm-data .card-body .ant-table-content {
  max-height: calc(100vh - 320px) !important;
  min-height: calc(100vh - 320px) !important;
}

.ptm-data table .ant-empty-normal {
  max-height: calc(100vh - 385px) !important;
  min-height: calc(100vh - 385px) !important;
}

.penalty-form #subtopiceditor label.label.as-placeholder {
  top: 55px !important;
}

@media (max-width: 505px) {
  .penalty-form #subtopiceditor label.label.as-placeholder {
    top: 75px !important;
  }
}

.absent-remark {
  background: #cae9db !important;
}

@media (min-width: 344px) and (max-width: 533px) {
  .absent-remark-form .text-editor .label {
    top: 67px;
  }
}

@media (min-width: 533px) and (max-width: 575px) {
  .absent-remark-form .text-editor .label {
    top: 43px;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .create-new-questionbank.ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: inherit !important;
  }
}

@media (min-width: 991px) {
  .create-new-questionbank.ant-drawer.ant-drawer-right.ant-drawer-open.ant-drawer-right
    .ant-drawer-content-wrapper {
    width: 960px !important ;
  }
}

.create-question-bank .card-body table thead tr th.sticky-top {
  z-index: 99;
}
.create-question-bank .card-body {
  height: 620px;
}

.create-question-bank .card-body table tr td {
  padding: 5px 10px !important;
}
.create-question-bank .ant-form-item {
  margin: 5px 0px !important;
}

.create-question-bank .card-body {
  height: calc(100vh - 346px);
  min-height: 150px;
}

.faculty-signing.second-view-question .ant-empty-normal {
  max-height: calc(100vh - 370px) !important;
  min-height: calc(100vh - 370px) !important;
}

@media (max-width: 575px) {
  .question-card.rnw-card .ant-table-content {
    max-height: calc(100vh - 290px) !important;
    min-height: calc(100vh - 290px) !important;
  }
  .question-card .ant-empty-normal {
    max-height: calc(100vh - 450px) !important;
    min-height: calc(100vh - 450px) !important;
  }
}

@media (max-width: 1024px) {
  .question-card.rnw-card .ant-table-content {
    max-height: calc(100vh - 260px) !important;
    min-height: calc(100vh - 260px) !important;
  }
  .question-card .ant-empty-normal {
    max-height: calc(100vh - 390px) !important;
    min-height: calc(100vh - 390px) !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .ptm-data .btn-back {
    height: 40px;
  }
}

.ptm-remark.studentAttendenceRemark .quill .ql-editor {
  background-color: #fffbfb99;
  border-color: #ffcdcd !important;
  box-shadow: none !important;
  height: 100px;
  min-height: unset;
  padding: 7.9px 11px;
  line-height: 1.3;
}

.lecture-data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 540px) {
  .signingsheet-card .ant-card-body > div {
    max-height: calc(100vh - 243px);
    overflow: auto;
  }
  .lecture-data {
    flex-direction: column;
  }
  .lecture-data .department-selected {
    margin: 0 !important;
  }
}

.btn_assign.ant-btn:not(:last-child) {
  margin-top: 0 !important;
}
.consent-letter .ant-form-item-control {
  height: 56px !important;
  margin-top: 0 !important;
}

.consent-letter.add-admission-upload
  .ant-form-item-control-input-content
  div:nth-child(2) {
  margin-top: 0px !important;
}

.consent-letter.add-admission-upload {
  height: 230px !important;
}

@media (max-width: 540px) {
  .consent-letter .ant-form-item-explain-error {
    top: 35px !important;
    margin-top: 0 !important;
  }
  .consent-letter .ant-form-item-control {
    height: 50px !important;
    margin-top: 0 !important;
  }
  .consent-letter .ant-typography {
    margin-bottom: 0px !important;
  }
}

.exam-result .ant-form-item {
  margin-bottom: 0px;
}

.exam-result-table {
  height: calc(100vh - 260px);
}

.subcourse-remove-btn .ant-btn {
  border: 1px solid red;
  padding: 1px;
  font-size: 14px;
  width: 18px;
  height: 19px;
}

.exam-answer-sheet .ant-empty-normal {
  max-height: calc(100vh - 325px) !important;
  min-height: calc(100vh - 325px) !important;
}

.exam-answer-sheet .ant-card-body > div {
  max-height: calc(100vh - 180px) !important;
  min-height: calc(100vh - 180px) !important;
  overflow: auto;
}

@media (max-width: 767px) {
  .exam-answer-sheet .ant-card-body > div {
    max-height: calc(100vh - 220px) !important;
    min-height: calc(100vh - 220px) !important;
    overflow: auto;
  }
}

.table-bg.exam-body {
  max-height: calc(100vh - 264px) !important;
  min-height: calc(100vh - 264px) !important;
}

.faculty-signing.exam-list .ant-empty-normal {
  max-height: calc(100vh - 320px) !important;
  min-height: calc(100vh - 390px) !important;
}

.faculty-card-more-btn .ant-popover {
  z-index: 999;
}

.faculty-card-more-btn .role-modal-details {
  overflow: auto !important;
  max-height: 140px;
}

.gx-layout-sider-header .cc.gx-site-logo {
  width: 140px;
}

.ant-modal-confirm-body .anticon-warning svg {
  width: 24px;
  margin-bottom: 4px;
}

@media (max-width: 1024px) {
  .admission-filter-tab .ant-tabs-content-holder {
    max-height: calc(100vh - 210px) !important;
    overflow: auto;
  }
}

.student-mark-card span {
  opacity: 1;
}

.exam-not-submit-msg {
  font-size: 12px;
  color: #ff0000;
  margin-top: 5px;
}

// Active Student List Page Style end

@media (max-width: 576px) {
  .active-student-list .ant-row.ant-row-end {
    row-gap: 0px !important;
  }
  .active-student-list .csv-download-btn {
    flex: 0 !important;
  }
}

@media (max-width: 992px) {
  .active-student-list .rnw-page-title {
    margin-bottom: 16px;
  }
}

// Counsellor Page

.counseller-card {
  background: #fff8f8;
  border-top: 2px solid #ea5455 !important;
  box-shadow: 0 0 2.1875rem rgba(90, 97, 105, 0.07),
    0 0 1.40625rem rgba(90, 97, 105, 0.07),
    0 0 0.53125rem rgba(90, 97, 105, 0.09),
    0 0 0.1875rem rgba(90, 97, 105, 0.07);
}

.counseller-card .ant-card-body {
  background: white;
  box-shadow: none;
  padding: 0px 0px;
}

.counseller-card .ant-card-head-title {
  padding: 0.8rem 0 0.5rem 0;
}

.counseller-card .package-btn {
  font-size: 1rem;
  background-color: transparent;
}

.counseller-card .ant-card-head-title {
  display: flex;
}

.counseller-card .ant-card-head {
  border-bottom: transparent;
}

.counseller-card .action-icon {
  margin-bottom: 0.5rem;
}

.counseller-card .hold-popover {
  max-width: 300px;
  overflow: auto;
}

.counseller-card .ant-popover-inner-content {
  padding: 0px 0px;
}

.counseller-card .ant-popover-inner-content p {
  font-weight: 400 !important;
}

.counseller-card .ant-btn-link:hover,
.counseller-card .ant-btn-link:focus {
  color: #ea5455;
}

.counseller-card .action-icon button {
  width: 110px;
}

@media (max-width: 500px) {
  .counseller-card .package-btn {
    font-size: 1rem;
  }
  .counseller-card .ant-card-head {
    padding: 0 14px;
  }
  .counseller-card .gx-fs-lg {
    font-size: 14px;
  }
}

// Master Subcourse

@media (max-width: 380px) {
  .subcourse-btns .ant-btn {
    padding: 0 5px;
  }
}

// Cancel Terminate Card

.cancel-terminate p {
  max-height: 100px;
  overflow-y: auto;
  margin: 0 !important;
}

.cancel-terminate .ant-popover {
  z-index: 1000;
}

@media (max-width: 520px) {
  .cancel-terminate p {
    width: 300px;
  }
}

.performance-modify .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: transparent !important;
}

.counsellor-page .erp-button-group,
.counsellor-page .ant-form .ant-row > .ant-col:first-child {
  padding-left: 0px !important;
}

.paragraph-break {
  white-space: normal;
  word-break: break-word;
  display: block;
  text-align: justify;
  line-height: 1.2rem;
}

.installment-check-modal .input-search .ant-input {
  height: 40px;
}

@media (max-width: 575px) {
  .installment-check-modal .input-search .ant-input {
    height: 35px;
  }
}

.ant-drawer.ant-drawer-right.cv-details.ant-drawer-open.ant-drawer-right
  .ant-drawer-content-wrapper {
  width: 50% !important;
}

@media (min-width: 480px) and (max-width: 768px) {
  .ant-drawer.ant-drawer-right.cv-details.ant-drawer-open.ant-drawer-right .ant-drawer-content-wrapper {
    width: 80% !important;
  }
}

@media (max-width: 480px) {
  .ant-drawer.ant-drawer-right.cv-details.ant-drawer-open.ant-drawer-right .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.cv-card .card-body {
  height: 455px;
}
.cv-card .card-body .ant-table-content {
  height: 455px;
}

.es-card .card-body {
  height: 405px;
}
.es-card .card-body .ant-table-content {
  height: 405px;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  background: #fff;
}

.ratio-cell {
  padding: 8px;
  text-align: center;
  border-radius: 3px;
}

.ratio-cell.with-background {
  color: #fff;
}

.ratio-cell.transparent {
  background-color: transparent;
}

.admission-target .ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 15;
}

.ant-drawer-body
  .student-detail-attendance
  .ant-picker-date-panel
  .ant-picker-body {
  width: 100%;
}

.student-detail-attendance .ant-picker-panel .ant-picker-body th {
  padding: 0px 0px 10px 0px;
  font-weight: 500;
}

.student-detail-attendance .ant-picker-panel .ant-picker-body th,
.student-detail-attendance .ant-picker-panel .ant-picker-body td {
  padding: 5px 0px;
  text-align: center;
}

.student-detail-attendance .ant-picker-panel .ant-picker-body td div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}
.attendance-card-padding {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 576px) and (max-width: 999px) {
  .ant-row .fee-details-row {
    width: 100vw !important;
  }
}

@media screen and (max-width: 576px) {
  .mobile-full-width,
  .fee-details-col .ant-input-number {
    width: 100% !important;
  }

  .fee-details-row .amount-col,
  .fee-details-row .gst-col,
  .fee-details-row .percentage-col,
  .fee-details-row .action-col {
    width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-bottom: 14px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .fee-details-row .gst-col .ant-radio-group {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 16px !important;
  }

  .fee-details-row .action-col {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    margin-bottom: 0 !important;
  }

  .fee-details-row .ant-form-item {
    margin-bottom: 0 !important;
  }

  .fee-details-row {
    margin-bottom: 4px !important;
  }

  .fee-details-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .fee-details-row > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .total-fee-container {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .fee-details-col + .total-fee-container {
    margin-top: 4px !important;
  }
}

@media screen and (max-width: 999px) {
  .ant-col-xs-24:first-child {
    margin-bottom: 16px;
  }

  .job-label {
    flex: 1 !important;
    padding: 0 8px !important;
    margin-left: 0 !important;
  }
}

.installment-payment-confirmation .modal-header {
  display: flex;
  align-items: center;
}

.installment-payment-confirmation .success-icon {
  color: #52c41a;
  font-size: 24px;
  margin-right: 10px;
}

.installment-payment-confirmation .modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.installment-payment-confirmation .warning-container {
  display: flex;
  align-items: center;
  max-width: 80%;
}

.installment-payment-confirmation .warning-icon {
  color: #faad14;
  font-size: 18px;
  margin-right: 12px;
}

.installment-payment-confirmation .warning-text {
  color: #d46b08;
  text-align: left;
}

.installment-payment-confirmation .ok-button {
  width: 120px;
}

.installment-payment-confirmation .success-message {
  text-align: center;
  margin-bottom: 24px;
}

.installment-payment-confirmation .success-title {
  color: #52c41a;
  margin-bottom: 16px;
}

.installment-payment-confirmation .receipt-container {
  background: #f5f5f5;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.installment-payment-confirmation .receipt-number {
  margin: 4px 0 0 0;
}

.button-container {
  display: flex;
  margin-top: 10px;
}

.custom-button {
  font-size: 14px;
  min-width: 40px;
  min-height: 40px;
}
.correct-answer {
  color: rgb(84, 202, 104);
}
.faculty-availability {
  opacity: 0.5;
}

@media screen and (min-width: 1399px) {
  .help-support-submit-btn {
    margin-right: 55px;
  }
}

@media (min-width: 1199px) and (max-width: 1399px) {
  .help-support-submit-btn {
    margin-right: 20px !important;
  }
}

.info-icon {
  color: rgb(228, 87, 87);
  margin-left: 5px;
  font-size: 16px;
}

@media (max-width: 576px) {
  .my-team-batches-title {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }

  .my-team-batches-title h2 {
    font-size: 1rem !important;
    margin-bottom: 0 !important;
    width: 80px !important;
  }

  .my-team-batches-title div {
    width: auto !important;
    margin: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .my-team-batches-title {
    display: flex;
    justify-content: space-between;
  }

  .my-team-batches-title h2 {
    width: auto !important;
    font-size: 16px !important;
  }

  .my-team-batches-title div {
    width: auto !important;
    margin: 0 !important;
  }
}

@media (min-width: 992px) and (max-width: 1160px) {
  .my-team-batches-title div {
    width: 255px !important;
    margin: 0 !important;
  }

  .my-team-batches-title h2 {
    width: auto !important;
    max-width: 175px !important;
  }
}
.grabbable .sheet {
  font-size: 12px;
  font-weight: 400;
  color: gray;
  margin-top: 4px;
  margin-left: 24px;
  cursor: grab;
}

.grabbable .space {
  margin-bottom: 8px;
}

.font-weight-bold {
  font-weight: bold;
}

.text-align-center {
  text-align: center;
}

.table-responsive {
  overflow: auto;
  background: white;
  position: relative;
  max-height: 70vh;
}

.faculty-signing {
  border-collapse: collapse;
  width: 100%;
}

.faculty-signing th.sr-no-col,
.faculty-signing td.sr-no-col {
  width: 60px;
  min-width: 60px;
  text-align: center;
}

.faculty-signing th.branch-col,
.faculty-signing td.branch-col {
  width: 150px;
  min-width: 150px;
}

.faculty-signing th.date-col,
.faculty-signing td.date-col {
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.faculty-signing th.monthwise-date-col,
.faculty-signing td.monthwise-date-col {
  width: 90px !important;
  min-width: 90px !important;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.faculty-signing th.total-col,
.faculty-signing td.total-col {
  width: 80px;
  min-width: 80px;
}

.table-footer {
  position: sticky;
  bottom: 0;
  z-index: 3 !important;
}

.faculty-signing thead tr th {
  padding: 8px 10px;
  font-weight: 500;
  position: sticky;
  top: 0;
  color: #fff;
  background: #ea5455;
  z-index: 3;
}

.faculty-signing thead tr th.left-sticky {
  z-index: 4;
  background: #ea5455;
}

.faculty-signing thead tr th.right-sticky {
  z-index: 4;
  background: #ea5455;
}

.faculty-signing thead tr th:before {
  background-color: hsla(0, 0%, 100%, 0.5);
  content: "";
  height: 1.6em;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 5;
  transform: translateY(-50%);
  transition: background-color 0.3s;
  width: 1px;
}

.faculty-signing thead tr th:last-child:before {
  display: none;
}

.faculty-signing tbody tr td.branch-col {
  padding: 8px 10px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.daily-total {
  background-color: #626262;
  font-weight: 500 !important;
  position: sticky;
  left: 0;
  z-index: 3 !important;
}

.faculty-signing tbody tr:hover {
  background-color: #f9f9f9;
}

.left-sticky {
  position: sticky;
  z-index: 2;
  background-color: inherit;
}

.faculty-signing tr:nth-child(even) td.left-sticky {
  background-color: #fff8f8;
}

.faculty-signing tr:hover td.left-sticky {
  background-color: #f9f9f9;
}

.faculty-signing tr td.left-sticky {
  background-color: white;
}

.sr-no-col.left-sticky {
  left: 0;
}

.branch-col.left-sticky {
  left: 60px;
}

.right-sticky {
  position: sticky;
  right: 0;
  z-index: 2;
  background-color: inherit;
}

.faculty-signing tr:nth-child(even) td.right-sticky {
  background-color: #fff8f8;
}

.faculty-signing tr:hover td.right-sticky {
  background-color: #f9f9f9;
}

.faculty-signing tr td.right-sticky {
  background-color: white;
}

.left-sticky.branch-col:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 4px;
  height: 100%;
  pointer-events: none;
  box-shadow: inset -2px 0 4px -2px rgba(0, 0, 0, 0.2);
}

.right-sticky:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  pointer-events: none;
  box-shadow: inset 2px 0 4px -2px rgba(0, 0, 0, 0.2);
}

.table-footer td {
  z-index: 2;
  border-top: 1px solid #e1e1e1;
}

@media screen and (max-width: 1024px) {
  .faculty-signing tbody tr .branch-col,
  .faculty-signing tbody tr .total-col {
    position: static;
    left: auto;
  }

  .faculty-signing tbody tr .sr-no-col {
    position: sticky;
  }

  .faculty-signing thead tr th {
    position: sticky;
    top: 0;
  }

  .faculty-signing thead tr .branch-col,
  .faculty-signing thead tr .total-col {
    left: auto;
    right: auto;
  }

  .faculty-signing thead tr .sr-no-col {
    position: sticky;
    left: 0;
    z-index: 5;
  }

  .faculty-signing tbody tr td.sr-no-col {
    border-left: none;
  }

  .left-sticky.branch-col:after {
    box-shadow: none;
  }

  .faculty-signing tbody tr.table-footer {
    position: sticky;
    bottom: 0;
    z-index: 2;
  }

  .faculty-signing tbody tr.table-footer td {
    position: static !important;
    left: auto !important;
    right: auto !important;
  }
}

.custom-date-picker.ant-picker .ant-picker-clear {
  right: 12px;
  background: transparent;
  z-index: 2;
  position: absolute;
}

.custom-date-picker.ant-picker .ant-picker-suffix {
  position: absolute;
  right: 12px;
}

.custom-date-picker.ant-picker-focused .ant-picker-suffix,
.custom-date-picker.ant-picker:hover .ant-picker-suffix {
  opacity: 0;
}

.dashboard-student-count-card .student-cards {
  margin: 0 !important;
  padding: 0 !important;
}

.dashboard-student-count-card .ant-col {
  padding-left: 10px !important;
  padding-right: 10px !important;
}